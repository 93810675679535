import React, { CSSProperties } from 'react'
import { MpFileProps, MimeType } from '../../../domain/File'
import { CoresEnum, TooltipPlacementEnum } from '../../../enums'
import { LimitedTextContent } from '../../../utils/LimitedTextContent'
import CustomTooltip from '../../CustomTooltip'

type Props = {
  currentFile: MpFileProps
  onFileClick: (file: MpFileProps) => void
  style: CSSProperties
  files: MpFileProps[]
  index: number
  hidenListViewer: boolean
  isCollapsed?: boolean
}

export const MpFileListViewerItem: React.FC<Props> = ({ onFileClick, style, files, index, hidenListViewer, currentFile }: Props) => {
  const file: {
    mimeType?: MimeType
    fileName?: string | React.ReactNode
    fileNumber?: string
  } = files[index] ?? { mimeType: 'text/html', fileName: 'Falha ao carregar' }
  return (
    <div onClick={() => onFileClick(files[index])}
      className={`mp-itemlist ${(currentFile?.id === files[index]?.id) ? 'current' : ''}`}
      style={style}>
      {getIconFile(file.mimeType, file.fileName as string, file.fileNumber, !hidenListViewer)}
    </div>
  )
}

type IconFileProps = {
  iconPath: string
  iconColor: CoresEnum
}

const getIconFile = (mimeType: MimeType, fileName: string, fileNumber: string, maxMode: boolean): React.ReactElement | string => {
  if (!mimeType) {
    return <></>
  }

  const IconFile: React.FC<IconFileProps> = (props: IconFileProps): React.ReactElement => (
    <>
      <CustomTooltip text={fileName} placement={TooltipPlacementEnum.RIGHT} isHide={maxMode}>
        <div className='mp-icon-filelist' style={{ color: `${props.iconColor.value}`, borderLeftColor: `${props.iconColor.value}` }}>{props.iconPath}</div>
      </CustomTooltip>
      <div className='mp-name-filelist'>
        <LimitedTextContent maxLength={20} alwaysShowTooltip={true}>{fileName}</LimitedTextContent>
      </div>
    </>
  )

  const MIME_TYPE_COMPONENT_MAP: { [key in MimeType]: React.ReactElement } = {
    'text/html': <IconFile iconPath={maxMode ? 'HTM' : fileNumber || 'HTM'} iconColor={CoresEnum.APOIO_1_DARK} />,
    'application/pdf': <IconFile iconPath={maxMode ? 'PDF' : fileNumber || 'PDF'} iconColor={CoresEnum.APOIO_1_DARK} />,
    'application/rtf': <IconFile iconPath={maxMode ? 'RTF' : fileNumber || 'RTF'} iconColor={CoresEnum.APOIO_1_DARK} />,
    'application/msword': <IconFile iconPath={maxMode ? 'DOC' : fileNumber || 'DOC'} iconColor={CoresEnum.APOIO_1_DARK} />,
    'image/png': <IconFile iconPath={maxMode ? 'PNG' : fileNumber || 'PNG'} iconColor={CoresEnum.APOIO_6_DARK} />,
    'image/jpeg': <IconFile iconPath={maxMode ? 'JPG' : fileNumber || 'JPG'} iconColor={CoresEnum.APOIO_6_DARK} />,
    'audio/video': <IconFile iconPath={maxMode ? 'AUD' : fileNumber || 'AUD'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'audio/ogg': <IconFile iconPath={maxMode ? 'OGG' : fileNumber || 'OGG'} iconColor={CoresEnum.APOIO_11_DARK} />,
    'audio/mpeg': <IconFile iconPath={maxMode ? 'MPG' : fileNumber || 'MPG'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'video/mpeg': <IconFile iconPath={maxMode ? 'MP4' : fileNumber || 'MP4'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'video/mp4': <IconFile iconPath={maxMode ? 'MP4' : fileNumber || 'MP4'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'video/quicktime': <IconFile iconPath={maxMode ? 'MOV' : fileNumber || 'MOV'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'application/octet-stream': <IconFile iconPath={maxMode ? 'ZIP' : fileNumber || 'ZIP'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'application/zip': <IconFile iconPath={maxMode ? 'ZIP' : fileNumber || 'ZIP'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'application/x-rar-compressed': <IconFile iconPath={maxMode ? 'RAR' : fileNumber || 'RAR'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'application/vnd.ms-excel': <IconFile iconPath={maxMode ? 'XLS' : fileNumber || 'XLS'} iconColor={CoresEnum.APOIO_14_DARK} />,
    'application/vnd.ms-powerpoint': <IconFile iconPath={maxMode ? 'PPT' : fileNumber || 'PPT'} iconColor={CoresEnum.APOIO_14_DARK} />
  }

  return MIME_TYPE_COMPONENT_MAP[mimeType]
}
