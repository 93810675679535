export enum DisplayEnum{
  FLEX = 'mp-d-flex',
  NONE = 'mp-d-none',
  BLOCK = 'mp-d-block'
}

export enum FlexEnum{
  INITIAL = 'mp-flex_initial',
  NONE = 'mp-flex_none',
  AUTO = 'mp-flex_auto'
}

export enum JustifyContentEnum {
  FLEX_START = 'mp-justify-content_flex-start',
  FLEX_END = 'mp-justify-content_flex-end',
  CENTER = 'mp-justify-content_center',
  SPACE_BETWEEN = 'mp-justify-content_space-between',
  START = 'mp-justify-content_start',
  END = 'mp-justify-content_end',
  LEFT = 'mp-justify-content_left',
  RIGHT = 'mp-justify-content_right'
}

export enum AlignItensEnum {
  FLEX_START = 'mp-align-itens_flex-start',
  FLEX_END = 'mp-align-itens_flex-end',
  CENTER = 'mp-align-itens_center',
  STRETCH = 'mp-align-itens_stretch',
  START = 'mp-align-itens_start',
  END = 'mp-align-itens_end',
  BASELINE = 'mp-align-itens_baseline',
  REVERT = 'mp-align-itens_revert',
}

export enum FlexDirectionEnum {
  ROW = 'mp-flex-direction_row',
  ROW_REVERSE = 'mp-flex-direction_row-reverse',
  COLUMN = 'mp-flex-direction_column',
  COLUMN_REVERSE= 'mp-flex-direction_column-reverse'
}

export enum FlexWrapEnum {
  NOWRAP = 'mp-flex-wrap_nowrap',
  WRAP = 'mp-flex-wrap_wrap',
  WRAP_REVERSE = 'mp-flex-wrap_wrap-reverse'
}
