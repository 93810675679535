import React from 'react'
import { ButtonSizeEnum } from '../../../../enums'
import { MpFileContextualMenuItemProps, MpFileTreeContextualMenu, MpFileTreeContextualMenuItem } from '../../FileTreeContextualMenu/MpFileTreeContextualMenu'
import { MpFileNodeProps } from '../MpFileTreeNode'

type Props = {
  node?: MpFileNodeProps
  isReduced?: boolean
  contextualMenu?: MpFileContextualMenuItemProps[]
}

export const MpFileTreeNodeContextualMenu: React.FC<Props> = (props: Props) => {
  return (
    <>
      {
        (props?.contextualMenu?.length > 0) && (
          <MpFileTreeContextualMenu isReduced={props.isReduced} buttonSize={ButtonSizeEnum.DEFAULT}>
            {
              props.contextualMenu?.filter((item) => !item.hide).map((item, index) => (
                <MpFileTreeContextualMenuItem node={props.node} key={`mp-file-tree-contextual-menu-item-${index}`} {...item} />
              ))
            }
          </MpFileTreeContextualMenu>
        )
      }
    </>
  )
}
