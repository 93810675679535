import React from 'react'
import {  mdiClose, mdiInformationOutline } from '@mdi/js'
import { Icone, TamanhoIconeEnum } from '../../index'
import { Button } from '../../../src/components/Buttons/Button'
import { ButtonTypeEnum } from '../../enums'

import './progressBar.scss'

export interface ProgressBarProps {
  onClose: () => void
  progress: number
  hide?: boolean
  fileName? : string
  isLight?: boolean
}
export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {

  type lineProgress = number & {_type_: "lineProgress"};
  const linePercentValid = (value: number): lineProgress => {
    if (value < 0 || value > 100) {
      throw new Error(`The value ${value} is not a valid percent`);
    }
    return value as lineProgress;
  }

  const fillerStyles = {
    width: `${linePercentValid(props.progress)}%`
  }

  return (
    <>
      { (!props.hide) &&
      <div className={`mp-progress-bar-container ${props.isLight ? 'light' : ''} `}>
        <div className="icon-message">
          <Icone iconPath={mdiInformationOutline} iconSize={TamanhoIconeEnum.LARGE} />
        </div>
        <div className="mp-progress-bar-loader-container">
          <p>
            {props.fileName ? props.fileName : 'Processando...'}
            <strong>
              {props.progress}%
            </strong>
          </p>
          <div className="mp-progress-bar">
            <div style={fillerStyles} className="mp-progress-bar-loader"></div>
          </div>
        </div>
        <div className="button-message">
          <Button buttonType={ButtonTypeEnum.ICON} iconPath={mdiClose} handleClick={props.onClose} />
        </div>
      </div>}
    </>
  )
}
