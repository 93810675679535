import React from 'react'
import { CoresEnum, TooltipPlacementEnum } from '../../enums'
import { CustomTooltip } from '../CustomTooltip'
import './avatar-padrao.scss'

export interface AvatarPadraoProps {
  conteudo: string
  tooltipText?: string
  background?: CoresEnum
  color?: CoresEnum
}

export const AvatarPadrao: React.FC<AvatarPadraoProps> = (props: AvatarPadraoProps) => {
  return (
    <div className="mp-avatar"
      style={{
        color: props?.color?.value,
        backgroundColor: props?.background?.value
      }}
    >
      <CustomTooltip text={props.tooltipText} placement={TooltipPlacementEnum.TOP}>
        <div className="mp-avatar-item" data-testid="com-tooltip">
          {props.conteudo.substring(0, 3)}
        </div>
      </CustomTooltip>
    </div>
  )
}
