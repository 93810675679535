import React, { useEffect, useState } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride'
import { MpBeaconTutorial } from './BeaconTutorial/BeaconTutorial'
import { MpHelpButtonTutorial } from './HelpButtonTutorial/HelpButtonTutorial'
import { MpHelpPanelTutorial } from './HelpPanelTutorial/HelpPanelTutorial'
import { MpTooltipTutorial } from './TooltipTutorial/TooltipTutorial'

export interface TutorialStep extends Step {
  index: number
  target: string
  content: string
  title?: string
}

export interface MpTutorialProps {
  steps: TutorialStep[]
  initialStep?: number
  run: boolean
  skipTutorial: Function
}

export const MpTutorial: React.FC<MpTutorialProps> = (
  props: MpTutorialProps
) => {
  const [activeStep, setActiveStep] = useState<number>(
    props.initialStep ? props.initialStep : 0
  )
  const [offset, setOffset] = useState<number>(0)
  const [run, setRun] = useState<boolean>(false)
  const [steps, setSteps] = useState(props.steps ? props.steps : [])
  const [showHelpPanel, setShowHelpPanel] = useState<boolean>(false)
  const [minimizeHelpPanel, setMinimizeHelpPanel] = useState<boolean>(false)
  const [helpPanelPosition, setHelpPanelPosition] = useState<number>(1)

  const disableBeaconOfStep = (stepNumber: number) => {
    const stepsUpdated = steps.map((step) => {
      return {
        ...step,
        disableBeacon: step.index === stepNumber
      }
    })
    setSteps(stepsUpdated)
  }

  const toggleTutorial = (run: boolean) => {
    if (targetsIsVisible(props.steps)) {
      if (!run) setMinimizeHelpPanel(false)
      if (!run) updateStep({ status: STATUS.FINISHED })
      disableBeaconOfStep(activeStep)
      setShowHelpPanel(run)
      setRun(run)
    } else {
      props.skipTutorial()
    }
  }

  const toggleMinimizeHelpPanel = (): void => {
    setMinimizeHelpPanel(!minimizeHelpPanel)
    setShowHelpPanel(!showHelpPanel)
  }

  const updateStep = (event: any): void => {
    const { action, status, type } = event
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false)
      setActiveStep(0)
      setShowHelpPanel(false)
      setMinimizeHelpPanel(false)
      props.skipTutorial()
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      let nextStepIndex = activeStep + offset + (action === ACTIONS.PREV ? -1 : 1)
      nextStepIndex = nextStepIndex < 0 ? 0 : nextStepIndex
      setActiveStep(nextStepIndex)
      setOffset(0)
      setTimeout(() => { intersectionObserver() }, 500)
    }
  }

  const selectStepFromPanel = (stepNumber: number): void => {
    disableBeaconOfStep(stepNumber)
    const direction = activeStep > stepNumber ? 1 : -1
    setOffset(direction)
    setActiveStep(stepNumber)
  }

  const moveStepFromPanel = (moveType: string): void => {
    const stepNumber = moveType === 'next' ? activeStep + 1 : activeStep - 1
    disableBeaconOfStep(stepNumber)
    const direction = activeStep > stepNumber ? 1 : -1
    setOffset(direction)
    setActiveStep(stepNumber)
  }

  const targetsIsVisible = (steps: TutorialStep[]): boolean => {
    const result = steps.filter((step) => {
      const div = document.querySelector(step.target)
      const isVisible = document.body.contains(div)
      return isVisible
    })
    return result.length === steps.length
  }

  const intersectionObserver = () => {
    const helpPanel = document.getElementById('help-panel-tutorial')
    const toolTip = document.getElementById('tooltip-tutorial')
    if (helpPanel && toolTip) {
      const booleanPosition = !helpPanelPosition
      if (elementsOverlap(helpPanel, toolTip)) { setHelpPanelPosition(Number(booleanPosition)) }
    }
  }

  const elementsOverlap = (el1: HTMLElement, el2: HTMLElement) => {
    const domRect1 = el1?.getBoundingClientRect()
    const domRect2 = el2?.getBoundingClientRect()

    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    )
  }

  useEffect(() => {
    const stepsWithoutBeacons = steps.map((step) => {
      return { ...step, disableBeacon: true }
    })
    setSteps(stepsWithoutBeacons)
  }, [])

  useEffect(() => {
    toggleTutorial(props.run)
  }, [props.run])

  // const createBeacons = () => {
  //   props.steps.map((step) => {
  //     {
  //       const beacon = ReactDOM.createPortal(
  //         <MpBeaconTutorial index={step.index}/>,
  //         document.querySelector(step.target)
  //       );

  //       ReactDOM.render(beacon, document.createElement("span"));
  //     }
  //   });
  // };

  return (
    <>
      <Joyride
        locale={{
          back: '',
          next: '',
          skip: '',
          last: ''
        }}
        debug={false}
        beaconComponent={MpBeaconTutorial}
        tooltipComponent={MpTooltipTutorial}
        steps={steps}
        showProgress
        showSkipButton
        continuous
        callback={updateStep}
        stepIndex={activeStep}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        run={run}
      />
      <MpHelpPanelTutorial
        position={helpPanelPosition}
        title={'Painel de Ajuda'}
        steps={props.steps}
        activeStep={activeStep}
        handleSelectStep={selectStepFromPanel}
        handleMoveStep={moveStepFromPanel}
        handleSkip={toggleTutorial}
        handleMinimize={toggleMinimizeHelpPanel}
        show={showHelpPanel}
      />
      <MpHelpButtonTutorial
        show={minimizeHelpPanel}
        handleMinimizeHelpPanel={toggleMinimizeHelpPanel}
      />
    </>
  )
}
