import { mdiClose, mdiMagnify } from '@mdi/js'
import React from 'react'
import MaskedInput from 'react-input-mask'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import { AplicacaoEnum, ButtonThemeEnum, ButtonTypeEnum, TamanhoIconeEnum } from '../../enums/'
import Button from '../Buttons'
import Icone from '../icone'
import { skeletonInputSearch } from './input-search-skeleton'

import 'react-placeholder/lib/reactPlaceholder.css'
import './input-search.scss'

export interface InputSearchProps {
  onSearch: (keyword: string) => void
  onFocus?: () => void
  onClear?: () => void
  inputId?: string
  buttonId?: string
  reduced?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  quickSearch? : boolean
  keyword?: string
  hint?: string
  mask?: string
  minLength?: number
  aplication?: AplicacaoEnum
  placeholder?: string
}

export interface InputSearchState {
  keyword: string
  hasError: boolean
}

export class InputSearch extends React.Component<InputSearchProps, InputSearchState> {
  constructor (props: InputSearchProps) {
    super(props)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.onChange = this.onChange.bind(this)
    this.verifyClick = this.verifyClick.bind(this)

    this.state = { keyword: props.keyword ? this.props.keyword : '', hasError: false }
  }

  componentDidUpdate (prevProps): void {
    if (this.props.keyword !== prevProps.keyword) {
      const keyword = (this.props.keyword === undefined || this.props.keyword === null) ? '' : this.props.keyword
      this.setState({ ...this.state, keyword })
    }
  }

  minLengthIsOk = (keyword: string): boolean => {
    if (!this.props.minLength) return true
    const trimmedKeyword = keyword.trim()
    return trimmedKeyword.length >= this.props.minLength
  }

  handleSearch = (): void => {
    let hasError = false
    const trimmedKeyword = this.state.keyword.trim()
    if (this.minLengthIsOk(trimmedKeyword)) {
      this.props.onSearch(trimmedKeyword)
    } else {
      hasError = true
    }
    this.setState({ hasError })
  }

  handleClear = (): void => {
    const keyword = ''
    const hasError = false
    this.setState({ keyword, hasError })
    if (this.props.quickSearch) this.props.onSearch(keyword)

    if (this.props.onClear) this.props.onClear()
  }

  onChange (event: any): void {
    const keyword = event.target.value
    this.setState({ keyword })
    if (this.props.quickSearch) this.props.onSearch(keyword)
  }

  verifyClick (event: any): void {
    if (event.key === 'Enter') {
      if (this.props.onSearch) {
        this.props.onSearch(this.state.keyword)
      }
    }
  }

  getStyleAplicacao = (): string => {
    return (this.props.aplication.indexOf(' ') > 0)
      ? this.props.aplication.substring(0, this.props.aplication.indexOf(' ')).toLocaleLowerCase()
      : this.props.aplication.toLocaleLowerCase()
  }

  ActionArea = (): any => {
    return (<div data-testid='search-button' className="mp-form-button">
      <Button
        id={this.props.buttonId}
        buttonType={ButtonTypeEnum.ICON}
        buttonTheme={!this.props.reduced ? ButtonThemeEnum.CONTAINED : null}
        iconPath={mdiMagnify}
        isDisabled={this.props.isDisabled}
        handleClick={this.handleSearch} />
    </div>)
  }

  render (): JSX.Element {
    return (
      <ReactPlaceholder showLoadingAnimation ready={!this.props.isLoading} customPlaceholder={skeletonInputSearch}>

        <div className={`mp-form-control mp-form-control-search 
          ${this.state.hasError ? 'mp-form-error' : ''} 
          ${this.props.aplication ? this.getStyleAplicacao() : ''} 
          ${this.props.isDisabled ? 'mp-form-control-disabled' : ''}
          ${this.props.reduced ? 'mp-input-search-reduced' : ''}
        `}
        >

          <div className="mp-form-input-content">
            <div className="mp-form-input-wrapper">

              {!this.props.reduced &&
              <div className="mp-form-icon">
                <Icone
                  iconSize={TamanhoIconeEnum.MEDIUM}
                  iconPath={mdiMagnify} />
              </div>
              }

              <div className="mp-form-input">
                <MaskedInput
                  id={this.props.inputId ? this.props.inputId : 'inputText'}
                  data-testid={this.props.inputId ? this.props.inputId : 'input-text'}
                  type="text"
                  name="inputText"
                  autoComplete="off"
                  onChange={this.onChange}
                  onKeyDown={this.verifyClick}
                  maskChar={null}
                  disabled={this.props.isDisabled}
                  value={this.state.keyword}
                  mask={this.props.mask}
                  onFocus={this.props.onFocus}
                  placeholder={this.props.placeholder} />
              </div>

              <div className="mp-form-button">

                { (this.state.keyword) &&

                <Button
                  iconPath={mdiClose}
                  buttonType={ButtonTypeEnum.ICON}
                  isDisabled={this.props.isDisabled}
                  handleClick={this.handleClear} />

                }
              </div>
              {!this.props.quickSearch && !this.props.reduced && <this.ActionArea />}

            </div>
            {!this.props.quickSearch && this.props.reduced && <this.ActionArea />}

          </div>

          { !this.props.reduced && this.props.hint && <div className="mp-form-hint">
            { this.state.hasError
              ? <p> {`Mínimo de ${this.props.minLength ? this.props.minLength : 0} caracteres`} </p>
              : <p> {this.props.hint} </p>
            }
          </div>}

        </div>
      </ReactPlaceholder>
    )
  }
}
