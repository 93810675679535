import React from 'react'
import Select from 'react-select'
import { normalize } from '../../utils/string-utils'
import { customStyles, groupStyles, groupBadgeStyles } from './CustomStyles'
import ReactPlaceholder from 'react-placeholder/lib'
import { skeletonCustomSelect, skeletonCustomSelectComLabel } from './customSelect-skeleton'
import Icone from '../icone'
import { TamanhoIconeEnum } from '../../enums'

import 'react-placeholder/lib/reactPlaceholder.css'
import './customSelect.scss'
import classNames from 'classnames'
export interface Item {
  value: string | number
  label: string
}

export interface GroupList {
  label: string
  options: Item[]
}

export interface CustomSelectProps {
  itens: Item[] | GroupList
  value: string | Item | Item[]
  id?: string
  onChange: (selectedOption: Item | Item[]) => void
  isLoading?: boolean
  iconPath? : string
  isDisabled?: boolean
  isMulti?: boolean
  label?: string
  isRequired? : boolean
  maxHeight?: string
  hint?: string
  hasError?: boolean
}

const formatGroupLabel = data => (
  <div className="mp-label-group" style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

export const CustomSelect: React.FC<CustomSelectProps> = (props: CustomSelectProps) => {
  const customFilter = (candidate, input): boolean => {
    const founds = []
    const palavrasOpcao: string[] = candidate.label.split(' ').map(normalize)
    const palavrasDigitadas: string[] = input?.split(' ').map(normalize)

    if (!input) {
      return true
    }

    palavrasDigitadas.forEach(palavraDigitada => {
      if (palavrasOpcao.some((opcao) => opcao.includes(palavraDigitada))) {
        founds.push(true)
      } else {
        founds.push(false)
      }
    })
    return founds.reduce((prev, curr) => (prev && curr))
  }

  const handleChange = (selectedOption: Item | Item[]): void => {
    props.onChange(selectedOption)
  }

  const idSelect = `react-custom-select${Math.random() * 100}`

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={props.label ? skeletonCustomSelectComLabel : skeletonCustomSelect}>
      <div className={classNames('mp-form-control', { 'mp-form-error': props.hasError })}>
        {props.label && (
          <div className="mp-form-label" >
            {props.iconPath &&
              (
                <div className="mp-form-label-icone">
                  <Icone iconPath={props.iconPath} iconSize={TamanhoIconeEnum.SMALL}></Icone>
                </div>
              )
            }
            <label
              className={`${props.isRequired ? 'mp-form-label-required' : ''}`}
              htmlFor={idSelect}
            >
              {props.label}
            </label>
          </div>
        )}

        <div className={'mp-form-select'}>
          <Select
            inputId={props.id || idSelect}
            className="react-select-container"
            classNamePrefix="react-select"
            noOptionsMessage={() => 'Nenhum item disponível'}
            placeholder={'Selecione...'}
            closeMenuOnSelect={!props.isMulti}
            filterOption={customFilter}
            value={props.value}
            onChange={handleChange}
            options={props.itens}
            formatGroupLabel={formatGroupLabel}
            isClearable={true}
            isSearchable={true}
            isDisabled={props.isDisabled}
            isMulti={props.isMulti}
            styles={customStyles}
            menuPosition={'fixed'}
            maxMenuHeight={props.maxHeight ? props.maxHeight : ''}
            menuPlacement="auto"
            minMenuHeight={300}
            // menuIsOpen={true}
            // menuContainerStyle={{top: 'auto', bottom: '100%'}}
            // menuShouldBlockScroll={true}
          />
        </div>

        <div className={'mp-form-hint'}>
          <p>{props?.hint}</p>
        </div>
      </div>
    </ReactPlaceholder>
  )
}
