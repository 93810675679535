import React, { ReactElement } from "react"

export const skeletonCardBasic = (
  <div className="mp-card-basico-skeleton"></div>
)
export const skeletonCard = (
  <div className='mp-card-group-skeleton'>
    <div className="mp-card-group-skeleton-header">
        <div className="mp-card-avatar-skeleton">
        </div>
        <div className="mp-card-title-skeleton">
          <span></span>
          <span></span>
        </div>
    </div>
    <div className="mp-card-group-skeleton-content">
      <div className="mp-card-group-avatar-skeleton">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p></p>
      <p></p>
      <p></p>
    </div>
  </div>
)

export function getSkeletonType(cardType: string): ReactElement {
  switch (cardType) {
    case "basic": return skeletonCardBasic
    case "cards": return skeletonCard
    default: return skeletonCardBasic
  }
}
