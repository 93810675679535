import React, { useState } from 'react'
import { mdiContentCopy } from '@mdi/js'
import Toaster, { emit } from '../Toaster'
import { Button } from '../Buttons'
import { ButtonSizeEnum, NotificationTypeEnum, ClipboardTypeEnum, ButtonTypeEnum, TooltipPlacementEnum } from '../../enums'
import { createPortal } from 'react-dom'

import CustomTooltip from '../CustomTooltip'

export interface ClipboardProps {
  id?: string
  text: string
  type?: ClipboardTypeEnum
  buttonText?: string
  tooltipText?: string
  iconPath?: string
  noLabel? : boolean
}

export const Clipboard: React.FC<ClipboardProps> = (props: ClipboardProps) => {
  const [appendToasterContainer, setAppendToasterContainer] = useState(false)

  async function copyTextToClipboard (event: any, text: string): Promise<void> {
    // event.stopPropagation()
    event.preventDefault()

    const alreadyHasContainer = document.querySelector('.Toastify')
    if (!alreadyHasContainer)setAppendToasterContainer(true)

    try {
      await navigator.clipboard.writeText(text)
      emit('O conteúdo foi copiado com sucesso!', NotificationTypeEnum.SUCCESS)
    } catch (err) {
      emit('Falha ao copiar', NotificationTypeEnum.DANGER)
      console.error(err)
    }
  }
  const getIconPath = () => {
    let iconPath: string
    if (!props.type || props.type === ClipboardTypeEnum.ICON) {
      if (props.iconPath) {
        iconPath = props.iconPath
      } else {
        iconPath = mdiContentCopy
      }
    }
    return iconPath
  }

  const getButtonText = () => props.buttonText ? props.buttonText : props.noLabel ? null : 'Copiar'

  const CopyButtonText: React.FC<ClipboardProps> = (props: ClipboardProps) => {
    return (<Button
      buttonText={getButtonText() }
      buttonSize={ButtonSizeEnum.SMALL}
      buttonType={ ButtonTypeEnum.ICON}
      tooltipText={props.tooltipText}
      iconPath={getIconPath()}
      handleClick={(event: any) => { copyTextToClipboard(event, props.text) }} />)
  }

  return (
    <>

      { props.type === ClipboardTypeEnum.TEXT_HOVER &&
      <CustomTooltip text={props.tooltipText} placement={TooltipPlacementEnum.TOP}
        footer = { <CopyButtonText {...props}/>
        }>
        {props.text}
      </CustomTooltip>
      }

      { !props.type || props.type === ClipboardTypeEnum.TEXT
        ? <div className='mp-clipboard' id={props?.id} onClick={(event: any) => { copyTextToClipboard(event, props.text) }}>
          <CustomTooltip text="Clique para copiar" placement={TooltipPlacementEnum.TOP}>
            {props.text}
          </CustomTooltip>
        </div>

        : <div datatest-id='mpClipBoard' className='mp-clipboard'>
          {props.type !== ClipboardTypeEnum.TEXT_HOVER && <CopyButtonText {...props}/>}
        </div>
      }

      { appendToasterContainer && createPortal(
        <Toaster/>, document.body
      )}

    </>
  )
}
