export enum ContextualMenuPositionEnum {
  CORNER_TOP_LEFT = 'CORNER TOP_LEFT',
  CORNER_TOP_RIGHT = 'CORNER TOP_RIGHT',
  CORNER_BOTTOM_LEFT = 'CORNER BOTTOM_LEFT',
  CORNER_BOTTOM_RIGHT = 'CORNER BOTTOM_RIGHT',

  INNER_TOP_LEFT = 'INNER TOP_LEFT',
  INNER_TOP_RIGHT = 'INNER TOP_RIGHT',
  INNER_BOTTOM_LEFT = 'INNER BOTTOM_LEFT',
  INNER_BOTTOM_RIGHT = 'INNER BOTTOM_RIGHT',

  INNER_LEFT_UP = 'INNER LEFT_UP',
  INNER_LEFT_DOWN = 'INNER LEFT_DOWN',
  INNER_RIGHT_UP = 'INNER RIGHT_UP',
  INNER_RIGHT_DOWN = 'INNER RIGHT_DOWN'
}