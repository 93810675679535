import React, { useEffect, useRef, useState } from 'react'
import Tree from 'rc-tree'
import { FieldNames as RCTreeFieldNames } from 'rc-tree/lib/interface'
import { MpAutoHeight, OVER_FLOW_CONTAINER_ID } from '../AutoHeight/MpAutoHeight'
import { MpLoader } from '../Loader/loader'
import { MpFileTreeSwitcher } from './FileTreeSwitcher/MpFileTreeSwitcher'
import { MpFileNodeProps } from './FileTreeNode/MpFileTreeNode'
import { MpDivider } from '../Divider'

import 'rc-tree/assets/index.css'
import './mp-file-tree.scss'

type FieldNames = { children: string, key: string, toolTipField?: string }
export interface MpFileTreeProps {
  id?: string
  data: any[]
  renderItem: (file: any) => React.ReactNode
  fieldNames: FieldNames
  isReduced?: boolean
  defaultExpandedIds?: string[] | number[]
  expandAll?: boolean
  selectedIds?: string[] | number[]
  currentScrollId? : string | number
  isLoading?: boolean
  header?: React.ReactNode
  checkable?: boolean
  treeRef?: React.MutableRefObject<any>
  onFilesCheck?: (files: any[]) => void
  checkedsIds?: string[] | number[]
  heightDecrement?: number
}

const TREE_HEADER_ID = 'mp-file-tree-header'
const getDynamicHeight = (): number => {
  const elTree = document.getElementById(OVER_FLOW_CONTAINER_ID)
  const viewportOffset = elTree?.getBoundingClientRect()
  const elHeaderTree = document.getElementById(TREE_HEADER_ID)
  const heightHeader = elHeaderTree?.getBoundingClientRect()
  return (viewportOffset?.height - heightHeader?.height - 5)
}

export const MpFileTree: React.FC<MpFileTreeProps> = (props: MpFileTreeProps) => {
  const treeRef = useRef(null)

  const [expandedKeys, setExpandedKeys] = useState([])

  const [clientWidth, setClientWidth] = useState(document.body.clientWidth)
  const [clientHeight, setClientHeight] = useState(document.body.clientHeight)
  const [dynamicHeight, setDynamicHeight] = useState(getDynamicHeight())

  const onFilesCheck = (_key, { checkedNodes }): void => {
    if (props.onFilesCheck) {
      props.onFilesCheck(checkedNodes)
    }
  }

  if (props?.data?.length > 0 && !props.isLoading && (props?.data[props?.data?.length - 1].id !== IDLASTNODEFAKE)) {
    props.data.push(LAST_NODE)
  }

  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys)
  }

  const expandAll = () => {
    const expandedKeys = []
    const expandMethod = (data: any) => {
      const fieldName = getFieldNames(props.fieldNames)
      if (data[fieldName.key] !== IDLASTNODEFAKE) {
        expandedKeys.push(data[fieldName.key])
        data[fieldName.children] && data[fieldName.children].forEach(expandMethod)
      }
    }
    props.data.forEach(expandMethod)
    setExpandedKeys(expandedKeys)
  }

  const collapseAll = () => {
    setExpandedKeys([])
  }

  useEffect(() => {
    if (props.expandAll) {
      expandAll()
    } else if (props.expandAll !== undefined) {
      collapseAll()
    }
  }, [props.expandAll])

  useEffect(() => {
    setExpandedKeys(props.defaultExpandedIds)
  }, props.defaultExpandedIds)

  const verifyVisibleArea = (event: any): void => {
    event.stopPropagation()
    event.preventDefault()
    const clientWidthSnapshot = document.body.clientWidth
    const clientHeightSnapshot = document.body.clientHeight

    if (clientWidth !== clientWidthSnapshot) {
      setClientWidth(clientWidthSnapshot)
      setDynamicHeight(getDynamicHeight())
    }

    if (clientHeight !== clientHeightSnapshot) {
      setClientHeight(clientHeightSnapshot)
      setDynamicHeight(getDynamicHeight())
    }
  }

  const expand = (tree: any[], nodeId: string | number): void => {
    tree?.forEach(parentNode => {
      const nodeChildren = getFieldNames(props.fieldNames)
      if (parentNode[nodeChildren.children]?.some(child => child[nodeChildren.key] === nodeId)) {
        setExpandedKeys([...expandedKeys, parentNode[nodeChildren.key]])
      } else {
        expand(parentNode[nodeChildren.children], nodeId)
      }
    })
  }

  useEffect(() => {
    window.addEventListener('mousemove', (event: any) => verifyVisibleArea(event))
  }, [])

  useEffect(() => {
  }, [expandedKeys])

  useEffect(() => {
    expand(props.data, props.currentScrollId)

    // TODO: estudar esse async
    setTimeout(() => treeRef?.current?.scrollTo({ key: props.currentScrollId }), 100)
  }, [props.currentScrollId])

  return (
    <>

      <MpAutoHeight id={props?.id} spacing={{ pb: 0, pr: 0 }} heightDecrement={props?.heightDecrement}>
        <div id={TREE_HEADER_ID}>{props.header}</div>
        {props.header && <MpDivider spacing={{ ml: 1, mr: 1 }} />}
        <MpLoader show={props.isLoading} text='Carregando...' />
        {
        props?.data?.length > 0 && ( // Recomendação da documentação para evitar double checking.
            <Tree
              showLine
              checkable={props?.checkable}
              height={dynamicHeight}
              fieldNames={getFieldNames(props.fieldNames)}
              itemHeight={10}
              className={`mp-file-tree   ${props.isReduced ? 'mp-file-tree-reduced' : ''}`}
              selectedKeys={props.selectedIds}
              checkedKeys={props.checkedsIds}
              expandAction='doubleClick'
              titleRender={props?.renderItem}
              showIcon={false}
              switcherIcon={(item: any) => {
              document.getElementById(`file-treenode-wrapper-${item[props.fieldNames.key] as string || item.id as string}`)?.setAttribute('class', `mp-file-treenode-wrapper level-${item.isEnd.length as string}`)
              return <MpFileTreeSwitcher isReduced={props.isReduced} item={item} fieldNames={props?.fieldNames} />
              }
              }
              onCheck={onFilesCheck}
              ref={treeRef}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              treeData={props.data}
            />
          )
        }
      </MpAutoHeight>
    </>
  )
}

const getFieldNames = (fieldNames: FieldNames): RCTreeFieldNames => {
  return fieldNames ? {
    ...fieldNames,
    title: fieldNames?.toolTipField
  } : {
    children: 'children',
    key: 'id',
    title: 'fileName'
  }
}

// geração do último nó da árvore de modo fake, para fazer o acabamento visual das linhas de identação
const IDLASTNODEFAKE = 'IdLastNodeFake'

const LAST_NODE: MpFileNodeProps = {
  id: IDLASTNODEFAKE,
  fileName: 'Last',
  src: null,
  mimeType: null,
  isFolder: false
}
