import 'mp-react-components/dist/mp-react-components.min.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ConsultarAnaliseTecnica } from './pages/ConsultarAnaliseTecnica'
import { GlobalContextProvider } from '../src/context/global'
import LayoutDefault from './components/Layout'
import { AnaliseTecnica } from './pages/AnaliseTecnica'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { Login } from './components/Login'
import { Home } from './pages/Home'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalContextProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={
                <LayoutDefault>
                  <Login />
                  <Home />
                </LayoutDefault>
              }
            />
            <Route
              path='/analise-tecnica'
              element={
                <LayoutDefault>
                  <Login />
                  <AnaliseTecnica />
                </LayoutDefault>
              }
            />
            <Route
              path='/consultar-analise-tecnica'
              element={
                <LayoutDefault>
                  <Login />
                  <ConsultarAnaliseTecnica />
                </LayoutDefault>
              }
            />
          </Routes>
        </BrowserRouter>
      </GlobalContextProvider>
    </ThemeProvider>
  )
}

export default App
