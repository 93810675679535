import React from 'react'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import { skeletonRadio } from './radio-skeleton'


import 'react-placeholder/lib/reactPlaceholder.css'
import './radio.scss'

export interface RadioProps {
  id: string
  isLoading?: boolean,
  value?: string
  isDisabled?: boolean
  checked?: boolean
  label?: string
  isRequired?:  boolean
  group: string
  onCheck: (value: string) => void

}

export class Radio extends React.Component<RadioProps, {}> {
  constructor (props) {
    super(props)
    this.onRadioClicked = this.onRadioClicked.bind(this)
  }

  onRadioClicked = (event): void => {
    event.stopPropagation()

    if (this.props.isDisabled) return

    const value = event.target.value


    if (this.props.onCheck) {

      this.props.onCheck(value)
    }
  }


  render (): any {
    return (
      <ReactPlaceholder showLoadingAnimation ready={!this.props.isLoading} customPlaceholder={skeletonRadio}>


        <div key={this.props.id}
          className={`mp-radio 
                        ${this.props.isDisabled ? 'mp-radio-desabilitado' : ''}
                        ${this.props.isRequired ? 'mp-form-label-required' : ''}
                     `}
        >
          <input readOnly
            id={`radio-${this.props.group}-${this.props.id}`}
            data-testid="radio"
            type="radio"
            checked={this.props.checked}
            name={this.props.group}
            value={this.props.value}
            disabled={this.props.isDisabled}
            onChange={this.onRadioClicked}
          ></input>

          <label className='mp-radio-label' htmlFor={`radio-${this.props.group}-${this.props.id}`}>
            {this.props.label}
          </label>
        </div>

        </ReactPlaceholder>

    )
  }
}
