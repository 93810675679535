
import React, { ReactNode } from 'react'

export interface MenuContextualClickAreaProps {
  id: string
  onClick: (event: any) => void
  children: ReactNode

}

export const MenuContextualClickArea = (props: MenuContextualClickAreaProps): JSX.Element => {
  return (
    <div className='mp-contextual-menu-target' data-testid="contextualMenu" onClick={(event) => props.onClick(event)}>
      {props.children}
    </div>
  )
}
