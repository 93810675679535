import { mdiClose } from '@mdi/js'
import React, { useState } from 'react'
import { ButtonThemeEnum, ButtonTypeEnum, TamanhoModalEnum } from '../../enums'
import { ButtonProps } from '../Buttons/Button'
import InputText from '../InputText'
import CustomModal from '../Modal'
import { getSpacing } from '../Spacing'
import MpTypography from '../Typography'

export type MpFileRenameProps = {
  currentName: string
  show: boolean
  onSave: (savedName: string) => void
  onClose: () => void
}

export const MpFileRename: React.FC<MpFileRenameProps> = ({ currentName, onSave, onClose, show }: MpFileRenameProps) => {
  const [editedName, setEditedName] = useState<string>('')

  function handleSaveEditedName (): void {
    if (editedName?.trim()) {
      onSave(editedName)
    }
    onClose()
    setEditedName('')
  }

  const HEADER_BUTTONS: ButtonProps[] = [
    {
      id: 'mp-file-rename-close-button',
      iconPath: mdiClose,
      handleClick: onClose,
      buttonType: ButtonTypeEnum.ICON
    }
  ]

  const FOOTER_BUTTONS = [
    {
      buttonText: 'CANCELAR',
      buttonTheme: ButtonThemeEnum.DEFAULT,
      handleClick: () => {
        onClose()
        setEditedName('')
      },
      id: 'mp-file-rename-cancel-button'
    },
    {
      buttonText: 'SALVAR',
      id: 'mp-file-rename-save-button',
      buttonTheme: ButtonThemeEnum.CONTAINED,
      handleClick: () => handleSaveEditedName()
    }
  ]

  return (
    <CustomModal
      show={show}
      size={TamanhoModalEnum.MEDIUM}
      onClose={onClose}
      title='Edição de titulo do arquivo'
      headerButtons={HEADER_BUTTONS}
      footerButtons={FOOTER_BUTTONS}>
      <MpTypography spacing={{ ml: 4, mb: 2, mt: 2 }} id='mp-file-rename-current-label' variant='heading-6'>Titulo atual do arquivo</MpTypography>
      <MpTypography spacing={{ ml: 4, mr: 4 }} id='mp-file-rename-current-title'>{currentName}</MpTypography>
      <div className={getSpacing({ m: 2 })}>
        <InputText
          id={'mp-file-rename-input-text'}
          value={editedName}
          handleChange={setEditedName}
          label='Titulo editado do arquivo'
          hint='' />
      </div>
    </CustomModal>
  )
}
