export enum MpFileVieweModeEnum {
  DEFAULT = 'default',
  FULLSCREEN = 'fullScreen',
  NEW_WINDOW = 'newWindow'
}

export const NEW_WINDOW_QUERY_PARAMS_KEY = 'mpReactComponentsFileViewMode'
export const SELECTED_FILE_LOCAL_STORAGE_KEY = 'mpReactComponentsCurrentNewTabFile'

export const NEW_WINDOW_FILE_PAGE_KEY = 'mpReactComponentsNewTabFilePage'
export const NEW_WINDOW_FILE_POSITION_KEY = 'mpReactComponentsNewTabFilePosition'
