/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { MpGrid, MpGridItem, Radio } from 'mp-react-components'
import CustomSelect from 'components/CustomSelect'
import { Field, useFormikContext, ErrorMessage } from 'formik'
import { FormValues } from '../../types'
import Service from '../../../../service'
import { Input } from 'components/Input'
import './style.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 'bold',
      color: '#702826',
      marginBottom: '20px',
    },
  }),
)

const optionsSelect = [
  {
    label: 'MPRJ',
    value: 'MPRJ',
  },
  {
    label: 'SEI',
    value: 'SEI',
  },
  {
    label: 'Inquérito Policial',
    value: 'Inquérito Policial',
  },
  {
    label: 'CNJ',
    value: 'CNJ',
  },
  {
    label: 'CNMP',
    value: 'CNMP',
  },
]

interface Props {
  errorValid: string
  setErrorValid: (error: string) => void
}

export const DetalhamentoSolicitacao: React.FC<Props> = ({ errorValid, setErrorValid }) => {
  const classes = useStyles()

  const formik = useFormikContext<FormValues>()

  const handleChekNumberSat = () => {
    const url = !formik.values['complAltSolicAnt']
      ? `/sat?nroProcesso=${formik.values['numeroProcesso']}&tpProcesso=${formik.values['tipoFonteProcesso']}&orgao=NAR`
      : `/sat?nroProcesso=${formik.values['numeroProcesso']}&tpProcesso=SEI&orgao=NAR`

    Service.getService(url)
      .then((response: any) => {
        formik.setFieldValue('ementa', response.data.ementa)
        setErrorValid('')
      })
      .catch((error) => {
        setErrorValid(error.response.data.message)
        formik.setFieldValue('ementa', '')
      })
  }

  const handleRadioChange = (value: string) => {
    formik.setFieldValue('complAltSolicAnt', JSON.parse(value))
    formik.setFieldValue('ementa', '')
    formik.setFieldValue('numeroProcesso', '')
    setErrorValid('')
  }

  return (
    <div className='content-analise'>
      <MpGrid>
        <MpGridItem xl={12} lg={12} md={12}>
          <Typography className={classes.heading}>Detalhamento da Solicitação</Typography>
        </MpGridItem>
        <MpGridItem xl={12} lg={12} md={12}>
          <p>Complementação ou alteração de solicitação anterior?</p>
        </MpGridItem>
        <Field name='complAltSolicAnt'>
          {({ field }: any) => (
            <>
              <MpGridItem xl={2} lg={2} md={2}>
                <Radio
                  group='complAltSolicAnt'
                  id='1'
                  label='Não'
                  onCheck={(e: any) => {
                    handleRadioChange(e)
                  }}
                  // @ts-ignore
                  value={false}
                  checked={!field.value}
                />
              </MpGridItem>
              <MpGridItem xl={2} lg={2} md={2}>
                <Radio
                  group='complAltSolicAnt'
                  id='2'
                  label='Sim'
                  onCheck={(e: any) => {
                    handleRadioChange(e)
                  }}
                  // @ts-ignore
                  value={true}
                  checked={field.value}
                />
              </MpGridItem>
            </>
          )}
        </Field>
      </MpGrid>
      <MpGrid>
        {!formik.values['complAltSolicAnt'] ? (
          <>
            <MpGridItem xl={4} lg={4} md={4}>
              <Field name='tipoFonteProcesso'>
                {({ field, form }: any) => (
                  <>
                    <CustomSelect
                      value={field.value && { label: field.value, value: field.value }}
                      onChange={(e: any) => {
                        form.setFieldValue(field.name, e.label)
                      }}
                      options={optionsSelect}
                      label='Fonte do Processo*'
                    />
                    <ErrorMessage
                      name='tipoFonteProcesso'
                      component='div'
                      className='error-message'
                    />
                  </>
                )}
              </Field>
            </MpGridItem>
            <MpGridItem xl={3} lg={3} md={3}>
              <Field name='numeroProcesso'>
                {({ field, form }: any) => (
                  <>
                    <Input
                      label='Número processo originário'
                      required
                      placeholder='0000.XXXXXXXX'
                      value={field.value}
                      disabled={!formik.values['tipoFonteProcesso']}
                      onChange={(e: any) => {
                        form.setFieldValue(field.name, e.target.value)
                      }}
                      onBlur={() => {
                        handleChekNumberSat()
                      }}
                    />
                    {errorValid ? (
                      <p className='error-message'>{errorValid}</p>
                    ) : (
                      <ErrorMessage
                        name='numeroProcesso'
                        component='div'
                        className='error-message'
                      />
                    )}
                  </>
                )}
              </Field>
            </MpGridItem>
          </>
        ) : (
          <MpGridItem xl={3} lg={3} md={3}>
            <Field name='numeroProcesso'>
              {({ field, form }: any) => (
                <>
                  <Input
                    label='Número SEI'
                    required
                    placeholder='0000.XXXXXXXX'
                    onChange={(e: any) => {
                      form.setFieldValue(field.name, e.target.value)
                    }}
                    onBlur={() => {
                      handleChekNumberSat()
                    }}
                  />
                  {errorValid ? (
                    <p className='error-message'>{errorValid}</p>
                  ) : (
                    <ErrorMessage name='numeroProcesso' component='div' className='error-message' />
                  )}
                </>
              )}
            </Field>
          </MpGridItem>
        )}
        <MpGridItem xl={12} lg={12} md={12}>
          <Field name='ementa'>
            {({ field }: any) => (
              <>
                <div className='container-textarea'>
                  <label>Ementa *</label>
                  <textarea {...field} />
                </div>
                <ErrorMessage name='ementa' component='div' className='error-message' />
              </>
            )}
          </Field>
        </MpGridItem>
      </MpGrid>
    </div>
  )
}
