import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import { AplicacaoEnum } from '../../enums/AplicacaoEnum'
import './menu.scss'
import { Logo } from '../logo/Logo'

interface Item {
  icone: string
  label: string
  link: string
  hide?: boolean
}

export interface MenuProps {
  itens: Item[]
  aplicacao: AplicacaoEnum
  onClose?: (e: any) => void
}

export interface ItensProps {
  itens: Item[]
  onClose: (e: any) => void
}

const Itens = (props: ItensProps): JSX.Element => {
  return (
    <>
      {props.itens.filter(item => !item.hide).map((item, index) => (
        <li key={index}>
          <Link to={item.link} onClick={props.onClose}>
            <i className="icon-item">
              <Icon path={item.icone} size={1} />
            </i>
            <p className="texto-item">{item.label}</p>
          </Link>
        </li>
      ))}
    </>
  )
}

export const Menu: React.FC<MenuProps> = (props) => {
  return (
    <>
      <aside>
        <div className="menu-header">

          {props.onClose &&
            <button onClick={props.onClose} className="bt-menu">
              <Icon className="icone-bt-menu" path={mdiClose} size={1} />
            </button>
          }
          <Logo />

        </div>
        <div className="menu-lista">
          <ul>
            <Itens itens={props.itens} onClose={props.onClose} />
          </ul>
        </div>
      </aside>

      <div className="backdrop-menu" onClick={props.onClose}></div>
    </>

  )
}
