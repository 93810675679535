import { FormikValues, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

export const initialValues = {
  orgao: 'MCA',
  tipoFonteProcesso: '',
  numeroProcesso: '',
  ementa: '',
  complAltSolicAnt: false,
  tipoSolicitacao: {
    descOutraJust: '',
    tipoSolicitacao: 'PEAT',
    analiseTecnicaPrevia: false,
    justAusenciaCompl: '',
    descServSolicDuviTec: '',
    analisePrioritaria: false,
    analisePrioritariaDesc: '',
    prazoMaximoAnalise: 'Sem prazo',
    files: [],
    esbocoQuisito: '',
  },
}

export const useHandleFiles = (): { changeFiles: (files: any[]) => void } => {
  const [files, setFiles] = useState<any[]>([])

  const formik = useFormikContext<FormikValues>()

  const changeFiles = (newFiles: any[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
  }

  useEffect(() => {
    const filesSemDuplicatas = files.filter(
      (obj: any, index: number, self: any) =>
        index === self.findIndex((o: any) => o.src === obj.src && o.fileName === obj.fileName),
    )

    const fileByteArray = filesSemDuplicatas.map((item: any) => {
      return {
        byteArray: Object.values(new TextEncoder().encode(JSON.stringify(item))),
        fileName: item.fileName,
        fileSizeInBytes: item.fileSizeInBytes,
        mimeType: item.mimeType,
        identificacao: 'a',
      }
    })

    formik.setFieldValue('tipoSolicitacao.files', fileByteArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  return { changeFiles }
}
