export enum TamanhoModalEnum {
  /*
  Pequeno = 'sm',
  Grande = 'lg'
  */
  XXXSMALL = 'mp-modal-xxxsm', // 10%
  XXSMALL = 'mp-modal-xxsm', // 20%
  XSMALL = 'mp-modal-xsm', // 30%
  SMALL = 'mp-modal-sm', // 40%
  MEDIUM = 'mp-modal-md', // 50%
  LARGE = 'mp-modal-lg', // 60%
  XLARGE = 'mp-modal-xlg', // 70%
  XXLARGE = 'mp-modal-xxlg', // 80%
  XXXLARGE = 'mp-modal-xxxlg', // 90%
  FULLSCREEN = '', // 100%
  CUSTOM = 'mp-modal-custom' // X'px'/'%' Y'px'/'%'
}
