/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { MpFileUpload, MpGrid, MpGridItem, Radio } from 'mp-react-components'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { FormValues } from '../../types'
import { AnalisePrioritaria } from '../AnalisePrioritaria'
import { useHandleFiles } from 'pages/AnaliseTecnica/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 'bold',
      color: '#B79266',
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(16),
      color: '#B79266',
      marginBottom: '20px',
    },
  }),
)

export const OutrosServicos: React.FC = () => {
  const formik = useFormikContext<FormValues>()

  const { changeFiles } = useHandleFiles()

  const classes = useStyles()

  return (
    <>
      {formik.values.tipoSolicitacao.tipoSolicitacao === 'AJOST' && (
        <div className='content-tipo-solicitacao'>
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <Typography className={classes.heading}>Ação Judicial</Typography>
              <Typography className={classes.subtitle}>Outros Serviços Técnicos</Typography>
            </MpGridItem>
            <MpGridItem xl={12} lg={12} md={12}>
              <p>
                Há documentação de análise técnica por perito judicial, documentação apresentada
                pela parte contrária, ou órgão do Executivo com poder de polícia sobre os fatos?
              </p>
            </MpGridItem>

            <Field name='tipoSolicitacao.analiseTecnicaPrevia'>
              {({ form, field }: any) => (
                <>
                  <MpGridItem xl={2} lg={2} md={2}>
                    <Radio
                      group='analiseTecnicaPrevia'
                      id='1'
                      label='Não'
                      onCheck={(e: any) => {
                        form.setFieldValue(field.name, JSON.parse(e))
                      }}
                      // @ts-ignore
                      value={false}
                      checked={!field.value}
                    />
                  </MpGridItem>
                  <MpGridItem xl={2} lg={2} md={2}>
                    <Radio
                      group='analiseTecnicaPrevia'
                      id='2'
                      label='Sim'
                      onCheck={(e: any) => {
                        form.setFieldValue(field.name, JSON.parse(e))
                      }}
                      checked={field.value}
                      // @ts-ignore
                      value={true}
                    />
                  </MpGridItem>
                </>
              )}
            </Field>
          </MpGrid>
          {!formik.values.tipoSolicitacao.analiseTecnicaPrevia ? (
            <MpGrid>
              <MpGridItem xl={12} lg={12} md={12}>
                <Field name='tipoSolicitacao.justAusenciaCompl'>
                  {({ field }: any) => (
                    <>
                      <div className='container-textarea'>
                        <label>Justificativa para ausência de complementaridade *</label>
                        <textarea {...field} placeholder='Digite seu texto aqui.' />
                      </div>
                      <ErrorMessage
                        name='tipoSolicitacao.justAusenciaCompl'
                        component='div'
                        className='error-message'
                      />
                    </>
                  )}
                </Field>
              </MpGridItem>
            </MpGrid>
          ) : (
            <MpGrid>
              <MpGridItem xl={12} lg={12} md={12}>
                <MpFileUpload
                  actions={['remove']}
                  label=''
                  onUploadSuccess={(e) => {
                    changeFiles(e)
                  }}
                  placeholder='Clique aqui ou arraste para anexar um arquivo'
                  signButtonDisabledDelay={2000}
                />
              </MpGridItem>
            </MpGrid>
          )}
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <Field name='tipoSolicitacao.descServSolicDuviTec'>
                {({ field }: any) => (
                  <>
                    <div className='container-textarea'>
                      <label>Descrição do serviço técnico solicitado e dúvida técnica *</label>
                      <textarea {...field} placeholder='Digite seu texto aqui.' />
                    </div>
                    <ErrorMessage
                      name='tipoSolicitacao.descServSolicDuviTec'
                      component='div'
                      className='error-message'
                    />
                  </>
                )}
              </Field>
            </MpGridItem>
          </MpGrid>
          <AnalisePrioritaria />
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <MpFileUpload
                actions={['remove']}
                label='Documentação referente a solicitação'
                onUploadSuccess={(e) => changeFiles(e)}
                placeholder='Clique aqui ou arraste para anexar um arquivo'
                signButtonDisabledDelay={2000}
              />
            </MpGridItem>
          </MpGrid>
        </div>
      )}
    </>
  )
}
