import React, { useState, ReactNode, useEffect } from 'react'
import './collapsible-button.scss'

export interface CollapsibleButtonProps {
  show: boolean
  collapsibleArea: ReactNode
  children: ReactNode
  contentCssClass? : string
  collapsibleAreaCssClass?: string
  handleClick?: () => void

}

export const CollapsibleButton: React.FC<CollapsibleButtonProps> = (props: CollapsibleButtonProps) => {
  const [show, setShow] = useState(props.show)

  const handleClick = (): void => {
    setShow(prevState => !prevState)

    props.handleClick?.()
  }

  useEffect(() => { setShow(props.show) }, [props.show])

  return (
    <>
      <span className={`${props.collapsibleAreaCssClass ? props.collapsibleAreaCssClass : ''}`} onClick={handleClick} >{props.collapsibleArea }
      </span>

      {show && (
        <div className= {`mp-collapsible-content  ${props.contentCssClass ? props.contentCssClass : ''}`}>
          {props.children}
        </div>)}
    </>
  )
}
