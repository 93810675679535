import React from 'react' 

export const skeletonCustomDatepicker = (
    <div className="mp-datePicker-skeleton">
        <div className='valor'></div>
    </div>
)

export const skeletonCustomDatepickerComLabel = (
    <div className="mp-datePicker-skeleton">
        <div className='label'></div>
        <div className='valor'></div>
    </div>
)
