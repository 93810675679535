import React, { useEffect, useRef, useState } from 'react'
import { ButtonSizeEnum, ButtonTypeEnum, ContextualBarTypeEnum, TooltipPlacementEnum } from '../../../../enums'
import { mdiCloseBoxMultiple, mdiFileSign } from '@mdi/js'
import { MpFileUploadItemProps } from '../../MpFileUpload'
import ContextualActionBar from '../../../ContextualActionBar'
import { MpFileUploadRowAction } from '../MpFileUploadTable'

export type MpFileUploadTableContextualActionBarProps = {
  files: MpFileUploadItemProps[]
  actions: MpFileUploadRowAction[]
  onSignSelecteds: (files: MpFileUploadItemProps[]) => void
  onRemoveSelecteds: (unSelectedsFiles: MpFileUploadItemProps[]) => void
  onClose: (files: MpFileUploadItemProps[]) => void
  signButtonDisabled: boolean
}

export const MpFileUploadTableContextualActionBar: React.FC<MpFileUploadTableContextualActionBarProps> = (props: MpFileUploadTableContextualActionBarProps) => {
  if (!props.files?.length) {
    return <></>
  }
  const {
    files,
    onSignSelecteds,
    onRemoveSelecteds,
    actions,
    onClose,
    signButtonDisabled
  } = props

  const DEFAULT_BUTTON_PROPS = { buttonType: ButtonTypeEnum.ICON, buttonSize: ButtonSizeEnum.SMALL }
  const REMOVE_ALL_BUTTON = { ...DEFAULT_BUTTON_PROPS, iconPath: mdiCloseBoxMultiple }
  const SIGN_ALL_BUTTON = { ...DEFAULT_BUTTON_PROPS, iconPath: mdiFileSign }

  return (
    <ContextualActionBar
      itens={files}
      onClose={onClose}
      selectedKeyProp='isSelected'
      contextualBarType={ContextualBarTypeEnum.ALTA}
      count={files.filter(f => f.isSelected).length}
      hide={!files.filter(f => f.isSelected).length}
      actions={[
        {
          ...DEFAULT_BUTTON_PROPS,
          ...SIGN_ALL_BUTTON,
          id: 'mp-file-upload-contextual-action-bar-sign-selecteds',
          tooltipText: 'Assinar selecionados',
          tooltipPosition: TooltipPlacementEnum.LEFT,
          handleClick: () => {
            onSignSelecteds(files.filter(f => f.isSelected))
          },
          hide: !actions?.includes('signSelecteds'),
          isDisabled: signButtonDisabled
        },
        {
          ...DEFAULT_BUTTON_PROPS,
          ...REMOVE_ALL_BUTTON,
          tooltipText: 'Remover selecionados',
          id: 'mp-file-upload-contextual-action-bar-remove-selecteds',
          tooltipPosition: TooltipPlacementEnum.LEFT,
          handleClick: () => {
            onRemoveSelecteds(files.filter(f => !f.isSelected))
          },
          hide: !actions?.includes('removeSelecteds')
        }
      ]}
    />
  )
}
