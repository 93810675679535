import React from 'react'
import { TipoAvatarEnum } from '../../enums/TipoAvatarEnum'
import { AvatarSizeEnum } from '../../enums/AvatarSizeEnum'
import { CoresEnum } from '../../enums/CoresEnum'
import AvatarPadrao from '../AvatarPadrao'
import AvatarSigla from '../AvatarSigla'
import AvatarIcone from '../AvatarIcone'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import { skeletonAvatarCircle, skeletonAvatarPadrao } from './avatar-skeleton'

import 'react-placeholder/lib/reactPlaceholder.css'
import './avatar.scss'
import '../CustomTooltip/custom-tooltip.scss'

export interface AvatarProps {
  tipo: TipoAvatarEnum
  isLoading?: boolean
  ativo?: boolean
  desabilitado? : boolean
  oculto?: boolean
  conteudo?: string
  tooltipText?: string
  icone?: any
  color?: CoresEnum
  background?: CoresEnum
  onClick?: (e: any) => void
  borderPosition?: 'top' | 'left' | 'bottom' | 'right'
  size?: AvatarSizeEnum
}

export const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const { conteudo, ativo, tooltipText, background, color, icone, onClick, tipo, oculto, borderPosition, size, desabilitado } = props
  const propsPadrao = { conteudo, tooltipText, background, color }

  const withClick = (Avatar: JSX.Element): JSX.Element => (onClick ? <a onClick={onClick}>{Avatar}</a> : <>{Avatar}</>)

  const config: Array<{ id: TipoAvatarEnum, component: JSX.Element }> = [
    { id: TipoAvatarEnum.Padrao, component: withClick(<AvatarPadrao {...propsPadrao} />) },
    { id: TipoAvatarEnum.Sigla, component: withClick(<AvatarSigla {...propsPadrao} ativo={ativo} />) },
    { id: TipoAvatarEnum.Icone, component: withClick(<AvatarIcone {...propsPadrao} icone={icone} />) }

  ]
  const skeletonType = (): JSX.Element => {
    if ((props.tipo === TipoAvatarEnum.Icone) || (props.tipo === TipoAvatarEnum.Sigla)) return skeletonAvatarCircle
    if (props.tipo === TipoAvatarEnum.Padrao) return skeletonAvatarPadrao
  }

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonType()}>

      { !oculto &&
      <>
        {config.find(({ id }) => id === tipo)?.component}
      </>
      }

    </ReactPlaceholder>
  )
}
