import React, { ReactNode, useState } from 'react'
import SplitPane from 'react-split-pane'
import Pane from 'react-split-pane/lib/Pane'

import './split-pane.scss'

export interface MpSplitPaneProps {
  children: ReactNode
  splitDirection?: 'vertical' | 'horizontal'
  allowResize?: boolean
  onChange?: (newSize: number) => void
  panes?: MpPaneProps[]
}
export interface MpPaneProps {
  children: ReactNode
}
export const MpSplitPane: React.FC<MpSplitPaneProps> = (props: MpSplitPaneProps) => {
  const INITIAL_SIZE_PANE1 = localStorage.getItem('splitPos')
  const MAX_SIZE_PANE1_DEFAULT: string = '50%'
  const BREAKPOINT_XS = 576
  const BREAKPOINT_SM = 992
  let initialSizePane1Default: string = '33.33%'
  let minSizePane1Default: string = '16.67%'

  if (window.innerWidth < BREAKPOINT_SM) { // breakpoint sm
    initialSizePane1Default = '37.5%'
    minSizePane1Default = '25%'
  }
  if (window.innerWidth < BREAKPOINT_XS) { // breakpoint xs
    initialSizePane1Default = '50%'
    minSizePane1Default = '50%'
  }

  const hasInitialPosition = INITIAL_SIZE_PANE1 || initialSizePane1Default
  const [splitPosition, setSplitPosition] = useState(hasInitialPosition)

  return (
    <SplitPane
      split={'vertical'}
      allowResize={props?.allowResize}
      onChange={(size) => {
        const sizeString = size[0] as string
        const valuePercentage = (sizeString.substring(0, size[0].length - 2) + '%')
        localStorage.setItem('splitPos', valuePercentage)
        setSplitPosition(valuePercentage)
      }}
    >
      {props.panes.map((pane, index) => (
        index === 0
          ? <Pane key={index} {...pane} className='mp-pane-button' minSize={minSizePane1Default} initialSize={splitPosition} maxSize={MAX_SIZE_PANE1_DEFAULT} />
          : <Pane key={index} {...pane} className='mp-pane-button' />
      ))}
    </SplitPane>
  )
}
