import React, { ReactNode } from 'react'
import { AbsoluteContextualMenu } from './AbsoluteContextuaMenu'
import { RelativeContextualMenu } from './RelativeContextualMenu'
import { ContextualMenuPositionEnum } from '../../enums'
import './contextual-menu.scss'

export interface ContextualMenuProps {
  id: string
  children: ReactNode
  itensMenu: ReactNode
  onCloseAllLabel?: string
  isAbsolute?: boolean
  isFlexible?: boolean
  position?: ContextualMenuPositionEnum
  handleIsOpened?: (isOpened: boolean) => void
  onCloseAll?: () => void
}

export const CONTEXTUAL_MENU_MAX_DIMENSION_PX: number = 320
export const SPACING_DEFAULT_OFFSET: number = 16

export const ContextualMenu: React.FC<ContextualMenuProps> = (props: ContextualMenuProps) => {
  return (
    <div className='mp-contextual-menu-wrapper'>
      {props.isAbsolute ? <AbsoluteContextualMenu {...props}/> : <RelativeContextualMenu {...props}/>}
    </div>

  )
}
