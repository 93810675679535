import React from 'react'
import { NotificationTypeEnum, TamanhoIconeEnum, CoresEnum } from '../../enums'
import Icone from '../icone'
import { Button } from '../Buttons'
import { getNotificationIcon } from '../../utils/NotificationIconHandler'
import './notification-box.scss'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'

export interface NotificationBoxProps {
  notificationType: NotificationTypeEnum
  message: string
  isLight?: boolean
  isLoading?: boolean
  hide?: boolean
  button?: { buttonText: string, onClick: () => void }
}

export const NotificationBox: React.FC<NotificationBoxProps> = (props: NotificationBoxProps) => {
  return (
    <ReactPlaceholder type='rect' color={CoresEnum.GRAY_100.value} style={{ width: '100%', height: '56px' }} showLoadingAnimation ready={!props.isLoading}>

      { (!props.hide) &&
      <div className={`notification-message ${props.notificationType} ${props.isLight ? 'light' : ''} `}>
        <div className="icon-message">
          <Icone iconPath={getNotificationIcon(props.notificationType)} iconSize={TamanhoIconeEnum.LARGE} />
        </div>
        <div className="text-message">
          <p>
            {props.message}
          </p>
        </div>
        { (props.button?.buttonText) &&
        <div className="button-message">
          <Button buttonText={props.button?.buttonText} handleClick={props.button?.onClick} />
        </div>
        }
      </div>}

    </ReactPlaceholder>
  )
}
