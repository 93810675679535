import React from 'react'
import { ButtonTypeEnum, CustomModal, NotificationTypeEnum, TamanhoModalEnum } from '../../index'
import '../ProgressBar/progressBar.scss'

export interface LoaderModalProps {
  onClose?: () => void
  progress: number
  fileName?: string
  isLight?: boolean
  hide?: boolean
  title?: string
}

export const LoaderModal: React.FC<LoaderModalProps> = (props: LoaderModalProps) => {
  type lineProgress = number & {_type_: 'lineProgress'}
  const linePercentValid = (value: number): lineProgress => {
    if (value < 0 || value > 100) {
      throw new Error(`The value ${value} is not a valid percent`)
    }
    return value as lineProgress
  }

  const fillerStyles = {
    width: `${linePercentValid(props.progress)}%`
  }

  return (
    <CustomModal
      footerButtons={props?.onClose && [{ buttonText: 'Fechar', buttonType: ButtonTypeEnum.DEFAULT, handleClick: () => props.onClose() }]}
      isLight={!!props.isLight}
      modalType={NotificationTypeEnum.INFO}
      show={!props.hide}
      size={TamanhoModalEnum.MEDIUM}
      title={props.title}
    >
      <div className="mp-progress-bar-loader-container">
        <p>
          {props.fileName ? props.fileName : 'Processando...'}
          <strong>
            {props.progress}%
          </strong>
        </p>
        <div className="mp-progress-bar">
          <div style={fillerStyles} className="mp-progress-bar-loader"></div>
        </div>
      </div>
    </CustomModal>
  )
}
