export function formatBytes (bytes, withSufix = false): string | number {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  if (withSufix) {
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  } else {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  }
}

export function mbToByte (mb: number, withSufix = false): number | string {
  const result = (mb * 1048576)
  if (withSufix) {
    return result.toString().concat('Bytes')
  } else {
    return result
  }
}

export function byteToMB (bytes: number, withSufix = false): number | string {
  const result = (bytes / 1e+6).toFixed(2)
  if (withSufix) {
    return result.toString().concat('MB')
  } else {
    return parseFloat(result)
  }
}
