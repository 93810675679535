import * as React from 'react'
import Icon from '@mdi/react'
import { mdiMenu } from '@mdi/js'
import { AplicacaoEnum } from '../../enums/AplicacaoEnum'
import { Menu } from '../menu/Menu'
import './toggle.scss'

interface State {
  isOpen: boolean
}

interface Item {
  icone: string
  label: string
  link: string
  hide?: boolean

}

export interface ToggleProps {
  itens: Item[]
  aplicacao: AplicacaoEnum
  logoIcone?: string
}

class ToggleMenu extends React.Component<ToggleProps, State> {
  constructor (props: Readonly<ToggleProps>) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
    let scrollBody = 'auto'
    if (!this.state.isOpen) {
      scrollBody = 'hidden'
    }
    document.querySelector('body').style.overflow = scrollBody
  }

  render () {
    return (
      <>
        {this.state.isOpen &&
                    <Menu onClose={this.handleClick} aplicacao={this.props.aplicacao} itens={this.props.itens} />
        }
        <button onClick={ this.handleClick } className="bt-menu">
          <Icon className="icone-bt-menu" path={mdiMenu} size={1} />
        </button>
      </>
    )
  }
}

export { ToggleMenu }
