import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import React, { ReactNode, useEffect, useState } from 'react'
import { ButtonSizeEnum, ButtonTypeEnum, TimelineTypeEnum, TooltipPlacementEnum } from '../../enums'
import { MpAutoHeight } from '../AutoHeight/MpAutoHeight'
import Button from '../Buttons'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'
import { MpLoader } from '../Loader/loader'

import './timeline.scss'

const CSS_CLASS_INITIAL_ELEMENT: string = 'initial'
const CSS_CLASS_BASE_ELEMENT: string = 'base'
const CSS_CLASS_LAST_ELEMENT: string = 'last'

export interface MPTimelineProps {
  id?: string
  timelineType?: TimelineTypeEnum
  events: MPTimelineEventProps[]
  isLoading?: boolean
  withOutBackground?: boolean
  heightDecrement?: number
  selectedId?: string
}
export interface MPTimelineEventProps {
  id: string
  description?: string
  status?: string
  eventDate?: string
  eventHour?: string
  isSelected?: boolean
  isOpen?: boolean
  eventDetails?: MPTimelineEventDetailProps[]
  onClick?: (e: any) => void
  handleCollapse?: (timeLineEvent: MPTimelineEventProps) => void
}
export interface MPTimelineEventDetailProps {
  id: string
  content?: ReactNode
  onClick?: (e: any) => void
}
interface MPTimelineElementProps {
  eventPosition: number
  timelineType?: TimelineTypeEnum
  sizeList: number
}
interface MPTimelineDateProps {
  dateTime?: string
  isOpen?: boolean
  handleCollapse?: (timeLineEvent: MPTimelineEventProps) => void
}

export const getEventPosition = (index: number, sizeList: number): typeof CSS_CLASS_INITIAL_ELEMENT | typeof CSS_CLASS_BASE_ELEMENT | typeof CSS_CLASS_LAST_ELEMENT => {
  if (index === 0) return CSS_CLASS_INITIAL_ELEMENT
  if (index === sizeList - 1) return CSS_CLASS_LAST_ELEMENT
  return CSS_CLASS_BASE_ELEMENT
}

export const isFullTimeline = (timelineType: TimelineTypeEnum): boolean => timelineType === TimelineTypeEnum.FULL

export const TimelineDateArea: React.FC<MPTimelineDateProps> = (props: MPTimelineDateProps) => {
  const [collapseIcon, setCollapseIcon] = useState(props.isOpen ? mdiChevronUp : mdiChevronDown)

  const onCollapseClick = (timeLineEvent: MPTimelineEventProps): void => {
    if (props.isOpen) {
      setCollapseIcon(mdiChevronDown)
    } else {
      setCollapseIcon(mdiChevronUp)
    }
    if (props.handleCollapse) {
      props.handleCollapse(timeLineEvent)
    }
  }
  return (
    <>
      <div className={'mp-timeline-element initial'}>
        <span></span>
      </div>
      <div className="mp-timeline-date">
        <span>{props.dateTime}</span>
      </div>
      <div className="mp-timeline-button">
        <Button handleClick={onCollapseClick} buttonType={ButtonTypeEnum.ICON} iconPath={collapseIcon} buttonSize={ButtonSizeEnum.SMALL} />
      </div>
    </>
  )
}

export const TimelineElementArea: React.FC<MPTimelineElementProps> = (props: MPTimelineElementProps) => {
  return (
    <div className={`mp-timeline-element ${isFullTimeline(props.timelineType) ? 'base' : getEventPosition(props.eventPosition, props.sizeList)} `}>
      <span></span>
    </div>
  )
}

export const MPTimeline: React.FC<MPTimelineProps> = (props: MPTimelineProps) => {
  const [timelineEvents, setTimelineEvents] = useState([])

  const toggleCollapse = (eventCollapsed: MPTimelineEventProps): void => {
    const updatedEvents = timelineEvents?.map(timelineEvent => {
      if (timelineEvent.id === eventCollapsed.id) {
        timelineEvent.isOpen = !timelineEvent.isOpen
        return timelineEvent
      } else {
        return timelineEvent
      }
    })
    setTimelineEvents(updatedEvents)

    if (eventCollapsed.handleCollapse) {
      eventCollapsed.handleCollapse(eventCollapsed)
    }
  }

  const refs = {}

  props.events?.map(event => {
    const ref = event?.eventDetails?.reduce((acc, value) => {
      acc[`timeline-event-ref-${value.id}`] = React.createRef()
      return acc
    }, {})
    Object.assign(refs, ref)
  })

  const scrollTo = (eventId): void => {
    refs[eventId]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }

  const eventIsOpen = (timeLineEvent: MPTimelineEventProps): boolean => {
    return timeLineEvent.isOpen || timeLineEvent.eventDetails?.some(detail => detail.id.toString() === props.selectedId)
  }

  useEffect(() => {
    setTimelineEvents(props.events)
  }, [props.events])

  useEffect(
    () => { scrollTo(`timeline-event-ref-${props.selectedId}`) },
    [props.selectedId]
  )

  return (
    <MpAutoHeight id={props.id} withoutBackground={true} heightDecrement={props.heightDecrement}>
      {props.isLoading ? (<MpLoader show={props.isLoading} text={'Carregando...'}/>) : (
        <div className={`mp-timeline 
            ${props.timelineType === TimelineTypeEnum.FULL ? 'movements' : ''}
            ${props?.withOutBackground ? 'without-background' : ''}
          `}
        >
          {timelineEvents?.map((timelineEvent, index, eventsList) => (
            <div key={index}>
              {isFullTimeline(props.timelineType) && (index === 0 || (index > 0 && (timelineEvent.eventDate !== eventsList[index - 1].eventDate))) &&
                <div key={`timeline-event-date-'${index}`} className='mp-timeline-item'>
                  <TimelineDateArea isOpen={eventIsOpen(timelineEvent)} dateTime={timelineEvent.eventDate} handleCollapse={() => toggleCollapse(timelineEvent)} />
                </div>
              }
              <div key={`timeline-event-'${index}`}
                style={{ display: (isFullTimeline(props.timelineType) && !eventIsOpen(timelineEvent)) ? 'none' : '' }}
                className={`mp-timeline-item  ${eventIsOpen(timelineEvent) ? 'subitem' : ''}`}>

                {!isFullTimeline(props.timelineType) &&
                  <>
                    <div className="mp-timeline-status">
                      {timelineEvent?.status}
                    </div>
                    <TimelineElementArea eventPosition={index} sizeList={timelineEvents.length} {...props} />
                    <div className={`mp-timeline-number ${index === 0 ? 'first' : ''} ${timelineEvent.isSelected ? 'current' : ''}  ${timelineEvent.onClick ? 'has-onclick' : ''} `} onClick={() => timelineEvent.onClick ? timelineEvent.onClick(timelineEvent.id) : null} >
                      {timelineEvent?.id}
                    </div>
                    <div className="mp-timeline-class">
                      {timelineEvent?.description}
                    </div>
                  </>
                }

                {isFullTimeline(props.timelineType) && (eventIsOpen(timelineEvent)) &&
                  <>
                    <TimelineElementArea eventPosition={index} sizeList={timelineEvents.length} {...props} />

                    <div className="mp-timeline-content">
                      { timelineEvent?.description &&
                        <>
                          <div className="mp-timeline-title">
                            <CustomTooltip placement={TooltipPlacementEnum.TOP} text={timelineEvent?.description}>
                              <span className='truncate-text' style={{ width: '100%', fontFamily: 'Roboto Medium' }}>
                                {timelineEvent?.description}
                              </span>
                            </CustomTooltip>
                          </div>
                        </>
                      }
                      { timelineEvent.eventDetails?.map((item: MPTimelineEventDetailProps, index) => (
                        <div key={index} id={`mp-time-line-event-id${item.id}`}>
                          <div ref={refs[`timeline-event-ref-${item.id}`]}
                            className={`mp-timeline-list has-onclick ${props?.selectedId == item.id ? 'mp-timeline-event-active' : 'mp-nada-ativo'}`}
                            onClick={() => {
                            item.onClick?.(item.id)
                            }}>
                            <div className="mp-timeline-text">
                              {item?.content}
                            </div>
                          </div>
                          {timelineEvent.eventDetails.length - 1 === index && (
                            <div className="mp-timeline-timer">
                              {timelineEvent.eventHour}
                            </div>)}
                        </div>
                      ))}

                      { timelineEvent.eventDetails.length === 0 && (
                        <div className="mp-timeline-timer">
                          {timelineEvent.eventHour}
                        </div>
                      )}
                    </div>
                  </>
                }
              </div>
            </div>
          ))}
          {isFullTimeline(props.timelineType) &&
          <div>
            <div className='mp-timeline-item subitem'>
              <div className={`mp-timeline-element ${CSS_CLASS_LAST_ELEMENT}`}><span></span></div>
            </div>
          </div>
          }
        </div>
      )}
    </MpAutoHeight>
  )
}
