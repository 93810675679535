import { CoresEnum } from '@/enums/CoresEnum'
import { CSSProperties } from 'react'

export interface BorderProps {
  bw?: number
  bwt?: number
  bwb?: number
  bwl?: number
  bwr?: number
  bc?: CoresEnum
  br?: number
  bst?: 'dashed' | 'dotted' | 'solid' | 'double' | 'groove' | 'ridge' | 'inset' | 'outset' | 'none'
}
export function getBorder (props: BorderProps): any {
  const borderStyleConfig: CSSProperties = {
    borderWidth: props?.bw ? `${props.bw.toString()}px` : '',
    borderTopWidth: props?.bwt ? `${props.bwt.toString()}px` : '',
    borderBottomWidth: props?.bwb ? `${props.bwb.toString()}px` : '',
    borderLeftWidth: props?.bwl ? `${props.bwl.toString()}px` : '',
    borderRightWidth: props?.bwr ? `${props.bwr.toString()}px` : '',
    borderStyle: props?.bst ? `${props.bst}` : '',
    borderColor: props?.bc ? `${props.bc.value}` : '',
    borderRadius: props?.br ? `${props.br.toString()}px` : ''
  }
  return borderStyleConfig
}
