
import Icone from '../../../components/icone'
import { MpLoader } from '../../../components/Loader/loader'
import MpTypography from '../../../components/Typography'
import { Button } from '../../../components/Buttons'
import { MimeType, MpFileProps, MpGetFileService } from '@/domain/File'
import { ButtonThemeEnum, CoresEnum, NotificationTypeEnum, TamanhoIconeEnum } from '../../../enums'
import { mdiAlertOutline, mdiCloseCircleOutline } from '@mdi/js'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { MpFileListViewerProps } from '../FileListViewer/MpFileListViewer'

type Props = {
  currentFile: MpFileProps
  hideListViewer?: boolean
  onRetry?: (currentFile: MpFileProps) => void
  authenticatedService?: MpGetFileService
  listViewer?: React.ReactElement<MpFileListViewerProps>
}

export const MpFileViewerControl: React.FC<Props> = (props: Props) => {
  const [src, setSrc] = useState<string>(null)
  const showFileViewer = (props.currentFile)
  const { hideListViewer } = props

  const effect = (): void => {
    setSrc(null)
    if (props.authenticatedService) {
      setAuthenticatedSrc()
      return
    }
    if (props?.currentFile?.page && props?.currentFile?.src) {
      setSrc(`${props?.currentFile?.src}#page=${props.currentFile.page}&view=FitH&toobarButton=false`)
    } else {
      setSrc(props.currentFile?.src)
    }
  }

  async function setAuthenticatedSrc (): Promise<void> {
    await props.authenticatedService.setBlob(props.currentFile.id as string)
    let fileUrl = URL.createObjectURL(new Blob([props.authenticatedService.blob], { type: props.currentFile.mimeType }))
    if (props?.currentFile?.page) {
      fileUrl += `#page=${props.currentFile.page}&view=FitH&toobarButton=false`
    }
    setSrc(fileUrl)
  }

  useEffect(effect, [props.currentFile])
  return (
    <>
      <MpLoader show={props?.currentFile?.isLoading} data-testid='mp-file-view-loading' textColor={CoresEnum.WHITE} className='mp-file-view-loading' text='Carregando...' />
      <div className='mp-file-viewer'>
        {!hideListViewer && (props.listViewer)}
        {showFileViewer && getFileViewer(props, src)}
      </div>
    </>
  )
}

const getFileViewer = (props: Props, src: string): React.ReactElement | string => {
  const { mimeType } = props.currentFile

  if (!mimeType || !src) {
    return <></>
  }

  const onError = (event: SyntheticEvent): void => {
  }
  const MIME_TYPE_COMPONENT_MAP: { [key in MimeType]: React.ReactElement } = {
    'text/html': <embed onError={onError} data-testid='embed-html' id='embed-html' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'application/pdf': <embed onError={onError} data-testid='embed-pdf' id='embed-pdf' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'application/rtf': <embed onError={onError} data-testid='embed-rtf' id='embed-rtf' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'application/msword': <embed onError={onError} data-testid='embed-word' id='embed-word' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'video/mp4': <video onError={onError} id='video-mp4' data-testid='video-mp4' controls> <source src={src} /></video>,
    'video/quicktime': <video onError={onError} id='video-quicktime' data-testid='video-quicktime' controls> <source src={src} /></video>,
    'video/mpeg': <video onError={onError} id='video-mpeg' data-testid='video-mpeg' controls> <source src={src} /></video>,
    'audio/video': <video onError={onError} id='audio-video' data-testid='audio-video' controls> <source src={src} /></video>,
    'audio/mpeg': <audio onError={onError} id='audio-mpeg' data-testid='audio-mpeg' controls><source src={src} type={mimeType} /></audio>,
    'audio/ogg': <audio onError={onError} id='audio-ogg' data-testid='audio-ogg' controls><source src={src} type={mimeType} /></audio>,
    'image/png': <img onError={onError} id='image-png' data-testid='image-png' src={src} />,
    'image/jpeg': <img onError={onError} id='image-jpeg' data-testid='image-jpeg' src={src} />,
    'application/octet-stream': <embed onError={onError} data-testid='octet-stream' id='octet-stream' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'application/zip': <embed onError={onError} data-testid='zip' id='zip' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'application/x-rar-compressed': <embed onError={onError} data-testid='x-rar-compressed' id='x-rar-compressed' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'application/vnd.ms-excel': <embed onError={onError} data-testid='ms-excel' id='ms-excel' type={mimeType} src={src} height="100%" width="100%"></embed>,
    'application/vnd.ms-powerpoint': <embed onError={onError} data-testid='ms-power-point' id='ms-power-point' type={mimeType} src={src} height="100%" width="100%"></embed>
  }

  const getErrorClassName = (typeError: NotificationTypeEnum): JSX.Element => {
    switch (typeError) {
      case (NotificationTypeEnum.DANGER):
      {
        return <>
          <div className="mp-file-viewer-icon">
            <Icone iconColor={CoresEnum.DANGER} iconSize={TamanhoIconeEnum.BIG} iconPath={mdiCloseCircleOutline} />
          </div>
          <MpTypography align='center' spacing={{ mb: 1, mt: 1 }} element={'h2'} id={'title'}>
          Link não encontrado
          </MpTypography>

          <MpTypography element={'p'} variant={'paragraph'} id={'texto'}>
              Algum registro está incorreto e o arquivo não pode ser encontrado na base de dados.
          </MpTypography>
        </>
      }

      case (NotificationTypeEnum.WARNING):
      {
        return <>
          <div className="mp-file-viewer-icon">
            <Icone iconColor={CoresEnum.WARNING} iconSize={TamanhoIconeEnum.BIG} iconPath={mdiAlertOutline} />
          </div>
          <MpTypography align='center' spacing={{ mb: 2, mt: 1 }} element={'h2'} id={'title'}>
              Algo Deu Errado!
          </MpTypography>
          <MpTypography spacing={{ mb: 2 }} element={'p'} variant={'paragraph'} id={'texto'}>
              O arquivo está indisponível no momento. Isto pode ter sido causado por uma série de motivos.
              Pode ter ocorrido:
          </MpTypography>
          <MpTypography element={'p'} variant={'paragraph'} id={'texto'}>
              a) uma falha temporária na conexão;
          </MpTypography>
          <MpTypography element={'p'} variant={'paragraph'} id={'texto'}>
              b) o arquivo inexiste na base de dados; ou
          </MpTypography>
          <MpTypography spacing={{ mb: 2 }} element={'p'} variant={'paragraph'} id={'texto'}>
              c) algum outro motivo ainda não foi identificado
          </MpTypography>
          <MpTypography spacing={{ mb: 4 }} element={'p'} variant={'paragraph'} id={'texto'}>
              Você pode aguardar alguns instantes e tentar novamente, mas não há garantia que o arquivo estará disponível.
          </MpTypography>
          <div className="mp-file-viewer-button">
            <Button buttonTheme={ButtonThemeEnum.WARNING} buttonText='Tentar Novamente' handleClick={() => props.onRetry(props.currentFile)} />
          </div>
        </>
      }

      default: return <></>
    }
  }

  const typeError = props.authenticatedService?.typeError || props.currentFile?.typeError

  return (
    <>
      { typeError
        ? <div className="mp-file-viewer-error">
          <div className='mp-file-viewer-error-wrapper'>
            {getErrorClassName(typeError)}
          </div>
        </div>
        : <div className='mp-file-viewer-wrapper'>
          <div id='viewer' className='mp-file-viewer-filecontent'>{MIME_TYPE_COMPONENT_MAP[mimeType]}</div>
        </div>
      }
    </>
  )
}
