import ReactPlaceholder from "react-placeholder/lib";
import styled, { css } from "styled-components";

interface ChevronButtonProps {
  isNext?: boolean
}

export const Arrow = styled.div`
  .__floater__arrow,
  span {
    left: 40px;
  }
`;

export const TooltipBody = styled.div`
  background-color: #fff;
  position: relative;
  width: 430px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
`;

export const TooltipTitle = styled.h2`
  color: #363534;
  padding: 20px;
  margin: 0;
  font-size: 16px;
  font-family: 'Roboto';
`;

export const TooltipHeader = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e7;
  height: 60px;
  align-items: center;
`;

export const TooltipContent = styled.div`
  padding: 20px;
`;

export const TooltipFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e8e8e7;
  align-items: center;
  padding: 8px;
`;

export const ToolTipContentParagraphy = styled.p``;

export const StepButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StepLabel = styled.label`
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #5E5E5E;
`;
