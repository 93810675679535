import Icon from '@mdi/react'
import React from 'react'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { CoresEnum, TamanhoIconeEnum } from '../../enums'
import MpSpacingProps, { getSpacing } from '../Spacing'
import { getIconeSkeleton } from './icone-skeleton'
import './icone.scss'

export interface IconeProps {
  iconPath: string
  isLoading?: boolean
  iconSize?: TamanhoIconeEnum
  iconColor?: CoresEnum
  spacing?: MpSpacingProps
}

export const Icone: React.FC<IconeProps> = (props: IconeProps) => {
  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={getIconeSkeleton(props.iconSize)}>
      <Icon className={getSpacing(props.spacing)} style={{ color: (props.iconColor) ? props.iconColor.value : '' }} path={props.iconPath} size={ props.iconSize ? props.iconSize : '' } />
    </ReactPlaceholder>
  )
}
