import React, { useState } from 'react'
import MultiToggle from 'react-multi-toggle'
import { MultiToggleSizeEnum, MultiToggleTypeEnum } from '../../enums'

import 'react-multi-toggle/style.css'
import './multi-toggle.scss'
import { MpMultiToggleButton } from './MultiToggleButton'

export interface MpMultiToggleProps {
  size: MultiToggleSizeEnum
  type: MultiToggleTypeEnum
  options: MultiToggleOption[]
  defaultOption?: MultiToggleOption
  handleSelect: (value: any) => void
}

interface Option {
  displayName: any
  value: any
  optionClass: string
}

export interface MultiToggleOption {
  text?: string
  icon?: string
  value: any
}

export const MpMultiToggle: React.FC<MpMultiToggleProps> = (props: MpMultiToggleProps) => {
  if (props.options.length < 2 || props.options.length > 6) {
    throw new Error('[MultiToggle] Minimo de 2 e máximo de 6 elementos permitidos')
  }

  const [selectedOption, setSelectedOption] = useState(props.defaultOption ? props.defaultOption.value : props.options[0].value)

  const selectOption = (value: any): any => {
    setSelectedOption(value)
    props.handleSelect(value)
  }

  const options: Option[] = props.options.map(
    (option: MultiToggleOption, index) => {
      return {
        displayName: (
          <MpMultiToggleButton
            id={index}
            value={option[props.type]}
            isSelected={selectedOption === option.value}
            type={props.type}
            size={props.size}
          />
        ),
        value: option.value,
        optionClass: 'toggle'
      }
    }
  )

  return (
    <MultiToggle
      options={options}
      className={props.size}
      selectedOption={selectedOption}
      onSelectOption={selectOption}
    />
  )
}
