import React from 'react'
import Icone from '../icone'
import { mdiInformationOutline } from '@mdi/js'
import { CoresEnum, TamanhoIconeEnum, InfoTextTypeEnum } from '../../enums'

import './info-text.scss'
import MpTypography from '../Typography'
import classNames from 'classnames'

export interface MpInfoTextProps {
  text: string
  label: string
  type: InfoTextTypeEnum
}

export const MpInfoText: React.FC<MpInfoTextProps> = (props: MpInfoTextProps) => {
  return (
    <div className={
      `mp-d-flex
       mp-flex-direction_${props.type}
       mp-align-itens_start`}>
      <div className={classNames(
        `mp-d-flex
         mp-flex-direction_row
         mp-align-itens_center`,
        { 'mp-info-text-icon-label-area': props.type === InfoTextTypeEnum.BLOCK }
      )}>
        <Icone
          iconColor={CoresEnum.GRAY_600}
          iconSize={TamanhoIconeEnum.XSMALL}
          iconPath={mdiInformationOutline} />
        <MpTypography
          id={'mp-info-label'}
          spacing={{ ml: 0.5 }}
          align={'center'}>
          {props.label}
        </MpTypography>
      </div>
      <MpTypography
        id={'mp-info-text'}
        spacing={{ ml: 0.5 }}
        align={'center'}>
        {props.text}
      </MpTypography>
    </div>
  )
}
