import React, { useEffect, useState } from 'react'
import { mdiClose } from '@mdi/js'
import { ButtonTypeEnum, ContextualBarTypeEnum, TooltipPlacementEnum } from '../../enums'
import { ButtonGroup } from '../ButtonGroup/ButtonGroup'
import { Button, ButtonProps } from '../Buttons/Button'
import './contextual-action-bar.scss'
import MpCheckbox from '../Checkbox'
import CustomTooltip from '../CustomTooltip'

export type ContextualActionBarActionProp = ButtonProps & {
  handleActionClick?: (item: any | any[]) => void
}
export interface ContextualActionBarProps {
  contextualBarType: ContextualBarTypeEnum
  count: number
  total?: number
  hide: boolean
  itens?: any[]
  selectedKeyProp?: string
  onSelectAll?: (itens: any[]) => void
  actions?: ContextualActionBarActionProp[]
  onClose?: (itens?: any[]) => void
}

export const ContextualActionBar: React.FC<ContextualActionBarProps> = (props: ContextualActionBarProps) => {
  const [hide, setHide] = useState(props.hide)
  const [allSelected, setAllSelected] = useState(false)
  const itens = props?.itens
  const selectedKeyProp = props?.selectedKeyProp
  const onSelectAll = props?.onSelectAll

  const check = (item: any): any => {
    if (!item) return
    if (item[selectedKeyProp]) return item
    item[selectedKeyProp] = true
    return item
  }

  const uncheck = (item: any): any => {
    if (!item) return
    if (!item[selectedKeyProp]) return item
    item[selectedKeyProp] = false
    return item
  }

  const checkAll = (isAllSelected: boolean): void => {
    setAllSelected(isAllSelected)
    if (isAllSelected) {
      onSelectAll(itens?.map(check))
    } else {
      onSelectAll(itens?.map(uncheck))
    }
  }

  const close = (): void => {
    if (props.onClose) {
      props.onClose(itens?.map(uncheck))
    }
    setHide(true)
  }

  const handleActionClick = (act): void => {
    if (act?.handleActionClick) {
      act.handleActionClick(itens?.filter(i => i[selectedKeyProp]))
    }
    if (act?.handleClick) {
      act.handleClick()
    }
  }

  useEffect(() => { onSelectAll && setAllSelected(props.itens?.every(i => i[selectedKeyProp])) }, [props?.itens])
  useEffect(() => { setHide(props.hide) }, [props.hide])

  return (
    <>
      {(!hide) &&
        <div data-testid='mp-contextual-action-bar' className={`mp-contextual-action-bar ${props.contextualBarType ? props.contextualBarType : ContextualBarTypeEnum.BAIXA} `} >
          <div className="mp-contextual-action-bar-icon">
            <Button
              id={'mp-contextual-action-bar-close-button'}
              buttonType={ButtonTypeEnum.ICON}
              hide={!props.onClose}
              isLoading={props?.actions?.some(a => a.isLoading)}
              handleClick={close}
              iconPath={mdiClose}
              tooltipText="Cancelar"
            />
          </div>
          <div className="mp-contextual-action-bar-text">
            <span>{props.count} selecionado{props.count > 1 ? 's' : ''}</span>
            {props.total && <span> de { props.total}</span>}
          </div>
          {
            <ButtonGroup>
              {
                (onSelectAll) &&
              <span className="mp-btn mp-btn-icone">
                <span className="btn-icon-content">
                  <CustomTooltip text={allSelected ? 'Tudo selecionado' : 'Selecionar tudo'} placement={TooltipPlacementEnum.TOP}>
                    <MpCheckbox
                      id='mp-select-all-button'
                      isChecked={allSelected}
                      onChange={(checked) => checkAll(checked)} />
                  </CustomTooltip>
                </span>
              </span>
              }
              {
                props.actions?.map((act, index) =>
                  <Button
                    id={act.id}
                    key={`mp-act-item-${index}`}
                    buttonType={ButtonTypeEnum.ICON}
                    isDisabled={act.isDisabled}
                    iconPath={act.iconPath}
                    isLoading={act.isLoading}
                    hide={act.hide}
                    tooltipPosition={act.tooltipPosition}
                    handleClick={() => handleActionClick(act)}
                    tooltipText={act.tooltipText} />
                )}
            </ButtonGroup>
          }
        </div>}
    </>
  )
}
