import { mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js'
import React from 'react'
import { TooltipRenderProps } from 'react-joyride'
import {
  StepButtonsArea,
  StepLabel,
  TooltipBody,
  TooltipContent,
  ToolTipContentParagraphy,
  TooltipFooter,
  TooltipHeader,
  TooltipTitle
} from './TooltipTutorial.styles'
import { TooltipPlacementEnum } from '../../../enums'
import { ButtonTutorial } from '../ButtonTutorial/ButtonTutorial'
import { TutorialButtonTypeEnum } from '../ButtonTutorial/ButtonTutorial.styles'

export const MpTooltipTutorial: React.FC<TooltipRenderProps> = (
  props: TooltipRenderProps
) => {
  return (
    <TooltipBody 
      data-testid={'tooltip-tutorial'}
      id={'tooltip-tutorial'}
      {...props.tooltipProps}>
      <TooltipHeader>
        <TooltipTitle>{props?.step?.title}</TooltipTitle>
        <ButtonTutorial
          buttonType={TutorialButtonTypeEnum.ICON}
          iconPath={mdiClose}
          tooltipPosition={TooltipPlacementEnum.TOP}
          tooltipText={'Encerrar Ajuda'}
          joyRideRenderProps={props.skipProps}
        />
      </TooltipHeader>
      <TooltipContent>
        <ToolTipContentParagraphy>
          {props?.step?.content}
        </ToolTipContentParagraphy>
      </TooltipContent>
      <TooltipFooter>
        <ButtonTutorial
          buttonType={TutorialButtonTypeEnum.DEFAULT}
          tooltipPosition={TooltipPlacementEnum.TOP}
          tooltipText={'Encerrar Ajuda'}
          joyRideRenderProps={props.skipProps}
          id={'tooltip-skip'}
          buttonText={'ENCERRAR'}
        />
        <StepLabel>
          {props.index + 1} de {props.size}
        </StepLabel>
        <StepButtonsArea>
          <ButtonTutorial
            buttonType={TutorialButtonTypeEnum.ICON}
            iconPath={mdiChevronLeft}
            tooltipPosition={TooltipPlacementEnum.TOP}
            tooltipText={'Anterior'}
            disabled={props.index === 0}
            joyRideRenderProps={props.backProps}
            id={'tooltip-back'}
          />
          <ButtonTutorial
            buttonType={TutorialButtonTypeEnum.ICON}
            iconPath={mdiChevronRight}
            tooltipPosition={TooltipPlacementEnum.TOP}
            tooltipText={'Próximo'}
            disabled={(props.size - 1) === props.index}
            hasLeftButton
            joyRideRenderProps={props.primaryProps}
            id={'tooltip-next'}
          />
        </StepButtonsArea>
      </TooltipFooter>
    </TooltipBody>
  )
}
