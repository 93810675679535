import React from 'react';
import { BrowserRouter, Switch, Route, HashRouter } from 'react-router-dom';

export interface Routes {
    path: string
    label: string
    Component: any
}

export interface RouterProps {
    routes: Routes[]
}

export const CustomRouter: React.FC<RouterProps> = (props: RouterProps) => (
    <BrowserRouter>
        <HashRouter>
            <Switch>
                {props.routes.map(({ path, Component }, key) => (
                    <Route
                        exact
                        path={path}
                        key={key}
                        render={componentsRender(props, Component)}
                    />
                ))}
            </Switch>
        </HashRouter>
    </BrowserRouter>
);

function componentsRender(props: RouterProps, Component: any) {
    return element => {
        const crumbs = props.routes
            .filter(({ path }) => element.match.path.includes(path))
            .map(({ path, ...rest }) => ({
                path: Object.keys(element.match.params).length
                    ? Object.keys(element.match.params).reduce(
                        (path, param) => path.replace(`:${param}`, element.match.params[param]),
                        path
                    )
                    : path,
                ...rest
            }));

        return (
            <Component {...element} crumbs={crumbs} />
        );
    };
}
