import React from 'react'
import { MpLoader } from '../../Loader/loader'

export const MpFileListViewerLoader = (props: { isLoading: boolean, isCollapsed?: boolean, isScrollingUp?: boolean, hide?: boolean }): React.ReactElement => {
  const loaderStyle = props.isCollapsed
    ? <MpLoader className={`${props.isScrollingUp ? 'mp-loading-top-collapsed' : ''}`} show={props.isLoading && !props.hide} />
    : <MpLoader className={`${props.isScrollingUp ? 'mp-loading-top' : ''}`} show={props.isLoading && !props.hide} text='Carregando...' />

  return <>
    {!props.hide && props.isLoading && loaderStyle}
  </>
}
