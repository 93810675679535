import React from 'react'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import { skeletonCheckbox } from './checkbox-skeleton'

import 'react-placeholder/lib/reactPlaceholder.css'
import './checkbox.scss'

export interface MpCheckboxProps {
  id: string
  isRequired?: boolean
  isLoading?: boolean
  isChecked?: boolean
  isDisabled?: boolean
  label?: string
  onChange?: (checked: boolean) => void
}

export const MpCheckbox: React.FC<MpCheckboxProps> = (props: MpCheckboxProps) => {
  const onCheckboxClicked = (event): void => {
    event.stopPropagation()
    if (props.isDisabled) return

    if (props.onChange) {
      props.onChange(event.target.checked)
    }
  }


  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonCheckbox}>

    <div
      className={`mp-checkbox  ${props.isDisabled ? 'mp-checkbox-desabilitado' : ''
        }   `}
    >
      <input readOnly
        aria-checked={props.isChecked ? true : false}
        data-testid={props.id}
        type="checkbox"
        id={props.id}
        checked={props.isChecked ? props.isChecked : false}
        disabled={props.isDisabled}
        onClick={onCheckboxClicked}
      ></input>
      <label htmlFor={props.id}>
        {props.label}{props.isRequired ? ' *' : ''}
      </label>
    </div>
    </ReactPlaceholder>

  )
}
