import React, { useState } from 'react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { ButtonTypeEnum, ButtonSizeEnum, TamanhoIconeEnum } from '../../enums'
import ButtonGroup from '../ButtonGroup'
import { Button, ButtonProps } from '../Buttons/Button'
import { Icone } from '../icone/Icone'

import { MpListItemCompoundProps, MpListItemContentProps, MpListItemIconAreaProps, MpListItemIconLeftAreaProps, MpListItemIconRighAreaProps, MpListItemLinkProps, MpListItemSimpleProps, MpListProps } from './list.interfaces'
import './list.scss'
import classNames from 'classnames'

export const MpList: React.FC<MpListProps> = (props: MpListProps) => {
  return (
    (props.isRoot
      ? <div className={`mp-list ${props.withBackground ? 'mp-list-background' : ''}`}>
        <ul className='mp-list-root'>
          {props.children}
        </ul>
      </div>
      : <ul>
        {props.children}
      </ul>)
  )
}

export const MpListItemLink: React.FC<MpListItemLinkProps> = (props: MpListItemLinkProps) => {
  return (
    <a onClick={props.handleClick} className={classNames('mp-list-item-link ', { disabled: props.isDisabled, active: props.isActive })}>
      {props.children}
    </a>
  )
}

export const MpListItemSimple: React.FC<MpListItemSimpleProps> = (props: MpListItemSimpleProps) => {
  return (
    <li>
      <div className={`mp-list-item-simple ${props.isMultiline ? 'mp-list-multiline' : 'mp-list-truncate'}`}>
        {props.children}
      </div>
    </li>
  )
}

export const MpListItemCompound: React.FC<MpListItemCompoundProps> = (props: MpListItemCompoundProps) => {
  const [showChild, setShowChild] = useState(!props.isCollapsable)

  return (
    <li>
      <div className="mp-list-item">
        <div>
          {props.content}
        </div>
        {props.isCollapsable &&
          <Button
            id={props.buttonId ? props.buttonId : 'button-show-child'}
            buttonSize={ButtonSizeEnum.SMALL}
            buttonType={ButtonTypeEnum.ICON}
            iconPath={showChild ? mdiChevronUp : mdiChevronDown}
            handleClick={() => setShowChild(!showChild)} />}
      </div>

      {showChild && props.children}
      <hr />
    </li>
  )
}

const MpListItemIconArea: React.FC<MpListItemIconAreaProps> = (props: MpListItemIconAreaProps) => {
  if ((props.buttons == undefined) == (props.iconPath == undefined)) {
    throw new Error('[MPList] O uso do componente MPListItemIconArea, deve ter um ícone ou um botão ')
  }

  const buttonGroup: ButtonProps[] = Array.isArray(props.buttons) ? props.buttons : [props.buttons]
  return (
    <div className={`mp-list-item-side ${props.cssClassName ? props.cssClassName : ''}`} >
      <div className="mp-list-item-icon">

        {props.iconPath && <Icone data-testid={'data-testid'} key={'keyId'} iconPath={props.iconPath} iconSize={TamanhoIconeEnum.SMALL} />}

        {props.buttons && renderButtonArea(buttonGroup, props.singleButton)}

      </div>
    </div>
  )
}

export const MpListItemIconLeftArea: React.FC<MpListItemIconLeftAreaProps> = (props: MpListItemIconLeftAreaProps) => {
  return (
    <MpListItemIconArea singleButton cssClassName={` ${props.buttons || props.iconPath ? 'mp-list-item-left-margin' : ''} `} {...props} />
  )
}

export const MpListItemIconRightArea: React.FC<MpListItemIconRighAreaProps> = (props: MpListItemIconRighAreaProps) => {
  return (
    <MpListItemIconArea cssClassName={` ${props.buttons || props.iconPath ? 'mp-list-item-right-margin' : ''} `} {...props} />
  )
}

export const MpListItemContent: React.FC<MpListItemContentProps> = (props: MpListItemContentProps) => {
  return (
    <div className='mp-list-item-content'>
      {props.children}
    </div>
  )
}

const renderButton = (button: ButtonProps): JSX.Element => {
  return (
    <Button
      id={`mp-btn-list-${button.id ? button.id : (Math.random() * 100)}`}
      key={`mp-btn-list-${button.id ? button.id : (Math.random() * 100)}`}
      buttonType={ButtonTypeEnum.ICON}
      buttonSize={ButtonSizeEnum.DEFAULT}
      iconPath={button.iconPath}
      isDisabled={button.isDisabled}
      tooltipText={button.tooltipText}
      handleClick={() => button.handleClick()}
    />
  )
}
const renderButtonArea = (buttons: ButtonProps[], singleButton: boolean): JSX.Element => {
  let buttonGroup = null

  if (Array.isArray(buttons) && !singleButton) {
    buttonGroup = <ButtonGroup>
      {buttons?.map((btn, index) =>
        renderButton(btn)
      )}
    </ButtonGroup>
  } else {
    buttonGroup = renderButton(buttons[0])
  }
  return (
    buttonGroup
  )
}
