import React from "react"
import CustomTooltip from "../../CustomTooltip"
import { TooltipPlacementEnum } from '../../../enums'

import { Button, ButtonIconContent, ButtonWrapper, TutorialButtonTypeEnum } from './ButtonTutorial.styles'
import Icon from "@mdi/react"

export interface ButtonToltipProps {
    id?: string
    tooltipPosition: TooltipPlacementEnum
    tooltipText: string
    hasLeftButton?: boolean
    disabled?: boolean
    iconPath?: string
    joyRideRenderProps?: any
    buttonType: TutorialButtonTypeEnum
    buttonText?: string
    handleClick?: (model? : any) => void
}

export const ButtonTutorial: React.FC<ButtonToltipProps> = (props: ButtonToltipProps) => {
    return (
      <Button
        {...props?.joyRideRenderProps}
        {...(props.handleClick && { onClick: props.handleClick })}
        data-testid={`${props.id}-button`}
        buttonType={props.buttonType}
        hasLeftButton={props?.hasLeftButton}
        disabled={props?.disabled}
      >
        <ButtonIconContent>
          <CustomTooltip
            text={props.tooltipText}
            placement={props.tooltipPosition}
            isHide={props?.disabled}
          >
            {props.buttonType === TutorialButtonTypeEnum.ICON && (
              <Icon path={props?.iconPath} size={1} />
            )}
          </CustomTooltip>
        </ButtonIconContent>
        {props?.buttonText}
      </Button>
    );
}