import React from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import { ButtonTypeEnum, ButtonSizeEnum, ChipSizeEnum, ChipStyleEnum, CoresEnum, TamanhoIconeEnum } from '../../enums'
import { LimitedTextContent } from '../../utils/LimitedTextContent'
import { skeletonChip } from './chip-skeleton'
import MpSpacingProps, { getSpacing } from '../Spacing'
import Icone from '../icone'
import Button from '../Buttons'

import 'react-placeholder/lib/reactPlaceholder.css'
import './chip.scss'

export interface ChipProps {
  text: string
  tooltipText?: string
  alwaysShowTooltip?: boolean
  color?: CoresEnum
  maxLength?: number
  size?: ChipSizeEnum
  style?: ChipStyleEnum
  spacing?: MpSpacingProps
  iconLeft?: string
  buttonRight?: { icon: string, onClick: () => void }
  isLoading?: boolean
}

export const Chip: React.FC<ChipProps> = (props: ChipProps) => {
  const styleContained = {
    background: props.color ? props.color.value : CoresEnum.TERTIARY.value
  }
  const styleOutline = {
    color: props.color ? props.color.value : CoresEnum.TERTIARY.value,
    borderColor: props.color ? props.color.value : CoresEnum.TERTIARY.value
  }
  const styleContainedIcon = {
    color: props.color ? props.color.value : CoresEnum.TERTIARY.value
  }
  const styleOutlineIcon = {
    color: CoresEnum.WHITE.value,
    background: props.color ? props.color.value : CoresEnum.TERTIARY.value
  }

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonChip}>

      <div className={`mp-chip 
          ${props.iconLeft && (props.size === ChipSizeEnum.MEDIUM) ? 'mp-chip-icon' : ''}             { /* só existe ícone para tipo MEDIUM = Default }
          ${props.buttonRight?.icon && (props.size === ChipSizeEnum.MEDIUM) ? 'mp-chip-button' : ''}  { /* só existe botão para tipo MEDIUM = Default }
          ${props.color &&
            (props.color.name.endsWith('light') ||
              props.color.name.endsWith('gray_50') ||
              props.color.name.match('gray_100') ||
              props.color.name.match('gray_200') ||
              props.color.name.match('gray_300')
            ) ? 'mp-chip-light' : ''
          } 
          ${props.spacing ? getSpacing(props.spacing) : ''} 
          ${props.size ? props.size : ChipSizeEnum.MEDIUM} 
          ${props.style ? props.style : ChipStyleEnum.DEFAULT} 
        `}
      style={
        (props.style === ChipStyleEnum.OUTLINE) ? styleOutline : styleContained
      }
      >

        {props.iconLeft && props.size === ChipSizeEnum.MEDIUM && (
          <div className="mp-chip-icon-content" style={ (props.style === ChipStyleEnum.OUTLINE) ? styleOutlineIcon : styleContainedIcon }>
            <Icone iconPath={props.iconLeft} iconSize={TamanhoIconeEnum.XSMALL} />
          </div>
        )}

        <span className="text-content">
          <LimitedTextContent alwaysShowTooltip={props.alwaysShowTooltip} tooltipText={props.tooltipText} maxLength={props.maxLength ? props.maxLength : 100}>
            {props.text}
          </LimitedTextContent>
        </span>

        {props.buttonRight?.icon && props.size === ChipSizeEnum.MEDIUM && (
          <div className="mp-chip-button-content">
            <span style={ (props.style === ChipStyleEnum.OUTLINE) ? styleOutlineIcon : styleContainedIcon }>
              <Button buttonSize={ButtonSizeEnum.SMALL}
                buttonType={ButtonTypeEnum.ICON}
                handleClick={props.buttonRight.onClick}
                iconPath={props.buttonRight.icon} />
            </span>
          </div>
        )}

      </div>

    </ReactPlaceholder>
  )
}
