import React, { ReactNode } from 'react'
import './form-area.scss'

export interface MpFormAreaProps {
  id? : string
  children: ReactNode
}

export const MpFormArea: React.FC<MpFormAreaProps> = (props: MpFormAreaProps) => {
  return (
    <div id={props.id} key={props.id} className="mp-form-content">
      {props.children}
    </div>
  )
}
