import React, { memo, useRef } from 'react'
import { CoresEnum } from '../../../enums'
import { MpFileProps } from '../../../domain/File'
import { MpFileContextualMenuItemProps } from '../FileTreeContextualMenu/MpFileTreeContextualMenu'
import { MpFileTreeNodeContextualMenu } from './FileTreeNodeContextualMenu/MpFileTreeNodeContextualMenu'
import { MpFileTreeNodeIcon } from './FileTreeNodeIcon/MpFileTreeNodeIcon'
import { MpFileTreeNodeNotifications } from './FileTreeNodeNotifications/MpFileTreeNodeNotifications'
import { MpFileTreeNodeTitle } from './FileTreeNodeTitle/MpFileTreeNodeTitle'

import './mp-file-treenode.scss'
import { mpIconMp } from '../../../../src'

export type MpFileNodeNotificationProps = { id?: string, tooltipText?: string, icon: string, color?: CoresEnum, hide?: boolean }

export interface MpFileNodeProps extends MpFileProps {
  inactive?: boolean
  isFolder?: boolean
  isParentWithParts?: boolean
  notifications?: MpFileNodeNotificationProps[]
  contextualMenu?: MpFileContextualMenuItemProps[]
  maxLength?: number
  onFileClick?: (file: MpFileNodeProps) => void
  isReduced?: boolean
}

export const MpFileTreeNode: React.FC<MpFileNodeProps> = memo(function any (props: MpFileNodeProps) {
  const itemRef = useRef(null)

  const onClick = (): void => {
    if (props?.onFileClick) {
      props?.onFileClick(props)
    }
  }

  return (
    <div ref={itemRef} className={'  mp-file-treenode-wrapper'} id={`file-treenode-wrapper-${props.id}`}>
      <MpFileTreeNodeContextualMenu isReduced={props.isReduced} node={props} contextualMenu={props.contextualMenu} />
      <div className={`mp-file-treenode ${props.contextualMenu ? 'with-contextual-menu' : ''}` }>
        <div className="mp-file-treenode-title"
          onClick={onClick}
          data-testid={`mp-file-tree-treenode-title-${props.id}`}
        >
          <MpFileTreeNodeIcon isMp={props.notifications?.some((notification: MpFileNodeNotificationProps) => notification.icon === mpIconMp)} inactive={props.inactive} mimeType={props.mimeType} isFolder={props.isFolder} isParentWithParts={props.isParentWithParts} />
          <div className='file-treenode-title-and-notifications'>
            <MpFileTreeNodeTitle inactive={props.inactive} maxLength={props.maxLength} fileName={props.fileName} typeError={props.typeError} id={props.id} />
            <MpFileTreeNodeNotifications notifications={props.notifications} isReduced={props.isReduced} />
          </div>
        </div>
      </div>
    </div>
  )
})
