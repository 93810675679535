export enum AvatarSizeEnum {
  DEFAULT = 'mp-avatar-quadrado-medium',
  XSMALL = 'mp-avatar-quadrado-xsmall',
  XSMALL_RETANGULAR = 'mp-avatar-retangular-xsmall',
  SMALL = 'mp-avatar-quadrado-small',
  MEDIUM = 'mp-avatar-quadrado-medium',
  MEDIUM_RETANGULAR = 'mp-avatar-retangular-medium',
  LARGE= 'mp-avatar-quadrado-large',
  XLARGE= 'mp-avatar-quadrado-xlarge',
  XXLARGE= 'mp-avatar-quadrado-xxlarge'
}
