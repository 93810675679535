import React, { forwardRef } from 'react'
import { mdiCloseBoxMultiple } from '@mdi/js'
import CardBasico from '../CardBasico'
import MpDivider from '../Divider'
import MpList, { MpListItemLink, MpListItemSimple, MpListItemIconLeftArea, MpListItemContent } from '../List'
import { ContextualMenuProps } from './ContextualMenu'

export interface MenuContextualContentProps extends ContextualMenuProps {
  style? : React.CSSProperties
}
export const MenuContextualContent = forwardRef<MenuContextualContentProps, any>((props: MenuContextualContentProps, contentRef: any) => {
  MenuContextualContent.displayName = 'MenuContextualContent'

  return (
    <div className="mp-contextual-menu-content" style={props.style ? props.style : null} ref={contentRef}>
      <CardBasico id='menu' data-testid={'menu_' + props.id}
        cssClass={`mp-contextual-menu-card ${props.isFlexible ? '' : 'mp-contextual-menu-fixed-card'}`}
        hideFooter hideHeader>

        <div className={'mp-contextual-menu-fixed-size-items-container'}>

          <MpList isRoot withBackground>
            {props.itensMenu}
          </MpList>
        </div>
        {props.onCloseAll && (
          <div className={'mp-contextual-menu-fixed-item'}>

            <MpList isRoot withBackground >
              <MpDivider />
              <MpListItemLink handleClick={props.onCloseAll}>
                <MpListItemSimple>
                  {props.onCloseAllLabel && (
                    <>
                      <MpListItemIconLeftArea iconPath={mdiCloseBoxMultiple} />
                      <MpListItemContent>{props.onCloseAllLabel}</MpListItemContent>
                    </>
                  )}
                </MpListItemSimple>
              </MpListItemLink>
            </MpList>
          </div>
        )}
      </CardBasico>
    </div>

  )
})
