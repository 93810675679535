import React from 'react'
import { Icone } from './../icone'
import { TamanhoIconeEnum } from './../../enums'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'

import { skeletonSwitch } from './custom-switch-skeleton'


import 'react-placeholder/lib/reactPlaceholder.css'
import './custom-switch.scss'


export interface CustomSwitchProps {
  id: string
  isLoading?: boolean,
  isDisabled?: boolean
  isChecked?: boolean
  label?: string
  labelIconPath?:string
  onChange?: (checked: boolean) => any
  isRequired?: boolean
}

export const CustomSwitch: React.FC<CustomSwitchProps> = (props: CustomSwitchProps) => {

  const onCheckboxClicked = (isChecked: any) => {
    if (props.isDisabled) return

    let switchValue =isChecked.currentTarget.checked
    if (props.onChange) {
      props.onChange(switchValue)
    }
  }
  
  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonSwitch}>

    <div className="mp-form-control">

      <div className="mp-form-control-header">
        <label className='mp-form-label' htmlFor="inputText">
          <div  className={`mp-form-label-icon ${props.isRequired ? "mp-form-label-required" : ""}`}  >

            {
            props.labelIconPath 
            ? 
            <span className="icone">
              <Icone iconPath={props.labelIconPath} iconSize={TamanhoIconeEnum.SMALL}/>
            </span>
            : ""}
            <span className="label">{props.label}</span>
          </div>
        </label>
      </div>

      <div className="mp-form-control-content">
        <div data-testid="switch" className={`mp-switch ${props.isDisabled ? 'mp-switch-checkbox-disabled' : ''}`}>
          <input id={props.id} className="mp-switch-checkbox"
            disabled={props.isDisabled} checked={props.isChecked} onChange={onCheckboxClicked} type="checkbox" />
          <label className='mp-switch-label' htmlFor={props.id}></label>
        </div>
      </div>

    </div>
    </ReactPlaceholder>
  )
}
