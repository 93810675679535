import React, { ReactNode } from 'react'
import { Logo } from '../logo/Logo'
import { ToggleMenu } from '../toggle'
import { ButtonGroup } from '../ButtonGroup'
import { AplicacaoEnum, CoresEnum, DividerTypeEnum } from '../../enums/'
import { InputSearch, InputSearchProps } from '../InputSearch/InputSearch'

import './header.scss'
import Breadcrumbs, { Crumb } from '../breadcrumbs'
import MpDivider from '../Divider'

export interface HeaderProps {
  menuItems: MenuItem[]
  application: AplicacaoEnum
  crumbs?: Crumb[]
  inputSearchProps? : InputSearchProps
  children?: ReactNode
  content?: ReactNode
  isLight?: boolean
  showMenu?: boolean
  showLogo?: boolean
  showButtonGroup?: boolean
  linkLogo?: string
  withInputSearch?: boolean
}
interface MenuItem {
  icone: string
  label: string
  link: string
  hide?: boolean
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
<>
    <header className={`${props.isLight ? 'mp-header-light' : ''}`}>
        {props.showMenu &&
        <ToggleMenu aplicacao={props.application} itens={props.menuItems} />
        }
        {props.showLogo &&
        <Logo aplicacao={props.application} link={props?.linkLogo} isLight={props?.isLight} />
        }
        {props.crumbs &&
        <Breadcrumbs crumbs={props.crumbs} />
        }
        <div className="mp-header-container">
          {props.withInputSearch &&
          <InputSearch {...props.inputSearchProps} reduced />
          }
          {props.showButtonGroup && props.withInputSearch
            ? <MpDivider dividerType={DividerTypeEnum.VERTICAL} color={props.isLight ? null : CoresEnum.TERTIARY_600} spacing={{ mr: 1, ml: 0, mt: 0.5, mb: 0.5 }} /> : null
          }
          {props.showButtonGroup &&
          <ButtonGroup spacing={{ mr: 6 }}>
            {props.children}
          </ButtonGroup>
          }

        </div>
    </header>
    {props.content}
</>
  )
}
