import React from 'react'
import { CoresEnum, DividerTypeEnum } from '../../enums'
import MpSpacingProps, { getSpacing } from '../Spacing'

import './divider.scss'

export interface MpDividerProps {
  dividerType?: DividerTypeEnum
  color?: CoresEnum
  spacing?: MpSpacingProps
}

export const MpDivider: React.FC<MpDividerProps> = (props: MpDividerProps) => {
  const colorDivider = props?.color ? props?.color.value : ''

  return (
    <hr
      className = {`
        mp-divider 
        ${props.dividerType ? props.dividerType : DividerTypeEnum.HORIZONTAL} 
        ${props?.spacing ? getSpacing(props?.spacing) : ''}
      `}
      style={{ borderColor: colorDivider }}
    />
  )
}
