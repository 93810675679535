import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Paste from './icons/paste.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import './css/style.css';

export default class MpPaste extends Plugin {
    init() {
        const editor = this.editor;


        editor.ui.componentFactory.add('paste', locale => {
            const view = new ButtonView(locale);
            view.set({
                label: 'Colar',
                icon: Paste,
                tooltip: true
            });

            // Callback executed once the image is clicked.
            view.on('execute', () => {

                editor.model && editor.model.change(writer => {

                    if (navigator.clipboard) {
                        navigator.clipboard.readText().then(clipText => {
                            if(clipText)
                            {
                                editor.model.insertContent(writer.createText(clipText));

                            }


                        })
                    }

                })


            });

            return view;
        });
    }
}
