
import React from 'react'
import { AlignItensEnum, DividerTypeEnum, FlexEnum, FlexWrapEnum } from '../../../enums'
import { MpDivider } from '../../Divider/'
import ButtonGroup from '../../ButtonGroup'
import { MpGridFlex, MpGridItem, MpGridRow } from '../../Grid'
import InputSearch from '../../InputSearch'

import './mp-file-treeHeader.scss'

export interface Props {
  inputSearchMinLength?: number
  onSearch?: (search: string) => void
  headerButtons?: React.ReactNode
  treeButtons?: React.ReactNode
}

export const MpFileTreeHeader: React.FC<Props> = (props: Props) => {
  return (
    <MpGridFlex spacing={{ pt: 0, pb: 0, ml: 1, mr: 1 }}>
      <MpGridRow alignItens={AlignItensEnum.CENTER} flexWrap={FlexWrapEnum.NOWRAP} spacing={{ mb: 0 }}>
        <MpGridItem flex={FlexEnum.AUTO} spacing={{ pr: 1, m: 0, p: 0 }}>
          {
            props.onSearch && (<InputSearch reduced minLength={props.inputSearchMinLength} onSearch={props.onSearch} />)
          }
        </MpGridItem>

        {props?.headerButtons && <MpDivider spacing={{ mt: 0.5, mb: 0.5, mr: 1 }} dividerType={DividerTypeEnum.VERTICAL}/>}

        <MpGridItem flex={FlexEnum.INITIAL} spacing={{ m: 0, p: 0 }}>
          <div className='box-button-group-header'>
            <ButtonGroup>
              {props.headerButtons}
            </ButtonGroup>
          </div>
        </MpGridItem>
      </MpGridRow>
      {
        props.treeButtons && (
          <MpGridRow alignItens={AlignItensEnum.CENTER} spacing={{ mb: 0 }}>
            <MpGridItem flex={FlexEnum.AUTO} spacing={{ p: 0, m: 0 }}>
              <div className='box-button-group-tree-header'>
                <ButtonGroup cssClass='button-group-justify'>
                  {props.treeButtons}
                </ButtonGroup>
              </div>
            </MpGridItem>
          </MpGridRow>
        )
      }
    </MpGridFlex>
  )
}
