import React from 'react'
import Select, { ActionMeta, MultiValue, SingleValue } from 'react-select'
import './style.scss'
import Icon from '@mdi/react'
export type OptionType = {
  value: string | number
  label: string
}

type Props = {
  options: OptionType[]
  placeholder?: string
  className?: string
  label?: string
  defaultValue?: OptionType
  onChange?: (
    value: MultiValue<OptionType> | SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>,
  ) => void
  icon?: string
  isMulti?: boolean
  error?: boolean
  isDisabled?: boolean
  value?: OptionType
  noOptionsMessage?: () => string
  isClearable?: boolean
}

const CustomSelect: React.FC<Props> = (props: Props) => {
  const {
    defaultValue,
    onChange,
    placeholder = 'Selecione',
    className,
    label,
    options,
    icon,
    isMulti,
    error,
    isDisabled,
    value,
    noOptionsMessage,
    isClearable,
  } = props

  return (
    <div className={className}>
      <div className='select-title'>
        {icon && <Icon path={icon} color='#333' size={1.0} />}
        <label>{label}</label>
      </div>

      <Select
        defaultValue={defaultValue}
        isMulti={isMulti}
        options={options}
        className={error ? 'select-mp-error' : 'select-mp'}
        classNamePrefix={error ? 'select-mp-error' : 'select-mp'}
        placeholder={placeholder}
        onChange={onChange}
        noOptionsMessage={noOptionsMessage}
        isDisabled={isDisabled}
        isClearable={isClearable}
        value={value}
      />
    </div>
  )
}

export default CustomSelect
