import React, { useEffect, useState } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import { EstadoInputTextEnum, TamanhoIconeEnum } from '../../enums'
import { skeletonInputTextComLabel } from '.'
import MaskedInput from 'react-input-mask'

import './input-text.scss'
import Icone from '../icone'

export interface InputTextProps {
  id?: string
  label: string
  hint: string
  handleChange?: (keyword: string) => void
  onBlur?: (keyword: string) => void
  isLoading?: boolean
  value?: any
  placeholder?: string
  type?: EstadoInputTextEnum
  iconPath? : string
  isDisabled?: boolean
  isRequired?: boolean
  mask?: string
  maxLength?: number
}

export const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
  const [value, setValue] = useState(props.value ?? '')

  const onChange = (event): void => {
    setValue(event.target.value)
    if (props.handleChange) props.handleChange(event.target.value)
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonInputTextComLabel}>

      <div className={`mp-form-control 
            ${props.type ? props.type : ''} 
            ${props.isDisabled ? 'mp-form-control-disabled' : ''}
          `}
      >

        <div className="mp-form-label">
          {props.iconPath &&
              (
                <div className="mp-form-label-icone">
                  <Icone iconPath={props.iconPath} iconSize={TamanhoIconeEnum.SMALL} />
                </div>
              )
          }
          <label className={`${props.isRequired ? 'mp-form-label-required' : ''}`} htmlFor={props.id ? props.id : 'inputText'}>
            {props.label}
          </label>
        </div>

        <div className="mp-form-input">
          {props.mask
            ? <MaskedInput
              type="text"
              id={props.id ? props.id : 'inputText'}
              name={props.id ? props.id : 'inputText'}
              data-testid={props.id ? props.id : 'input-text'}
              placeholder={props.placeholder}
              autoComplete="off"
              onBlur = {() => props?.onBlur(value)}
              onChange={onChange}
              maskChar={null}
              disabled={props.isDisabled}
              value={value}
              mask={props.mask}
              maxLength={props.maxLength} />
            : <input
              placeholder={props.placeholder}
              type="text"
              id={props.id ? props.id : 'inputText'}
              data-testid={props.id ? props.id : 'input-text'}
              name={props.id ? props.id : 'inputText'}
              value={value}
              onBlur= {() => props?.onBlur(value)}
              onChange={onChange}
              disabled={props.isDisabled}
              required={props.isRequired}
              maxLength={props.maxLength}
            />
          }
        </div>

        <div className="mp-form-hint">
          <p>{props.hint}</p>
        </div>

      </div>

    </ReactPlaceholder>

  )
}
