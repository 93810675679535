import React from 'react'
import Icone from '../icone'
import { CustomTooltip } from '../CustomTooltip'
import { CoresEnum, TooltipPlacementEnum } from '../../enums'
import '../CustomTooltip/custom-tooltip.scss'
import './avatar-icone.scss'

export interface AvatarIconeProps {
  icone: any
  ativo?: boolean
  tooltipText?: string
  color?: CoresEnum
  background?: CoresEnum
}

export const AvatarIcone: React.FC<AvatarIconeProps> = (props: AvatarIconeProps) => {
  return (
    <div className={`avatar-icone ${props.ativo ? 'active' : ''}`}
      style={{
        color: props?.color?.value,
        backgroundColor: props?.background?.value
      }}
    >
      <CustomTooltip text={props.tooltipText} placement={TooltipPlacementEnum.TOP}>
        <div className="avatar-icone-item" data-testid="com-tooltip">
          <Icone iconPath={props.icone} />
        </div>
      </CustomTooltip>
    </div>
  )
}
