import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Cut from './icons/scissors.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import './css/style.css';

export default class MpCut extends Plugin {
    init() {
        const editor = this.editor;


        editor.ui.componentFactory.add('cut', locale => {
            const view = new ButtonView(locale);
            view.set({
                label: 'Recortar',
                icon: Cut,
                tooltip: true
            });

            // Callback executed once the image is clicked.
            view.on('execute', () => {

                let selection = editor.model.document.selection;

                const range = selection.getFirstRange();
                let copiedText = ""

                for (const item of range.getItems()) {
                    copiedText+= item.data
                }

                if(copiedText.length)
                {
                    navigator.clipboard.writeText(copiedText)
                    editor.execute('delete');
                }
                



            });

            return view;
        });
    }
}
