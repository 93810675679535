import React, { useEffect, useState } from 'react'
import { mdiApplication, mdiArrowExpandAll, mdiCheck, mdiClose, mdiDotsVertical, mdiMinus } from '@mdi/js'
import { LimitedTextContent } from '../../utils/LimitedTextContent'
import { ButtonSizeEnum, ButtonTypeEnum, ContextualMenuPositionEnum, TamanhoIconeEnum, TooltipPlacementEnum } from '../../enums'
import { Button, ButtonGroup, ContextualMenu, MpListItemContent, MpListItemIconLeftArea, MpListItemLink, MpListItemSimple, MpTypography } from '../../index'
import Icone from '../icone'

import './fixedTabs.scss'

export interface MpFixedTabsProps {
  tabs: FixedTab[]
  activeTabId?: string
  hide?: boolean
  onMenuClick?: () => void
  onCloseAll?: () => void
}

export interface FixedTab {
  id: string
  label: string
  hide?: boolean
  iconPath?: string
  metadata?: any
  onClose?: (metadata?: any) => void
  handleMaximized?: (metadata: any) => void
  handleFloat?: (metadata: any) => void
  handleMinimized?: () => void
}

export const MpFixedTabs: React.FC<MpFixedTabsProps> = (props: MpFixedTabsProps) => {
  const { tabs, activeTabId } = props
  const [contextualMenuIsOpened, setContextualMenuIsOpened] = useState(false)
  const contextualMenuOptions: any[] = tabs.map(tab => (
    {
      id: tab?.id,
      label: tab?.label,
      metadata: tab?.metadata,
      handleClick: (metadata) => {
        tab?.handleFloat(metadata)
      }
    }
  ))

  const refs = tabs.reduce((acc, value) => {
    acc[`tab-ref-${value.id}`] = React.createRef()
    return acc
  }, {})

  const scrollTo = (tabId): void => {
    refs[tabId]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
  const onClickTab = (tab: FixedTab): void => {
    if (tab.handleMaximized) {
      tab.handleMaximized(tab.metadata)
    }
  }

  const openFloat = (tab: FixedTab): void => {
    if (tab.handleFloat) {
      tab.handleFloat(tab.metadata)
    }
  }

  const minimize = (tab: FixedTab): void => {
    if (tab.handleMinimized) {
      tab.handleMinimized()
    }
  }

  const close = (tabIndex: number): void => {
    if (tabs[tabIndex].onClose) {
      tabs[tabIndex].onClose(tabs[tabIndex]?.metadata)
    }
    const updatedTabs = [...tabs]
    updatedTabs.splice(tabIndex, 1)
  }

  useEffect(
    () => { setTimeout(() => scrollTo(`tab-ref-${activeTabId}`), 400) },
    [activeTabId]
  )

  return (
    <>

      {(!props.hide && !!props?.tabs?.length) &&
        <div className="mp-fixed-tabs" >

          <div className="mp-fixed-tabs-content">
            <div className='mp-tab-fixed-header-content'>
              {tabs?.map((tab, tabIndex) =>
                (
                  <React.Fragment key={tab.id}>
                    {!tab.hide &&
                    <div ref={refs[`tab-ref-${tab.id}`]} key={tab.id}
                      className={`mp-fixed-tabs-item ${activeTabId === tab.id ? 'active' : ''}`} >
                      <span onClick={tab.handleFloat}>
                        {tab.iconPath &&
                        <div className="mp-fixed-tabs-icon">
                          <Icone iconSize={TamanhoIconeEnum.SMALL} iconPath={tab.iconPath} />
                        </div>
                        }
                        <div className="mp-fixed-tabs-text">

                          {/* <MpTypography id='' maxLength={getMaxLength(activeTabId, tab)} italic>
                            {tab.label}
                          </MpTypography> */}
                          <LimitedTextContent maxLength={getMaxLength(activeTabId, tab)} >
                            {tab.label}
                          </LimitedTextContent>
                        </div>
                      </span>
                      <ButtonGroup cssClass='flex-align-tabs'>
                        <Button id={'mp-card-button-close'}
                          buttonSize={ButtonSizeEnum.SMALL}
                          handleClick={() => close(tabIndex)}
                          iconPath={mdiClose}
                          tooltipText={'Fechar'}
                          buttonType={ButtonTypeEnum.ICON} />

                        <Button id={'mp-card-button-maximize'}
                          buttonSize={ButtonSizeEnum.SMALL}
                          handleClick={() => { onClickTab(tab) }}
                          iconPath={mdiArrowExpandAll}
                          tooltipText={'Maximizar'}
                          buttonType={ButtonTypeEnum.ICON} />

                        <Button id={'mp-card-button-minimize'}
                          buttonSize={ButtonSizeEnum.SMALL}
                          handleClick= {() => activeTabId === tab.id ? minimize(tab) : openFloat(tab)}
                          iconPath= {` ${activeTabId === tab.id ? mdiMinus : mdiApplication}`}
                          tooltipText= {` ${activeTabId === tab.id ? 'Minimizar' : 'Abrir Flutuante'}`}

                          buttonType={ButtonTypeEnum.ICON} />
                      </ButtonGroup>

                    </div>
                    }
                  </React.Fragment>

                )

              )}
            </div>
            <div className="mp-fixed-tabs-button">
              <ContextualMenu
                id='mp-fixed-tabs-contextual-menu-close-all'
                handleIsOpened={setContextualMenuIsOpened}
                position={ContextualMenuPositionEnum.INNER_TOP_LEFT}
                onCloseAll={props.onCloseAll}
                onCloseAllLabel='Fechar todas as abas'
                itensMenu={
                  contextualMenuOptions.map((menuOption, index) => (
                    <MpListItemLink isActive={props.activeTabId === menuOption.id} key={`key${index}`} handleClick={menuOption.handleClick}>
                      <MpListItemSimple>
                        {props.activeTabId === menuOption.id && <MpListItemIconLeftArea iconPath={menuOption.iconPath || mdiCheck} />}
                        {menuOption.iconPath && <MpListItemIconLeftArea iconPath={menuOption.iconPath} />}
                        <MpListItemContent>
                          <LimitedTextContent toolTipPlacement={TooltipPlacementEnum.LEFT} maxLength={(props.activeTabId === menuOption.id) ? 34 : 40}>
                            {menuOption.label}
                          </LimitedTextContent>
                        </MpListItemContent>
                      </MpListItemSimple>
                    </MpListItemLink>

                  ))
                }
              >
                <Button
                  buttonType={ButtonTypeEnum.ICON}
                  iconPath={contextualMenuIsOpened ? mdiClose : mdiDotsVertical} />
              </ContextualMenu>
            </div>
          </div>

        </div>

      }

    </>

  )
}
const getMaxLength = (activeTabId: string, tab: FixedTab): number => {
  let maxLength = 15
  if (activeTabId === tab.id) maxLength = 30
  if (tab.iconPath) maxLength -= 3
  return maxLength
}
