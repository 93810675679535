import React, { ReactNode } from 'react'
import { mdiArrowDownDropCircle } from '@mdi/js'
import { TooltipPlacementEnum, ButtonTypeEnum, NavigationBarTypeEnum, FlexEnum, AlignItensEnum, DividerTypeEnum, JustifyContentEnum } from '../../enums'
import InputSearch, { InputSearchProps } from '../InputSearch'
import { ButtonGroup } from '../ButtonGroup'
import Button from '../Buttons'
import CustomTooltip from '../CustomTooltip'
import Breadcrumbs, { Crumb } from '../breadcrumbs'
import { MpGridItem, MpGridFlex, MpGridRow } from '../Grid/Grid'
import { ButtonProps } from '../Buttons/Button'
import { MpDivider } from '../../components/Divider'

import './navigation-bar.scss'

export interface NavigationBarProps {
  type?: NavigationBarTypeEnum
  title?: string
  inboxSize?: number
  tooltipInbox?: string
  crumbs?: Crumb[]
  buttons?: ButtonProps[]
  dropdownButtonText?: string
  onDropdownClick?: () => void
  inputSearchProps? : InputSearchProps
  children?: ReactNode
}

export const NavigationBar: React.FC<NavigationBarProps> = (props: NavigationBarProps) => {
  if (props.type === NavigationBarTypeEnum.Default && !(props.title)) {
    throw new Error('[NavigationBar Default] Deve ser fornecido o parametro title ')
  }

  return (
    <>
      <div className={`mp-navbar ${props.type === NavigationBarTypeEnum.Default ? 'mp-navbar-default' : 'mp-navbar-extended'}`}>
        <MpGridFlex spacing={{ pt: 0, pb: 0 }}>
          <MpGridRow alignItens={AlignItensEnum.CENTER} spacing={{ mb: 0 }}>
            <MpGridItem flex={FlexEnum.INITIAL}spacing={{ p: 0 }}>
              <div className="mp-title">
                <h4>{props.title}</h4>
                { (props.inboxSize?.toString().length) &&
                  <CustomTooltip text={props.tooltipInbox} placement={TooltipPlacementEnum.TOP}>
                    <small>{props.inboxSize}</small>
                  </CustomTooltip>
                }
              </div>
            </MpGridItem>

            { props.onDropdownClick &&
              <MpGridItem flex={FlexEnum.INITIAL} spacing={{ p: 0 }}>
                <Button id='mpDropDownOrgao'
                  buttonType={ButtonTypeEnum.DEFAULT}
                  buttonText={props.dropdownButtonText}
                  iconPath={mdiArrowDownDropCircle}
                  handleClick={props.onDropdownClick}
                />
              </MpGridItem>
            }

            {props.crumbs &&
            <MpGridItem spacing={{ ml: 1, p: 0 }} >
              <Breadcrumbs crumbs={props.crumbs} />
            </MpGridItem>
            }

            {(props.inputSearchProps || props.buttons) &&
            <MpGridItem>
              <MpGridRow alignItens={AlignItensEnum.CENTER} justifyContent={JustifyContentEnum.FLEX_END} spacing={{ mb: 0 }}>
                { props.inputSearchProps &&
                  <MpGridItem spacing={{ p: 0 }} flex={FlexEnum.INITIAL} >
                    <InputSearch {...props.inputSearchProps} reduced={props.type !== NavigationBarTypeEnum.Extended}/>
                  </MpGridItem>
                }

                {props.type !== NavigationBarTypeEnum.Extended && props.inputSearchProps && props.buttons?.length

                  ? <MpDivider spacing={{ mt: 0.5, mr: 0.5, mb: 0.5 }} dividerType={DividerTypeEnum.VERTICAL}/> : <></>
                }

                { props.buttons &&
                  <MpGridItem flex={FlexEnum.INITIAL} spacing={{ ml: 0.5, p: 0 }}>
                    <ButtonGroup cssClass='mp-header-button-group'>
                      { props.buttons.map((buttonProps, index) => (
                        <Button key={`button${index}`} {...buttonProps} buttonType={props.type !== NavigationBarTypeEnum.Extended ? ButtonTypeEnum.ICON : ButtonTypeEnum.SQUARE} ></Button>
                      ))}
                    </ButtonGroup>
                  </MpGridItem>
                }
              </MpGridRow>
            </MpGridItem>
            }
          </MpGridRow>
        </MpGridFlex>

      </div>
      {props.children}

    </>
  )
}
