import classNames from 'classnames'
import React, { ReactNode } from 'react'
import Footer, { FooterProps } from '../footer'
import Header, { HeaderProps } from '../header'

import './wrapper.scss'

export interface MpWrapperProps {
  id?: string
  children: ReactNode
  headerProps?: HeaderProps
  footerProps?: FooterProps
  contentHeight?: string
  hasScroll? : boolean
}

export const MpWrapper: React.FC<MpWrapperProps> = (props: MpWrapperProps) => {
  return (

    <div className={classNames('mp-wrapper', {
      'mp-wrapper-full-height': props.hasScroll
    })}>
      <div className='mp-wrapper-header'>
        <Header {...props.headerProps}/>

      </div>
      <div id={props.id ? props.id : `mp-wrapper-content${Math.random() * 1000}`}
        className={classNames('mp-wrapper-content', {
          'mp-wrapper-content-height': !props.contentHeight,
          'mp-has-scroll': props.hasScroll
        })}
        style={
          {
            height: `${props.contentHeight}`
          }

        }
      >

        {props.children}

        <div className='mp-wrapper-footer'>
          {props.footerProps && <Footer {...props.footerProps}/>}

        </div>

      </div>

    </div>

  )
}
