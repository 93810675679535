import { mdiEmailOpenOutline, mdiEmailOutline } from "@mdi/js";

export class  EstiloDataEnum {
    static readonly ESTILO_A  = new EstiloDataEnum('primary', mdiEmailOutline);
    static readonly ESTILO_B = new EstiloDataEnum('primary', mdiEmailOpenOutline);
  
    private constructor(public readonly cssClass: string, public readonly icone: any) {
        this.cssClass = cssClass;
        this.icone = icone;
    }
  
    
  }