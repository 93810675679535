import React from 'react'
import { TooltipPlacementEnum, CoresEnum } from '../../enums'
import CustomTooltip from '../CustomTooltip'
import '../CustomTooltip/custom-tooltip.scss'
import './avatar-sigla.scss'

export interface AvatarSiglaProps {
  conteudo: string
  ativo?: boolean
  tooltipText?: string
  background?: CoresEnum
  color?: CoresEnum
}

export const AvatarSigla: React.FC<AvatarSiglaProps> = (props: AvatarSiglaProps) => {
  return (
    <div className={`avatar-siglas ${props.ativo ? 'active' : ''}`}
      style={{
        color: props?.color?.value,
        backgroundColor: props?.background?.value
      }}
    >
      <CustomTooltip text={props.tooltipText} placement={TooltipPlacementEnum.TOP}>
        <div className="avatar-siglas-item" data-testid="com-tooltip">
          {props.conteudo?.substring(0, 2)}
        </div>
      </CustomTooltip>
    </div>
  )
}
