import React, { ReactNode } from 'react'
import MpSpacingProps, { getSpacing } from '../Spacing'
import './button-group.scss'

export interface ButtonGroupProps {
  children: ReactNode
  cssClass?: string
  spacing?: MpSpacingProps
}

export const ButtonGroup: React.FC<ButtonGroupProps> = (props: ButtonGroupProps) => {
  return (
    <div className={`button-group ${props.cssClass ? props.cssClass : ''} ${props.spacing ? getSpacing(props.spacing) : ''} `}>
      {props.children}
    </div>
  )
}
