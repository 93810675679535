import { mdiInformationOutline } from '@mdi/js'
import React from 'react'
import { CoresEnum, TamanhoIconeEnum, TooltipPlacementEnum } from '../../../../enums'
import CustomTooltip from '../../../CustomTooltip'
import Icone from '../../../icone'
import { MpFileNodeNotificationProps } from '../MpFileTreeNode'

type Props = {
  notifications?: MpFileNodeNotificationProps[]
  isReduced?: boolean
}

type iconProps =
{
  id: string
  icon? : string
  tooltipText? : string
  color?: CoresEnum
}

const IconArea = (props: iconProps): JSX.Element => {
  return (
    <>
      <CustomTooltip text={props.tooltipText} placement={TooltipPlacementEnum.RIGHT} key={props.id}>
        <Icone key={props.id} iconPath={props.icon} iconSize={TamanhoIconeEnum.XSMALL} iconColor={props.color || CoresEnum.INFO} />
      </CustomTooltip>
    </>
  )
}

const buildTooltipNotificationsReduced = (notifications: MpFileNodeNotificationProps[]): string => notifications.map(notification => notification.tooltipText).join(' | ')

export const MpFileTreeNodeNotifications: React.FC<Props> = (props: Props) => {
  return (
    <>
      {(props?.notifications?.length > 0) &&
        <span data-testid='mp-file-tree-node-notifications' className='file-treenode-notifications'>
          {props.isReduced
            ? <IconArea key={'mp-file-tree-node-notification-icon-id'} id={'mp-file-tree-node-notification-icon-id'}
              tooltipText={buildTooltipNotificationsReduced(props.notifications)}icon={mdiInformationOutline} color={CoresEnum.INFO} />
            : props.notifications?.filter(not => !not.hide).map((notification, i) =>
              <IconArea key={i} id={`mp-file-tree-node-notification-icon-id${i}`} {...notification} />
        )}

        </span>

      }
    </>
  )
}
