export type MimeType = 'text/html' |
'application/pdf' |
'application/rtf' |
'application/msword'|
'image/png' |
'image/jpeg' |
'audio/video' |
'audio/ogg' |
'audio/mpeg' |
'video/mpeg' |
'video/mp4'|
'video/quicktime'|
'application/octet-stream' |
'application/zip' |
'application/x-rar-compressed' |
'application/vnd.ms-excel' |
'application/vnd.ms-powerpoint'

export const mimetypeExtensionMap: { [key in MimeType]: string[] } = {
  'text/html': ['.html', '.htm'],
  'application/pdf': ['.pdf'],
  'application/rtf': ['.rtf'],
  'application/msword': ['.doc', '.docx'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'audio/video': ['.3gp', '.3g2'],
  'audio/ogg': ['.oga'],
  'audio/mpeg': ['.mp3'],
  'video/mpeg': ['.mpeg'],
  'video/mp4': ['.mp4'],
  'video/quicktime': ['.mov', '.qt'],
  'application/octet-stream': ['.zip', '.rar'],
  'application/x-rar-compressed': ['.rar'],
  'application/zip': [';zip'],
  'application/vnd.ms-excel': ['xls', 'xlsx'],
  'application/vnd.ms-powerpoint': ['.ppt']
}
