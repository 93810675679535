import React, { ReactNode } from 'react'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import Button from '../Buttons'
import MpSpacingProps, { getSpacing } from '../Spacing'
import BorderProps, { getBorder } from '../../utils/Border'
import { ButtonGroup } from '../ButtonGroup'
import { ButtonProps } from '../Buttons/Button'
import { getSkeletonType, skeletonCardBasic } from './card-basico-skeleton'
import MpDivider from '../../components/Divider'
import MpTypography from '../Typography'
import { ButtonSizeEnum } from '@/enums/ButtonSizeEnum'

import './card-basico.scss'

export interface CardBasicoProps {
  id: string
  'data-testid'?: string
  children: ReactNode
  isLoading?: boolean
  loadingMode?: 'cards' | 'basic'
  title?: string
  withHeaderHR? : boolean
  withFooterHR? : boolean
  cssClass?: string
  style?: React.CSSProperties
  isSelected?: boolean
  isDisabled?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  headerButtonText?: string
  buttons?: ButtonProps[]
  headerButtons?: ButtonProps[]
  headerSpacing?: MpSpacingProps
  contentSpacing?: MpSpacingProps
  footerSpacing?: MpSpacingProps
  fixedCardContentHeight?: string
  handleClick?: (model: CardBasicoProps) => void
  mouseEnter? : () => void
  mouseLeave? : () => void
  border?: BorderProps
}

export interface SimpleButtonProps{
  text: string
  onClick?: () => void
  buttonSize?: ButtonSizeEnum

}

export const CardBasico: React.FC<CardBasicoProps> = (props: CardBasicoProps) => {
  const buildSpacing = (where: 'HEADER' | 'CONTENT' | 'FOOTER'): MpSpacingProps => {
    switch (where) {
      case 'HEADER' : return props.headerSpacing ? props.headerSpacing : { }
      case 'CONTENT' : return props.contentSpacing ? props.contentSpacing : { }
      case 'FOOTER' : return props.footerSpacing ? props.footerSpacing : { }
    }
  }

  const styleBorder = getBorder(props?.border)
  const styleConfig = { ...props.style, ...styleBorder }

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={props.loadingMode ? getSkeletonType(props.loadingMode) : skeletonCardBasic}>

      <div role="button" id={props.id} data-testid={props['data-testid'] || 'cardbasico'} aria-label="Card Clicável"
        onClick={() => { if (props.handleClick) props.handleClick(props) }}

        onMouseEnter={props.mouseEnter}
        onMouseLeave={props.mouseLeave}
        style={ styleConfig }
        className={`mp-card 
          ${props.cssClass ? props.cssClass : ''} 
          ${props.handleClick && !props.isDisabled ? 'mp-card-link' : ''} 
          ${props.isSelected ? 'card-selecionado' : ''} 
          ${props.isDisabled ? 'card-desabilitado' : ''}
        `}
      >
        { (!props.hideHeader) &&
          <div className={`mp-card-header mp-d-row ${getSpacing(buildSpacing('HEADER'))}`}>
            <MpTypography bold id={props.id} element='h6' isTruncate>
              {props.title}
            </MpTypography>
            { props.headerButtons?.length &&
              <ButtonGroup data-testid='mp-card-buttons-header'>
                {props.headerButtons?.map((button, key) =>
                  <Button data-testid={`mp-card-button-${key}`} key={key} {...button} />
                )}
              </ButtonGroup>
            }
          </div>
        }
        {props.withHeaderHR && <MpDivider></MpDivider>}

        <div
          className={`mp-card-wrapper  ${getSpacing(buildSpacing('CONTENT'))}`}
          style={
            props.fixedCardContentHeight && {
              height: `${props.fixedCardContentHeight}`,
              overflow: 'auto'
            }
          }
        >
          {props.children}

        </div>

        {props.withFooterHR && <MpDivider></MpDivider>}

        { (!props.hideFooter) &&
          <div className= {`mp-card-footer  ${getSpacing(buildSpacing('FOOTER'))}`}>
            <ButtonGroup data-testid="mp-card-buttons-footer">
              {props.buttons?.map((button, key) => (
                <Button key={key} {...button} />
              ))}
            </ButtonGroup>
          </div>
        }

      </div>

    </ReactPlaceholder>
  )
}
