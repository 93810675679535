import React, { useContext, useState } from 'react'
import {
  CustomModal,
  TamanhoModalEnum,
  ButtonTypeEnum,
  NotificationTypeEnum,
  Toaster,
  emit,
} from 'mp-react-components'
import Icon from '@mdi/react'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import './style.scss'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Service from '../../service'
import { GlobalContext } from '../../context/global'

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false)

  const { show, setShow, user, setUser, password, setPassword } = useContext(GlobalContext)

  const handleLogin = () => {
    Service.postService('/login', {
      user,
      password,
    })
      .then((response) => {
        localStorage.setItem('login-MPRJ', JSON.stringify(response.data))
        setShow(false)
      })
      .catch((error) => emit(error.response.data.message, NotificationTypeEnum.DANGER))
  }

  const args = {
    footerButtons: [
      {
        id: 'A',
        buttonText: 'Entrar',
        buttonType: ButtonTypeEnum.OUTLINE,
        handleClick: () => handleLogin(),
      },
    ],
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <>
      {show && <div className='blur' />}
      <Toaster />
      <CustomModal show={show} size={TamanhoModalEnum.XSMALL} title='Login' {...args}>
        <div className='contain'>
          <TextField
            size='small'
            label='Usuário'
            variant='outlined'
            InputProps={{ style: { width: '300px', marginBottom: '6%' } }}
            onChange={(e) => setUser(e.target.value)}
          />
          <TextField
            size='small'
            label='Senha'
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              style: { width: '300px', marginBottom: '6%' },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? (
                      <Icon path={mdiEyeOffOutline} size={1.0} />
                    ) : (
                      <Icon path={mdiEyeOutline} size={1.0} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </CustomModal>
    </>
  )
}
