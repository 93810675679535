import React from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'

import Editor from 'mp-ck-editor-5'

import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'
import './text-editor.scss'

export interface MpTextEditorProps {
  onChange: (textEvent: any) => void
  originalContent? : string
  isDisabled? : boolean
  isLoading?: boolean

}
const getLoadingText = (): string => {
  return 'Carregando...'
}

export const MpTextEditor: React.FC<MpTextEditorProps> = (props: MpTextEditorProps) => {
  let editor = Editor
  return (

    <div className="mp-text-editor" id="mp-text-editor">
      <CKEditor
        disabled={props.isDisabled}

        config={ {
          language: 'pt-br',

          /**
           * Remove o ícone de upload a partir do explorador de arquivos, que necessita de um outro plugin que não instalamos nessa versão
           * A colagem de imagens via URL ou ctrl-c funciona normalmente
           */
          removePlugins: ['ImageUpload', 'EasyImage', 'ImageInsert']

        } }

        onReady={ decoupledEditor => {
          // Insert the toolbar before the editable area.
          decoupledEditor.ui.getEditableElement().parentElement.insertBefore(
            decoupledEditor.ui.view.toolbar.element,

            decoupledEditor.ui.getEditableElement()

          )

          decoupledEditor.keystrokes.set('tab', (data, stop) => {
            decoupledEditor.execute('input', { text: '    ' })
            stop()
          })

          decoupledEditor.keystrokes.set('backspace', (data, stop) => {
            decoupledEditor.execute('outdent')
            stop()
          })

          editor = decoupledEditor
        } }
        onChange={ (_event, editor) => props.onChange ? props.onChange(editor.getData()) : null}
        editor={ editor }
        data={props.isLoading ? getLoadingText() : props.originalContent}
      />
    </div>

  )
}
