import React, { ReactNode, useState } from 'react'
import { mdiClose } from '@mdi/js'
import { Button } from '../Buttons'
import Icone from '../icone'
import { LimitedTextContent } from '../../utils/LimitedTextContent'
import { ButtonThemeEnum } from '../../enums/ButtonThemeEnum'
import { ButtonTypeEnum } from '../../enums/ButtonTypeEnum'
import { PositionEnum } from '../../enums/PositionEnum'
import { TamanhoIconeEnum } from '../../enums'
import './fab.scss'

export interface MpFabProps {
  iconPath?: string
  position?: PositionEnum
  hide?: boolean
  options?: optionMenu[]
  activeOptionId?: string
  tooltipText?: string
  isDisabled?: boolean
  hideCloseButton?: boolean
  children?: ReactNode
  handleClick?: () => void
}

interface optionMenu {
  id?: string
  label: string
  iconPath?: string
  handleClick?: (metadata: any) => void
}

export const MpFab: React.FC<MpFabProps> = (props: MpFabProps) => {
  const [showMenu, setShowMenu] = useState(false)

  const onClick = (): any => {
    setShowMenu(!showMenu)
    if (props.handleClick) props.handleClick()
  }

  const getIcon = (): string => {
    let iconPath = props.iconPath
    if (showMenu && !props.hideCloseButton) {
      iconPath = mdiClose
    }
    return iconPath
  }

  return (

    (!props.hide) &&

    <div className={`mp-fab-anchor ${props.position ? props.position : ''}`}>

      {showMenu && props.children}

      {(props.options?.length && showMenu) && <div className='mp-fab-menu'>

        {props.options?.map((option, index) => (
          <div className={`mp-fab-menu-item ${props.activeOptionId === option.id ? 'active' : ''} `} key={`fab-option${index}`} onClick={() => option.handleClick(option)}>
            <div className='mp-fab-menu-icon'>
              {option.iconPath &&
              <Icone
                iconPath={option.iconPath}
                iconSize={TamanhoIconeEnum.SMALL}
              />}
            </div>
            <div className='mp-fab-menu-text' >
              <LimitedTextContent maxLength={option.iconPath ? 35 : 38} >{option.label}</LimitedTextContent>
            </div>
          </div>
        ))}
      </div>}

      <Button tooltipText={props.tooltipText} isDisabled={props.isDisabled} iconPath={getIcon()}
        buttonType={ButtonTypeEnum.ICON} buttonTheme={ButtonThemeEnum.CONTAINED} handleClick={onClick}
      />
    </div>

  )
}
