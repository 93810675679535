import React from 'react'
import Chip from '../chip'
import { AplicacaoEnum, ChipSizeEnum, CoresEnum } from '../../enums/'
import LogoIntegra from '../../assets/img/logo-mprj-integra.svg'
import LogoIntegraLight from '../../assets/img/logo-mprj-integra-light.svg'
import './logo.scss'

export interface LogoProps {
  aplicacao?: AplicacaoEnum
  link?: string
  isLight?: boolean
}

export const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  return (
    <div className="container-logo">
      <div className="logo-sistema">
        { props.link && <a href={props?.link}><img src={props?.isLight ? LogoIntegraLight : LogoIntegra} /></a> }
        { !props.link && <img src={props?.isLight ? LogoIntegraLight : LogoIntegra} /> }
      </div>
      { props.aplicacao && <Chip text={props.aplicacao} size={ChipSizeEnum.SMALL} spacing={{ mr: 1 }} color={props?.isLight ? null : CoresEnum.WHITE } /> }
    </div>
  )
}
