export enum MpFileUploadErros {
  INVALID_TYPE = 'file-invalid-type',
  TO_MANY_FILES = 'too-many-files',
  FILE_EXISTS = 'file-exists',
  MAXIMUM_SIZE_OF_THE_SUMMED_FILES_IN_MB = 'maximum-size-of-the-summed-files-in-mb',
  FILE_TOO_LARGE = 'file-too-large'
}

export const MpFileUploadErrosMap: { [key in MpFileUploadErros]: string } = {
  [MpFileUploadErros.INVALID_TYPE]: 'Tipo inválido de arquivo.',
  [MpFileUploadErros.TO_MANY_FILES]: 'O upload contém mais arquivos do que permitido.',
  [MpFileUploadErros.FILE_EXISTS]: 'Já existe um arquivo com o mesmo nome.',
  [MpFileUploadErros.FILE_TOO_LARGE]: 'Arquivo maior que o tamanho máximo permitido.',
  [MpFileUploadErros.MAXIMUM_SIZE_OF_THE_SUMMED_FILES_IN_MB]: 'Arquivos somados excedem o valor maximo permitido.'
}
