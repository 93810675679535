import React, { createContext, useContext, useState } from 'react'

interface IContextProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  user: string
  setUser: React.Dispatch<React.SetStateAction<string>>
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
}

interface IProviderProps {
  children: React.ReactNode
}

export const GlobalContext = createContext({} as IContextProps)

export const GlobalContextProvider = ({ children }: IProviderProps) => {
  const [show, setShow] = useState(localStorage.getItem('login-MPRJ') ? false : true)
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const contextValues: IContextProps = {
    show,
    setShow,
    user,
    setUser,
    password,
    setPassword,
  }

  return <GlobalContext.Provider value={contextValues}>{children}</GlobalContext.Provider>
}

export const useGlobalContext = (): IContextProps => useContext(GlobalContext)
