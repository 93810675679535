import React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { MpGrid, MpGridItem, Radio } from 'mp-react-components'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { FormValues } from '../../types'
import './style.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 'bold',
      color: '#702826',
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(16),
      color: '#702826',
      marginBottom: '20px',
    },
  }),
)

export const TipoSolicitacao: React.FC = () => {
  const formik = useFormikContext<FormValues>()

  const classes = useStyles()

  return (
    <>
      {formik.values['tipoFonteProcesso'] && (
        <div className='content-analise'>
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <Typography className={classes.heading}>Tipo de Solicitação</Typography>
              {formik.values['tipoFonteProcesso'] === 'CNJ' ? (
                <Typography className={classes.subtitle}>Ação Judicial</Typography>
              ) : (
                <Typography className={classes.subtitle}>Procedimento Extrajudicial</Typography>
              )}
            </MpGridItem>
            {formik.values['tipoFonteProcesso'] === 'CNJ' ? (
              <MpGridItem xl={12} lg={12} md={12}>
                <Field name='tipoSolicitacao.tipoSolicitacao'>
                  {({ field, form }: any) => (
                    <>
                      <MpGridItem xl={12} lg={12} md={12}>
                        <div className='box-radio'>
                          <Radio
                            group='tipoSolicitacao'
                            id='2'
                            label='Apoio na elaboração de quesitos'
                            onCheck={(e: any) => {
                              form.setFieldValue(field.name, e)
                            }}
                            value='AJAEQ'
                            checked={field.value === 'AJAEQ'}
                          />
                        </div>
                      </MpGridItem>
                      <MpGridItem xl={12} lg={12} md={12}>
                        <div className='box-radio'>
                          <Radio
                            group='tipoSolicitacao'
                            id='3'
                            label='Nomeação de técnico como assistente pericial'
                            onCheck={(e: any) => {
                              form.setFieldValue(field.name, e)
                            }}
                            value='AJNTAP'
                            checked={field.value === 'AJNTAP'}
                          />
                        </div>
                      </MpGridItem>
                      <MpGridItem xl={12} lg={12} md={12}>
                        <div className='box-radio'>
                          <Radio
                            group='tipoSolicitacao'
                            id='4'
                            label='Outros serviços técnicos'
                            onCheck={(e: any) => {
                              form.setFieldValue(field.name, e)
                            }}
                            value='AJOST'
                            checked={field.value === 'AJOST'}
                          />
                        </div>
                      </MpGridItem>
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name='tipoSolicitacao.tipoSolicitacao'
                  component='div'
                  className='error-message-tipo-solicitacao'
                />
              </MpGridItem>
            ) : (
              <Field name='tipoSolicitacao.tipoSolicitacao'>
                {({ field, form }: any) => (
                  <MpGridItem xl={12} lg={12} md={12}>
                    <div className='box-radio'>
                      <Radio
                        group='tipoSolicitacao'
                        id='1'
                        label='Análise Técnica'
                        onCheck={(e: any) => {
                          form.setFieldValue(field.name, e)
                        }}
                        value='PEAT'
                        checked={field.value === 'PEAT'}
                      />
                    </div>
                  </MpGridItem>
                )}
              </Field>
            )}
          </MpGrid>
        </div>
      )}
    </>
  )
}
