import React, { ReactNode } from 'react'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import Icone from '../icone'
import CustomTooltip from '../CustomTooltip'
import {
  ButtonTypeEnum,
  TamanhoIconeEnum,
  IconSideEnum,
  ButtonThemeEnum,
  ButtonSizeEnum,
  TooltipPlacementEnum
} from '../../enums'
import { getSkeletonType } from './buttons-skeleton'

import 'react-placeholder/lib/reactPlaceholder.css'
import './button.scss'
import '../icone/icone.scss'
import BorderProps, { getBorder } from '../../utils/Border'

export interface ButtonProps {
  id?: string
  isLoading?: boolean
  iconPath?: string
  iconSide?: IconSideEnum
  buttonType?: ButtonTypeEnum
  buttonSize?: ButtonSizeEnum
  buttonTheme?: ButtonThemeEnum | ButtonThemeEnum[]
  buttonText?: string
  tooltipText?: string
  tooltipPosition?: TooltipPlacementEnum
  isDisabled?: boolean
  isSelected?: boolean
  children?: ReactNode
  hide?: boolean
  handleClick?: (model? : any) => void
  border?: BorderProps
}

const extractButtonThemes = (buttonThemes: ButtonThemeEnum | ButtonThemeEnum[]): any => {
  if (Array.isArray(buttonThemes)) {
    return buttonThemes.toString().replace(/\,/g, '')
  } else {
    return buttonThemes
  }
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const cssClassButtonType = props.buttonType ? props.buttonType : ''
  const cssClassButtonSize = props.buttonSize ? props.buttonSize : ''

  const iconSize = (iconType): TamanhoIconeEnum => {
    switch (iconType) {
      case ButtonSizeEnum.SMALL:
        return TamanhoIconeEnum.XXSMALL
      case ButtonSizeEnum.LARGE:
        return TamanhoIconeEnum.MEDIUM
      default:
        return TamanhoIconeEnum.SMALL
    }
  }

  const styleBorder = getBorder(props?.border)

  return (
    !props.hide && (
      <ReactPlaceholder
        showLoadingAnimation
        ready={!props.isLoading}
        customPlaceholder={getSkeletonType(props.buttonType)}
      >
        <button
          id={props.id}
          data-testid={props.id}
          onClick={props.handleClick}
          disabled={props.isDisabled}
          className={`mp-btn 
            ${cssClassButtonType}  
            ${cssClassButtonSize} 
            ${props.buttonTheme ? extractButtonThemes(props.buttonTheme) : ''} 
            ${props.isSelected ? 'ativo' : ''} 
            ${props.iconSide ? props.iconSide : ''}
          `}
          style={ styleBorder }
        >
          {props.children}
          {props.iconPath && (
            <div className="btn-icon-content">
              <CustomTooltip
                text={props.tooltipText}
                isHide={!props.tooltipText}
                placement={props.tooltipPosition ? props.tooltipPosition : TooltipPlacementEnum.TOP}
              >
                <Icone
                  iconPath={props.iconPath}
                  // Atributo iconSize só está utilizando iconSize(type) para tipo de botão ícone,
                  // pois não temos (até 03/11/21) as definições UX de grande e pequeno para demais tipos de botões
                  iconSize={
                    props.buttonType === ButtonTypeEnum.ICON
                      ? iconSize(props.buttonSize)
                      : TamanhoIconeEnum.SMALL
                  }
                />
              </CustomTooltip>
            </div>
          )}
          <span className="btn-text-content"> {props.buttonText} </span>
        </button>
      </ReactPlaceholder>
    )
  )
}
