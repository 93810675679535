import React from 'react'
import { formatBytes } from '../../../utils/file-utils'

type Props = {
  maximumSizeOfTheSummedFilesInMB: number
  currentTotalFileSizeInByte: number
}

export const MpFileUploadTableMaxFileSize = ({ maximumSizeOfTheSummedFilesInMB, currentTotalFileSizeInByte }: Props): React.ReactElement => {
  return (
    <>
      {formatBytes(currentTotalFileSizeInByte, true).toString()}
      <strong>
        {maximumSizeOfTheSummedFilesInMB && '/'.concat(maximumSizeOfTheSummedFilesInMB?.toString()).concat('MB')}
      </strong>
    </>
  )
}
