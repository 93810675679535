
type ItemProps = Object & {
  id: string | number
}
type Props = {
  from: ItemProps[]
  totalItems: number
  limit: number
  itemToFind: ItemProps
}

export const getCurrentPage = (props: Props): number => {
  const currentIndex = props.from.findIndex((item) => item.id === props.itemToFind.id)
  let currentPage = 0

  for (let i = 0; i <= props.totalItems; i++) {
    const mod = i % props.limit
    if (mod === 0) {
      currentPage++
    }
    if (i === currentIndex) {
      break
    }
  }
  return currentPage
}

export const getCurrentPosition = (props: Props): number => {
  const currentIndex = props.from.findIndex((item) => item.id === props.itemToFind.id)
  let currentPage = getCurrentPage(props)
  let scrollPosition =  currentIndex < props.limit ? currentIndex : currentIndex - (props.limit * (currentPage - 1) )
  
  return scrollPosition 
}

