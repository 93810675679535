import { CoresEnum } from '../../enums'

export const hasADestination = (result): boolean => !!result.destination

const reorderRows = (rows: any[], startIndex: number, endIndex: number): any => {
  const result = Array.from(rows)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const DragAndAdropHandler = {

  reorder: (sourceType: 'TABLE_ROW', list: any[], dragAndDropEvent: any): any[] => {
    if (!dragAndDropEvent.destination) return
    switch (sourceType) {
      case 'TABLE_ROW' : return reorderRows(list, dragAndDropEvent.source.index, dragAndDropEvent.destination.index)
    }
  }

}

export const getDraggableItemStyle = (isDragging: boolean, draggableStyle: any): any => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  background: isDragging ? 'white' : '',
  boxShadow: isDragging ? '0 4px 4px rgba(0,0,0, .25)' : '',
  border: isDragging ? ` 1px solid ${CoresEnum.TERTIARY.value}` : '',

  // styles we need to apply on draggables
  ...draggableStyle
})

export const getDroppableAreaStyle = (isDraggingOver: boolean): { background: string } => ({
  background: isDraggingOver ? 'none' : 'none'
})
