import { mdiAlertOutline, mdiCheckCircleOutline, mdiCloseCircleOutline, mdiInformationOutline } from "@mdi/js"
import { NotificationTypeEnum } from "../enums/"

export const getNotificationIcon = (modalType: NotificationTypeEnum): string => {
    switch (modalType) {
      case NotificationTypeEnum.DANGER: return mdiCloseCircleOutline
      case NotificationTypeEnum.INFO: return mdiInformationOutline
      case NotificationTypeEnum.SUCCESS: return mdiCheckCircleOutline
      case NotificationTypeEnum.WARNING: return mdiAlertOutline
    }
  }
  
