/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { MpFileUpload, MpGrid, MpGridItem } from 'mp-react-components'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { FormValues } from '../../types'
import { AnalisePrioritaria } from '../AnalisePrioritaria'
import { useHandleFiles } from 'pages/AnaliseTecnica/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 'bold',
      color: '#B79266',
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(16),
      color: '#B79266',
      marginBottom: '20px',
    },
  }),
)

export const NomeacaoTecnico: React.FC = () => {
  const formik = useFormikContext<FormValues>()

  const { changeFiles } = useHandleFiles()

  const classes = useStyles()

  return (
    <>
      {formik.values.tipoSolicitacao.tipoSolicitacao === 'AJNTAP' && (
        <div className='content-tipo-solicitacao'>
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <Typography className={classes.heading}>Ação Judicial</Typography>
              <Typography className={classes.subtitle}>
                Nomeação de técnico como assistente pericial
              </Typography>
            </MpGridItem>
            <MpGridItem xl={12} lg={12} md={12}>
              <Field name='tipoSolicitacao.descServSolicDuviTec'>
                {({ field }: any) => (
                  <>
                    <div className='container-textarea'>
                      <label>Descrição do serviço técnico solicitado e dúvida técnica *</label>
                      <textarea {...field} placeholder='Digite seu texto aqui.' />
                    </div>
                    <ErrorMessage
                      name='tipoSolicitacao.descServSolicDuviTec'
                      component='div'
                      className='error-message'
                    />
                  </>
                )}
              </Field>
            </MpGridItem>
          </MpGrid>
          <AnalisePrioritaria />
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <MpFileUpload
                actions={['remove']}
                label='Documentação referente a solicitação'
                onUploadSuccess={(e) => changeFiles(e)}
                placeholder='Clique aqui ou arraste para anexar um arquivo'
                signButtonDisabledDelay={2000}
              />
            </MpGridItem>
          </MpGrid>
        </div>
      )}
    </>
  )
}
