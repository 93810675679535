import React from 'react'
import classNames from 'classnames'
import { MultiToggleSizeEnum, MultiToggleTypeEnum } from '../../enums'
import './multi-toggle-button.scss'
import Icon from '@mdi/react'

export interface MpMultiToggleButtonProps {
  id: number
  value: any
  size: MultiToggleSizeEnum
  type: MultiToggleTypeEnum
  isSelected: boolean
}

export const MpMultiToggleButton: React.FC<MpMultiToggleButtonProps> = (props: MpMultiToggleButtonProps) => {
  const getIconSize = (size: string): string => {
    switch (size) {
      case MultiToggleSizeEnum.LARGE:
        return '26.67px'
      case MultiToggleSizeEnum.MEDIUM:
        return '26.67px'
      case MultiToggleSizeEnum.SMALL:
        return '22.5px'
      case MultiToggleSizeEnum.XSMALL:
        return '15px'
    }
  }

  return (
    <>
      {props.type === MultiToggleTypeEnum.TEXT && (
        <button
          data-testid={`multi-toggle-${props.type}-button-${props.id}`}
          className={classNames(
            `multi-toggle-button
             multi-toggle-button-text
             ${props.size}-button-text`, {
              'multi-toggle-button-selected': props.isSelected
            })}
        >
          {props.value}
        </button>
      )}

      {props.type === MultiToggleTypeEnum.ICON && (
        <button
          data-testid={`multi-toggle-${props.type}-button-${props.id}`}
          className={
            classNames(
            `multi-toggle-button
             multi-toggle-button-icon
            ${props.size}-button-icon`, {
              'multi-toggle-button-selected': props.isSelected
            })}
        >
          <Icon
            path={props.value}
            size={getIconSize(props.size)}
          />
        </button>
      )}
    </>
  )
}
