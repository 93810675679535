import { MpFileProps } from '../../../domain/File'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { MpFileVieweModeEnum, NEW_WINDOW_FILE_PAGE_KEY, NEW_WINDOW_FILE_POSITION_KEY, NEW_WINDOW_QUERY_PARAMS_KEY } from '../../../enums'
import { getCurrentPage, getCurrentPosition } from '../../../utils/pagination-utils'
import { MpBiDirecionalInfiniteScroll } from '../../InfiniteScroll/bidirecional-infinite-scroll'
import { MpFileListViewerHeaderControl } from './MpFileListViewerHeaderControl'
import { MpFileListViewerItem } from './MpListViewerItem'

type Props = {
  isLoading?: boolean
  files: MpFileProps[]
  onFileClick: (file: MpFileProps) => void
  next?: (currentPage: number) => void
  previous?: (currentPage: number) => void
  limit?: number
  totalItems?: number
  hide?: boolean
  currentFile?: MpFileProps
}

export type MpFileListViewerProps = Props

export const MpFileListViewer: React.FC<Props> = (props: Props) => {
  const { currentFile } = props
  const isNewTabViewer = new URLSearchParams(location.href).get(NEW_WINDOW_QUERY_PARAMS_KEY) === MpFileVieweModeEnum.NEW_WINDOW
  const [hidenListViewer, setHidenListViewer] = useState<boolean>(true)
  const listRef = useRef(null)
  const countScroll = useRef(1)

  const saveFilePage = (file: MpFileProps): void => {
    if (isNewTabViewer) {
      localStorage.setItem(NEW_WINDOW_FILE_PAGE_KEY, null)
    } else {
      const currentPage = getCurrentPage({ itemToFind: file, from: props.files, limit: props.limit, totalItems: props.totalItems }).toString()
      const currentPosition = getCurrentPosition({ itemToFind: file, from: props.files, limit: props.limit, totalItems: props.totalItems }).toString()
      localStorage.setItem(NEW_WINDOW_FILE_PAGE_KEY, currentPage)
      localStorage.setItem(NEW_WINDOW_FILE_POSITION_KEY, currentPosition)
    }
  }

  const onFileClick = (file: MpFileProps): void => {
    saveFilePage(file)
    props.onFileClick(file)
  }

  useEffect(() => {
    if (props.hide) {
      return
    }
    const index = props?.files?.findIndex(file => file?.id === currentFile?.id)
    if (countScroll.current === 1) {
      listRef?.current?.scrollToItem(index, 'start')
    } else {
      listRef?.current?.scrollToItem(index)
    }
    countScroll.current++
  }, [currentFile, props.hide, props?.files])

  return (
    <>
      {
        (!props.hide) && (
          <div data-testid='mp-file-viewer-filelist' className={`mp-file-viewer-filelist ${hidenListViewer ? 'mode-collapsed' : ''}`}>
            <MpFileListViewerHeaderControl
              isCollapsed={hidenListViewer}
              files={props.files}
              totalFiles={props.totalItems}
              currentFile={props.currentFile}
              onMenuClick={() => setHidenListViewer(!hidenListViewer)}
              onFileClick={(file) => {
                onFileClick(file)
              }} />
            <MpBiDirecionalInfiniteScroll
              isLoading={props.isLoading}
              height={isNewTabViewer ? screen.availHeight - 210 : screen.availHeight - 278}
              isCollapsed={hidenListViewer}
              items={props.files}
              listRef={listRef}
              listClassName={`${hidenListViewer ? 'file-list-height-collapsed' : 'file-list-height'}`}
              totalItems={props.totalItems}
              next={props.next}
              previous={props.previous}
              startIndex={Number(isNewTabViewer ? localStorage.getItem(NEW_WINDOW_FILE_POSITION_KEY) : 0)}
              startPage={Number(isNewTabViewer ? localStorage.getItem(NEW_WINDOW_FILE_PAGE_KEY) : 1)}>
              {({ index, style }: { index: number, style: CSSProperties }): React.ReactElement => (
                <MpFileListViewerItem
                  index={index}
                  style={style}
                  isCollapsed={hidenListViewer}
                  currentFile={currentFile}
                  files={props.files}
                  hidenListViewer={hidenListViewer}
                  onFileClick={onFileClick} />
              )}
            </MpBiDirecionalInfiniteScroll>
          </div>
        )
      }
    </>

  )
}
