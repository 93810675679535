import React from 'react'
import Dropzone, { DropEvent, FileError, FileRejection } from 'react-dropzone'
import MpTypography from '../../Typography'
import { BadgePositionEnum, CoresEnum, MpFileUploadErros, MpFileUploadErrosMap, TamanhoIconeEnum } from '../../../enums'
import Icone from '../../icone'
import { mdiAttachment } from '@mdi/js'
import Badge from '../../Badge'
import { MimeType } from '@/domain/File'
import { mimetypeExtensionMap } from '../../../domain/MimeType'
import { MpFileUploadItemProps } from '../MpFileUpload'
import { mbToByte } from '../../../utils/file-utils'

type Props = {
  placeholder: string
  maxFileSize: number
  maxFiles: number
  files: MpFileUploadItemProps[]
  count: number
  accept: MimeType[]
  isMultiple?: boolean
  maximumSizeOfTheSummedFilesInMB: number
  sizeOfAllFilesAdded: number
  id: string
  handleOnDrop?: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
}

export const MpFileUploadContainer = (props: Props): any => {
  const {
    maxFileSize, maxFiles, handleOnDrop,
    files,
    placeholder, accept, count,
    maximumSizeOfTheSummedFilesInMB,
    sizeOfAllFilesAdded,
    id
  } = props
  const ACCEPTED_FILES = accept?.reduce((acc, cur) => {
    acc[cur] = mimetypeExtensionMap[cur]
    return acc
  }, {})

  const customValidation = (file: File): FileError[] => {
    const validations = []
    if (files?.some(f => f.fileName === file.name)) {
      const code = MpFileUploadErros.FILE_EXISTS
      const message = MpFileUploadErrosMap[code]
      validations.push({ message, code })
    }
    if (validations?.length > 0) {
      return validations
    } else {
      return null
    }
  }

  const handleDrop = (succededFiles: File[], filesRejections: FileRejection[], event: DropEvent): void => {
    const sizeOfSuccededFiles = succededFiles.reduce((acc: number, next: File): number => {
      acc += next.size
      return acc
    }, 0)
    if ((sizeOfAllFilesAdded + sizeOfSuccededFiles) >= mbToByte(maximumSizeOfTheSummedFilesInMB)) {
      const code = MpFileUploadErros.MAXIMUM_SIZE_OF_THE_SUMMED_FILES_IN_MB
      const message = MpFileUploadErrosMap[code]
      filesRejections.push({ file: null, errors: [{ code, message }] })
      succededFiles = []
    }

    if ((files.length + succededFiles.length) > maxFiles) {
      const code = MpFileUploadErros.TO_MANY_FILES
      const message = MpFileUploadErrosMap[code]
      filesRejections.push({ file: null, errors: [{ code, message }] })
      succededFiles = []
    }
    handleOnDrop(succededFiles, filesRejections, event)
  }

  return (
    <Dropzone
      accept={ACCEPTED_FILES}
      maxSize={maxFileSize}
      maxFiles={maxFiles}
      multiple={maxFiles !== 1}
      validator={customValidation}
      onDrop={handleDrop}>
      {({ getRootProps, getInputProps }) => (
        <div className="mp-file-upload">
          <Badge showWithoutNumber={false} position={BadgePositionEnum.TOP_RIGHT} count={count}>
            <div className='mp-file-upload-container' {...getRootProps()}>
              <input id={id} data-testid={id} {...getInputProps()} />
              <Icone iconColor={CoresEnum.SECONDARY} iconPath={mdiAttachment} iconSize={TamanhoIconeEnum.MEDIUM} />
              <MpTypography
                id={'mp-file-upload-placeholder'}
                color={CoresEnum.SECONDARY}
                variant={'paragraph'}>{placeholder}</MpTypography>
            </div>
          </Badge>
        </div>
      )}
    </Dropzone>
  )
}
