import React, { ReactNode } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ButtonGroup } from '../ButtonGroup'
import { ButtonProps } from '@/components/Buttons/Button'
import { Button } from '../Buttons'
import { Icone } from '../icone/Icone'
import { TamanhoModalEnum, TamanhoIconeEnum, NotificationTypeEnum, ButtonTypeEnum, ButtonThemeEnum } from '../../enums/'
import { getNotificationIcon } from '../../utils/NotificationIconHandler'
import './custom-modal.scss'

export interface ModalProps {
  title?: string
  id?: string
  show: boolean
  children: ReactNode
  modalType?: NotificationTypeEnum
  bodyCssClassName?: string
  isLight?: boolean
  hideHeader?: boolean
  hideTitle?: boolean
  hideFooter?: boolean
  footerButtons?: ButtonProps[]
  onClose?: () => void
  isFixedHeight?: boolean
  headerButtons?: ButtonProps[]
  size?: TamanhoModalEnum
  customModal?: sizeCustomModal
}

interface sizeCustomModal {
  sizeCustomUnit: 'px' | '%'
  sizeCustomHeight: number
  sizeCustomWidth: number
}

const ModalIcon = ({ modalType }: ModalProps) => (
  <>
    {modalType && <div className="modal-icon"><Icone iconPath={getNotificationIcon(modalType)} iconSize={TamanhoIconeEnum.BIG} /></div>}
  </>
)

const setButtonsTheme = (buttonKey: number, buttonsListLength, modalType: NotificationTypeEnum, buttonTheme: any): ButtonThemeEnum[] => {
  const botaoPrimario = buttonsListLength === 3 ? 2 : null
  const buttonThemes = buttonKey === botaoPrimario ? [ButtonThemeEnum.CONTAINED] : []

  if (buttonsListLength === 2 && buttonTheme) {
    buttonThemes.push(buttonTheme)
  }

  switch (modalType) {
    case NotificationTypeEnum.DANGER: return [...buttonThemes, ButtonThemeEnum.DANGER]
    case NotificationTypeEnum.INFO: return [...buttonThemes, ButtonThemeEnum.INFO]
    case NotificationTypeEnum.SUCCESS: return [...buttonThemes, ButtonThemeEnum.SUCCESS]
    case NotificationTypeEnum.WARNING: return [...buttonThemes, ButtonThemeEnum.WARNING]
  }
}

const setButtonsVariation = (buttonThemes: ButtonThemeEnum[], isLight: boolean): ButtonThemeEnum[] => isLight ? [...buttonThemes, ButtonThemeEnum.LIGHT] : buttonThemes

export const CustomModal: React.FC<ModalProps> = (props: ModalProps) => {
  /*
    let backdrop, keyboard
    if (props.noEscape) {
      backdrop = 'static'
      keyboard = false
    } else {
      backdrop = true
      keyboard = true
    }
  */
  return (
    <Modal data-testid={props.id || 'mp-custom-modal'}
      id={props.id ? props.id : 'mp-custom-modal'}
      className={`${props.modalType ? 'notification-modal' : ''}  
                ${props.modalType ? props.modalType : ''} 
                ${props.isLight ? 'light' : ''} 
                ${props.isFixedHeight ? 'modal-fixed-height' : ''}
                ${props.size ? props.size : null}`}
      show={props.show}
      onHide={props.onClose}
      onExited={props.onClose ? props.onClose : null}
      backdrop={true}
      keyboard={true}
      animation={false} centered
    >
      {(props?.customModal) &&
      <style jsx>
        {`
          /* Custom */
          .mp-modal-custom .modal-content {
            max-width: ${props?.customModal?.sizeCustomWidth}${props?.customModal?.sizeCustomUnit};
            height: ${props?.customModal?.sizeCustomHeight}${props?.customModal?.sizeCustomUnit};
          }
        `}
      </style>
      }

      { (!props.hideHeader) &&
        <Modal.Header>

          <ModalIcon {...props} />

          { (!props.hideTitle) &&
            <Modal.Title>
              {props.title}
            </Modal.Title>
          }

          { (!props.modalType &&
            props.headerButtons?.length) &&
            <ButtonGroup data-testid='mp-custom-modal-buttons-header'>
              {props.headerButtons?.map((button, key) =>
                <Button data-testid={`mp-card-button-${key}`} key={key} buttonType={ButtonTypeEnum.ICON} {...button} />
              )}
            </ButtonGroup>
          }

        </Modal.Header>
      }

      <Modal.Body className={props.bodyCssClassName ? props.bodyCssClassName : null}>
        {props.children}
      </Modal.Body>

      {(!props.hideFooter) &&
        <Modal.Footer>
          <ButtonGroup data-testid='mp-custom-modal-buttons-footer'>
            {props.footerButtons?.map((button, key) =>
              <Button data-testid={`mp-custom-modal-buttom-${key}`} key={key} handleClick={button.handleClick}
                buttonType={props.footerButtons.length >= 2 && key === 1 ? ButtonTypeEnum.OUTLINE : null}
                buttonTheme={setButtonsVariation(setButtonsTheme(key, props.footerButtons.length, props.modalType, button.buttonTheme), props.isLight)}
                {...button} />
            )}
          </ButtonGroup>
        </Modal.Footer>
      }

    </Modal>
  )
}
