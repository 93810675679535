import React, { EffectCallback, useEffect, useRef, useState } from 'react'
import { FixedTab, MpFixedTabs } from '../../components/FixedTabs/FixedTabs'
import { DefaultViewer, FullScreenViewer } from './MpWindows'
import { SimpleButtonProps } from '../CardBasico/CardBasico'
import { MpWindowModeEnum } from '../../enums'
import { ButtonProps } from '../Buttons/Button'
import './mpwindowmanager.scss'

export type MpWindowProps = {
  id: string
  title: string
}

export type MpWindowManagerProps = {
  currentWindow: MpWindowProps
  metadata?: any
  show?: boolean
  hideHeader?: boolean
  isFlex?: boolean
  children?: React.ReactNode
  tabs?: FixedTab[]
  withHeaderHR?: boolean
  withBottomTabs?: boolean
  buttons? : ButtonProps[]
  footerButtons?: SimpleButtonProps[]
  onClose?: (closedWindowId: string) => void
  onCloseAll?: () => void
  onFullScreen?: (metadata: any) => void
  onFloatScreen?: (metadata: any) => void
  onNewWindow?: (openedWindow: MpWindowProps) => void
  newWindowUrl?: string
  onMinimized?: () => void
  onChangeViewerMode?: (viewerMode: MpWindowModeEnum) => void
  heightDecrement?: number
  onChangeTabs?: (tabs: FixedTab[]) => void
}

export const MpWindowManager: React.FC<MpWindowManagerProps> = (props: MpWindowManagerProps) => {
  const {
    withBottomTabs,
    onClose,
    onFullScreen,
    onFloatScreen,
    onMinimized,
    onNewWindow,
    currentWindow,
    children,
    metadata,
    show,
    onCloseAll
  } = props

  const [tabs, setTabs] = useState<FixedTab[]>([])
  const filesInTabRef = useRef<FixedTab[]>([])
  const [lastClosedId, setLastClosedId] = useState<string>('')
  const { FULLSCREEN, DEFAULT } = MpWindowModeEnum
  const [viewerMode, setViewerMode] = useState<MpWindowModeEnum>(MpWindowModeEnum.DEFAULT)
  const [wrapperOffset, setWrapperOffset] = useState<string>('3')

  const contentWrapperRef = useRef(null)

  const handleMaximized = (): void => {
    setViewerMode(FULLSCREEN)
    if (onFullScreen) {
      onFullScreen(metadata)
    }
  }

  const handleFloat = (): void => {
    setViewerMode(DEFAULT)
    if (onFloatScreen) {
      onFloatScreen(metadata)
    }
  }

  const handleClose = (id: string): void => {
    const remove = (fileInTab): boolean => fileInTab.id !== id
    setTabs(filesInTabRef.current.filter(remove))
    setLastClosedId(id)
    setViewerMode(DEFAULT)
  }

  const handleMinimized = (): void => {
    setViewerMode(DEFAULT)
    if (onMinimized) {
      onMinimized()
    }
  }

  const handleNewWindow = (openedWindow: MpWindowProps): void => {
    if (onNewWindow) {
      onNewWindow(currentWindow)
    }
    if (onClose) {
      onClose(openedWindow.id)
    }
    setTabs(tabs?.filter(tab => tab.id !== openedWindow.id))
    setViewerMode(MpWindowModeEnum.DEFAULT)
    open(props.newWindowUrl, '', `width=${screen.width - 500},height=${screen.height - 300},status=no`)
  }

  const handleCloseAll = (): void => {
    setViewerMode(DEFAULT)
    setTabs([])
    if (onCloseAll) {
      onCloseAll()
    }
  }

  const effectTabsIds: EffectCallback = () => {
    filesInTabRef.current = tabs
    return () => {
      setLastClosedId('')
    }
  }

  const effectLastClosedId = (): void => {
    if (lastClosedId && handleClose && onClose) {
      onClose(lastClosedId)
    }
  }

  const effectTabs = (): void => {
    const fileExists = tabs.some((fileInTab) => fileInTab?.id === currentWindow?.id)
    if (!fileExists && withBottomTabs && currentWindow?.id && currentWindow?.title && viewerMode === MpWindowModeEnum.DEFAULT) {
      const newTab = {
        id: currentWindow.id,
        label: currentWindow.title,
        handleFloat,
        metadata,
        handleMaximized,
        handleMinimized,
        onClose: () => handleClose(currentWindow?.id)
      }
      setTabs([newTab, ...tabs])
    }
  }

  const effectExternalTabs = (): void => {
    props.tabs?.forEach((tab) => {
      const indexExistingTab = tabs?.findIndex((inTab) => inTab?.id === tab?.id)
      if (indexExistingTab !== -1) {
        tabs[indexExistingTab].label = tab?.label
        setTabs(tabs)
      } else {
        setTabs([tab, ...tabs])
      }
    })
  }

  const handleChangeViewerMode = (): void => {
    if (props.onChangeViewerMode) {
      props.onChangeViewerMode(viewerMode)
    }
  }

  const effectTabsChange = (): void => {
    if (!props.onChangeTabs) return

    props.onChangeTabs(tabs)
  }

  const initializeWrapperOffset = (): void => {
    setWrapperOffset(contentWrapperRef.current?.offsetTop)
  }

  useEffect(effectTabs, [currentWindow])
  useEffect(effectTabsIds, [tabs])
  useEffect(effectTabsChange, [tabs])
  useEffect(effectLastClosedId, [lastClosedId])
  useEffect(effectExternalTabs, [props.tabs])
  useEffect(handleChangeViewerMode, [viewerMode])
  useEffect(initializeWrapperOffset, [])

  const getHeight = (): string => {
    return contentWrapperRef.current ? contentWrapperRef.current.offsetTop : '0'
  }

  return (
    <>
      {
        show &&
        <div ref={contentWrapperRef} style={{ height: 'calc(100% - ' + getHeight() + 'px)' }} className="mp-window-manager-wrapper" >
          <div className="mp-window-manager">
            <DefaultViewer
              {...props}

              onClose={() => handleClose(currentWindow?.id)}
              onFullScreen={handleMaximized}
              onMinimized={handleMinimized}
              onNewWindow={handleNewWindow}
              children={children}
              show={viewerMode === MpWindowModeEnum.DEFAULT || !viewerMode} />
            <FullScreenViewer
              {...props}
              onClose={() => handleClose(currentWindow?.id)}
              onFloatScreen={handleFloat}
              children={children}
              onNewWindow={handleNewWindow}
              onMinimized={handleMinimized}
              show={viewerMode === MpWindowModeEnum.FULLSCREEN} />
          </div>
        </div>
      }
      {
        withBottomTabs && (
          <MpFixedTabs
            activeTabId={currentWindow.id}
            onCloseAll={handleCloseAll}
            tabs={tabs} />
        )
      }
    </>
  )
}

export default MpWindowManager
