import React, { forwardRef, ReactNode } from 'react'
import { mdiMenuDown, mdiMenuSwap, mdiMenuUp } from '@mdi/js'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import { ButtonTypeEnum } from '../../enums'
import MpSpacingProps, { getSpacing } from '../Spacing'
import { skeletonTable } from './table-skeleton'
import Button from '../Buttons'
import { ButtonSizeEnum } from '../../enums/ButtonSizeEnum'

import 'react-placeholder/lib/reactPlaceholder.css'
import './table.scss'

export interface Column {
  content: ReactNode
  colspan?: number
  width?: string
  isOrdenable?: boolean
  orderType?: 'ASC' | 'DESC'
  onClick?: (col) => any
}

export interface MpTableProps {
  id?: string
  ref? : any
  children: ReactNode
  header: MpTableColumnsProps
  isLoading?: boolean

  htmlInternalProps? : any
  footer?: MpTableColumnsProps
  hasHighlightInFirstColumn?: boolean
  firstColumnIndex?: number
  spacing?: MpSpacingProps
  style? : any

}

export interface MpTableColumnsProps {
  columns: Column[]
}

export interface MpTableRowProps {
  id?: string
  innerRef? : any
  htmlInternalProps? : any
  noHover?: boolean
  children: ReactNode
  cssClassName?: string
  style? : any
}

export interface MpTableCellProps {
  children: ReactNode
  id?: string
  width?: string
  colspan?: number
}
export const MpTable = forwardRef<MpTableProps, any>((props: MpTableProps, ref: any) => {
  MpTable.displayName = 'Table'

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonTable}>
      <div data-testid={props.id} ref={ref}
        {...props.htmlInternalProps}
        style={props.style}
        className={`mp-table ${props.hasHighlightInFirstColumn ? (props.firstColumnIndex >= 1 ? 'highlight-second' : 'highlight-first') : ''}`}>
        <table className={`${props.spacing ? getSpacing(props.spacing) : ''} `}>
          <MpTableHeader columns={props.header.columns} />
          <tbody>
            {props.children}
          </tbody>
          {props.footer &&
            <MpTableFooter columns={props.footer.columns} />
          }
        </table>
      </div>
    </ReactPlaceholder>
  )
})

export const MpTableHeader: React.FC<MpTableColumnsProps> = (props: MpTableColumnsProps) => {
  const getOrderIcon = (column: Column): string => {
    return column.orderType ? (column.orderType === 'ASC' ? mdiMenuDown : mdiMenuUp) : mdiMenuSwap
  }

  return (
    <thead>
      <tr >
        {props.columns.map((column, index) => (
          <td key={`mp-table-td${index}`} style={column.width ? { width: column.width } : {}}>
            <div className='mp-d-flex' >
              <div className='td-content'>{column.content}</div>
              {column.isOrdenable &&
              <Button buttonType={ButtonTypeEnum.ICON} buttonSize={ButtonSizeEnum.SMALL} iconPath={getOrderIcon(column)} handleClick={() => column.onClick ? column.onClick(column) : null}></Button>}
            </div>
          </td>
        ))}
      </tr>
    </thead>
  )
}
export const MpTableRow = forwardRef<MpTableRowProps, any>((props: MpTableRowProps, ref: any) => {
  MpTableRow.displayName = 'TableRow'

  return (
    <tr
      {...props.htmlInternalProps}
      style={props.style}
      ref = {ref}
      key={props.id || `mp-table-row-id-${Math.random() * 100}`}
      id={props.id || `mp-table-row-id-${Math.random() * 100}`}
      data-testid={props.id}
      className={`
                  ${props.noHover ? '' : 'mp-table-hover'}
                  ${props.cssClassName ? props.cssClassName : ''}
                `}
    >
      {props.children}
    </tr>
  )
})

export const MpTableCell: React.FC<MpTableCellProps> = (props: MpTableCellProps) => {
  return (
    <td style={props.width ? { width: props.width } : {}} colSpan={props.colspan ? props.colspan : undefined}>
        {props.children}
    </td>
  )
}

export const MpTableFooter: React.FC<MpTableColumnsProps> = (props: MpTableColumnsProps) => {
  return (
    <tfoot>
      <MpTableRow key={'mp-table-footer-tr'}>
        {props.columns.map((column, index) => (
          <MpTableCell key={`mp-table-footer-td${index}`} colspan={column.colspan ? column.colspan : undefined}>
            {column.content}
          </MpTableCell>
        ))}
      </MpTableRow>
    </tfoot>
  )
}
