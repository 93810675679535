import variables from '../../src/assets/css/colors.scss'

export class CoresEnum {
  static readonly WHITE = new CoresEnum('white', variables.white)
  static readonly BLACK = new CoresEnum('black', variables.black)
  static readonly ACCENT = new CoresEnum('accent', variables.accent)

  static readonly DANGER = new CoresEnum('danger', variables.danger)
  static readonly DANGER_LIGHTEST = new CoresEnum('danger_lightest', variables.danger)
  static readonly DANGER_LIGHT = new CoresEnum('danger_light', variables.danger)
  static readonly DANGER_DARK = new CoresEnum('danger_dark', variables.danger)
  static readonly DANGER_DARKEST = new CoresEnum('danger_darkest', variables.danger)

  static readonly INFO = new CoresEnum('info', variables.info)
  static readonly INFO_LIGHTEST = new CoresEnum('info_lightest', variables.info_lightest)
  static readonly INFO_LIGHT = new CoresEnum('info_light', variables.info_light)
  static readonly INFO_DARK = new CoresEnum('info_dark', variables.info_dark)
  static readonly INFO_DARKEST = new CoresEnum('info_darkest', variables.info_darkest)

  static readonly SUCCESS = new CoresEnum('success', variables.success)
  static readonly SUCCESS_LIGHTEST = new CoresEnum('success_lightest', variables.success)
  static readonly SUCCESS_LIGHT = new CoresEnum('success_light', variables.success)
  static readonly SUCCESS_DARK = new CoresEnum('success_dark', variables.success)
  static readonly SUCCESS_DARKEST = new CoresEnum('success_darkest', variables.success)

  static readonly WARNING = new CoresEnum('warning', variables.warning)
  static readonly WARNING_LIGHTEST = new CoresEnum('warning_lightest', variables.warning)
  static readonly WARNING_LIGHT = new CoresEnum('warning_light', variables.warning)
  static readonly WARNING_DARK = new CoresEnum('warning_dark', variables.warning)
  static readonly WARNING_DARKEST = new CoresEnum('warning_darkest', variables.warning)

  static readonly PRIMARY = new CoresEnum('primary', variables.primary)
  static readonly SECONDARY = new CoresEnum('secondary', variables.secondary)
  static readonly TERTIARY = new CoresEnum('tertiary', variables.tertiary)
  static readonly TERTIARY_050 = new CoresEnum('tertiary', variables.tertiary050)
  static readonly TERTIARY_100 = new CoresEnum('tertiary', variables.tertiary100)
  static readonly TERTIARY_200 = new CoresEnum('tertiary', variables.tertiary200)
  static readonly TERTIARY_300 = new CoresEnum('tertiary', variables.tertiary300)
  static readonly TERTIARY_400 = new CoresEnum('tertiary', variables.tertiary400)
  static readonly TERTIARY_500 = new CoresEnum('tertiary', variables.tertiary500)
  static readonly TERTIARY_600 = new CoresEnum('tertiary', variables.tertiary600)
  static readonly TERTIARY_700 = new CoresEnum('tertiary', variables.tertiary700)
  static readonly TERTIARY_800 = new CoresEnum('tertiary', variables.tertiary800)
  static readonly TERTIARY_900 = new CoresEnum('tertiary', variables.tertiary900)
  static readonly GRAY_050 = new CoresEnum('gray_50', variables.gray050)
  static readonly GRAY_100 = new CoresEnum('gray_100', variables.gray100)
  static readonly GRAY_200 = new CoresEnum('gray_200', variables.gray200)
  static readonly GRAY_300 = new CoresEnum('gray_300', variables.gray300)
  static readonly GRAY_400 = new CoresEnum('gray_400', variables.gray400)
  static readonly GRAY_500 = new CoresEnum('gray_500', variables.gray500)
  static readonly GRAY_600 = new CoresEnum('gray_600', variables.gray600)
  static readonly GRAY_700 = new CoresEnum('gray_700', variables.gray700)
  static readonly GRAY_800 = new CoresEnum('gray_800', variables.gray800)
  static readonly GRAY_900 = new CoresEnum('gray_900', variables.gray900)
  static readonly APOIO_1 = new CoresEnum('apoio_1', variables.apoio01)
  static readonly APOIO_1_LIGHT = new CoresEnum('apoio_1_light', variables.apoio01Light)
  static readonly APOIO_1_DARK = new CoresEnum('apoio_1_dark', variables.apoio01Dark)
  static readonly APOIO_2 = new CoresEnum('apoio_2', variables.apoio02)
  static readonly APOIO_2_LIGHT = new CoresEnum('apoio_2_light', variables.apoio02Light)
  static readonly APOIO_2_DARK = new CoresEnum('apoio_2_dark', variables.apoio02Dark)
  static readonly APOIO_3 = new CoresEnum('apoio_3', variables.apoio03)
  static readonly APOIO_3_LIGHT = new CoresEnum('apoio_3_light', variables.apoio03Light)
  static readonly APOIO_3_DARK = new CoresEnum('apoio_3_dark', variables.apoio03Dark)
  static readonly APOIO_4 = new CoresEnum('apoio_4', variables.apoio04)
  static readonly APOIO_4_LIGHT = new CoresEnum('apoio_4_light', variables.apoio04Light)
  static readonly APOIO_4_DARK = new CoresEnum('apoio_4_dark', variables.apoio04Dark)
  static readonly APOIO_5 = new CoresEnum('apoio_5', variables.apoio05)
  static readonly APOIO_5_LIGHT = new CoresEnum('apoio_5_light', variables.apoio05Light)
  static readonly APOIO_5_DARK = new CoresEnum('apoio_5_dark', variables.apoio05Dark)
  static readonly APOIO_6 = new CoresEnum('apoio_6', variables.apoio06)
  static readonly APOIO_6_LIGHT = new CoresEnum('apoio_6_light', variables.apoio06Light)
  static readonly APOIO_6_DARK = new CoresEnum('apoio_6_dark', variables.apoio06Dark)
  static readonly APOIO_7= new CoresEnum('apoio_7', variables.apoio07)
  static readonly APOIO_7_LIGHT = new CoresEnum('apoio_7_light', variables.apoio07Light)
  static readonly APOIO_7_DARK = new CoresEnum('apoio_7_dark', variables.apoio07Dark)
  static readonly APOIO_8 = new CoresEnum('apoio_8', variables.apoio08)
  static readonly APOIO_8_LIGHT = new CoresEnum('apoio_8_light', variables.apoio08Light)
  static readonly APOIO_8_DARK = new CoresEnum('apoio_8_dark', variables.apoio08Dark)
  static readonly APOIO_9 = new CoresEnum('apoio_9', variables.apoio09)
  static readonly APOIO_9_LIGHT = new CoresEnum('apoio_9_light', variables.apoio09Light)
  static readonly APOIO_9_DARK = new CoresEnum('apoio_9_dark', variables.apoio09Dark)
  static readonly APOIO_10 = new CoresEnum('apoio_10', variables.apoio10)
  static readonly APOIO_10_LIGHT = new CoresEnum('apoio_10_light', variables.apoio10Light)
  static readonly APOIO_10_DARK = new CoresEnum('apoio_10_dark', variables.apoio10Dark)
  static readonly APOIO_11 = new CoresEnum('apoio_11', variables.apoio11)
  static readonly APOIO_11_LIGHT = new CoresEnum('apoio_11_light', variables.apoio11Light)
  static readonly APOIO_11_DARK = new CoresEnum('apoio_11_dark', variables.apoio11Dark)
  static readonly APOIO_12 = new CoresEnum('apoio_12', variables.apoio12)
  static readonly APOIO_12_LIGHT = new CoresEnum('apoio_12_light', variables.apoio12Light)
  static readonly APOIO_12_DARK = new CoresEnum('apoio_12_dark', variables.apoio12Dark)
  static readonly APOIO_13_LIGHT = new CoresEnum('apoio_13_light', variables.apoio13Light)
  static readonly APOIO_13_DARK = new CoresEnum('apoio_13_dark', variables.apoio13Dark)
  static readonly APOIO_14_LIGHT = new CoresEnum('apoio_14_light', variables.apoio14Light)
  static readonly APOIO_14_DARK = new CoresEnum('apoio_14_dark', variables.apoio14Dark)
  static readonly APOIO_15 = new CoresEnum('apoio_15', variables.apoio15)
  static readonly APOIO_15_LIGHT = new CoresEnum('apoio_15_light', variables.apoio15Light)
  static readonly APOIO_15_DARK = new CoresEnum('apoio_15_dark', variables.apoio15Dark)
  static readonly APOIO_16 = new CoresEnum('apoio_16', variables.apoio16)
  static readonly APOIO_16_LIGHT = new CoresEnum('apoio_16_light', variables.apoio16Light)
  static readonly APOIO_16_DARK = new CoresEnum('apoio_16_dark', variables.apoio16Dark)
  static readonly APOIO_17 = new CoresEnum('apoio_17', variables.apoio17)
  static readonly APOIO_17_LIGHT = new CoresEnum('apoio_17_light', variables.apoio17Light)
  static readonly APOIO_17_DARK = new CoresEnum('apoio_17_dark', variables.apoio17Dark)
  static readonly APOIO_18 = new CoresEnum('apoio_17', variables.apoio18)
  static readonly APOIO_18_LIGHT = new CoresEnum('apoio_17_light', variables.apoio18Light)
  static readonly APOIO_18_DARK = new CoresEnum('apoio_18_dark', variables.apoio18Dark)

  private constructor (public readonly name: string, public readonly value: string) {
    this.name = name
    this.value = value
  }

  /** Mapeando o retorno das variaveis estaticas da classe Enum(que sao arrays primitivas) para key-value */
  public static toList () {
    const cores = {}

    Object.entries(CoresEnum).filter((_item, index) => index > 0).map(item => {
      cores[item[0]] = item[1]
    })

    return cores
  }
}
