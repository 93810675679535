import React from 'react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { FieldNames } from 'rc-tree/lib/interface'
import { MpFileTreeButton } from '../FileTreeButton/MpFileTreeButton'
import { ButtonSizeEnum } from '../../../enums'

type Props = {
  item: {
    id: string | number
    expanded?: boolean
    children?: any[]
    data?: { children: any[]}
  }
  fieldNames?: FieldNames
  isReduced?: boolean

}

export const MpFileTreeSwitcher: React.FC<Props> = ({ item, fieldNames, isReduced }: Props) => {
  const getDefaulProps = (expanded: boolean): any => ({
    icon: expanded ? mdiChevronUp : mdiChevronDown,
    // handleClick: () => { },  Controlado internamente pelo RCtree
    size: isReduced ? ButtonSizeEnum.SMALL : ButtonSizeEnum.DEFAULT
  })

  if (item?.data?.children?.length > 0) {
    return <MpFileTreeButton id={`mp-file-tree-custom-switcher-with-custom-field-name-${item.id}`} {...getDefaulProps(item.expanded)} />
  }

  if (fieldNames?.children) {
    return (item[fieldNames.children]?.length > 0)
      ? <MpFileTreeButton id={`mp-file-tree-custom-switcher-with-custom-field-name-${item.id}`} {...getDefaulProps(item.expanded)} /> : <></>
  }
  return <></>
}
