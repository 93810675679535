import React from 'react'
import { mdiAlert, mdiCloseCircle } from '@mdi/js'
import Icone from '../../../../components/icone'
import { MpTypography } from '../../../Typography'
import { CoresEnum, NotificationTypeEnum, TamanhoIconeEnum } from '../../../../enums'

type Props = {
  id: string | number
  fileName: string | React.ReactNode
  maxLength?: number
  inactive?: boolean
  typeError?: any
}

const layoutError: { [key in NotificationTypeEnum.DANGER | NotificationTypeEnum.WARNING]: { color: CoresEnum, icon: string }} = {
  [NotificationTypeEnum.DANGER]: {
    color: CoresEnum.DANGER,
    icon: mdiCloseCircle
  },
  [NotificationTypeEnum.WARNING]: {
    color: CoresEnum.WARNING,
    icon: mdiAlert
  }
}

export const MpFileTreeNodeTitle: React.FC<Props> = ({ id, fileName, typeError, maxLength, inactive }: Props) => {
  return (
    <>
      {
        (typeof fileName === 'string')
          ? (
            <div className='mp-d-flex mp-align-items_center'>
              {layoutError[typeError] &&
                <Icone
                  spacing={{ ml: 0 }}
                  iconSize={TamanhoIconeEnum.XSMALL}
                  iconColor={layoutError[typeError]?.color}
                  iconPath={layoutError[typeError]?.icon}
                />
              }
              <MpTypography
                element='div'
                variant='paragraph'
                isTruncate
                id={`mp-file-tree-node-title-${id}`}
                maxLength={maxLength}
                color={inactive ? CoresEnum.GRAY_300 : layoutError[typeError]?.color}
                children={fileName}
              />
            </div>
          )
          : fileName
      }
    </>
  )
}
