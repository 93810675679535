import React from 'react'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import { BrowserRouter, Link } from 'react-router-dom'
import { skeletonBreadcrumb } from './breadcrumb-skeleton'

import 'react-placeholder/lib/reactPlaceholder.css'
import './breadcrumbs.scss'

export interface Crumb {
  id?: number
  path: string
  label: string
}

export interface BreadcrumbsProps {

  crumbs: Crumb[]
  isLoading?: boolean

}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props: BreadcrumbsProps) => {
  if (!props.crumbs || props.crumbs.length <= 1) return null

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonBreadcrumb} >

      <ul className="mp-breadcrumb">

        {props.crumbs.map(({ label, path }, key) =>

          <li key={key} className="mp-breadcrumb-item">

            {key + 1 === props.crumbs.length ? (
              <span>{label}</span>
            ) : (
              <Link className="mp-breadcrumb-link" to={path}>
                {label}
              </Link>
            )}

          </li>

        )}

      </ul>
    </ReactPlaceholder>
  )
}
