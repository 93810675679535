import styled, { css } from "styled-components";

interface HelpPanelBodyProps {
  position: number
}

interface HelpPanelStepProps {
  activeStep: number;
  step: number;
}

export const HelpPanelBody = styled.div<HelpPanelBodyProps>`
  background-color: #fff;
  position: fixed;
  bottom: 10px;
  width: 260px;
  border-radius: 8px;
  z-index: 200;

  ${props => {
    return (
      props.position === 0 &&
      css`left: 10px;`
    );
  }}

  ${props => {
    return (
      props.position === 1 &&
      css`right: 10px;`
    );
  }}
`;

export const HelpPanelHeader = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e7;
  height: 60px;
  align-items: center;
`;

export const HelpPanelContent = styled.div`
  overflow: auto;
  max-height: 400px;
`;

export const HelpPanelFooter = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  border-top: 1px solid #e8e8e7;
  align-items: center;
  padding: 8px;
`;

export const HelpPanelTitle = styled.h2`
  color: #363534;
  padding: 20px;
  margin: 0;
  font-weight: 600;
  font-size: 1.125em;
  font-family: "Roboto Bold";
`;

export const HelpPanelTopicsTitle = styled.h4`
  padding: 15px 30px;
  font-size: 15px;
  font-family: "Roboto";
  color: #9d9b99;
`;

export const HelpPanelList = styled.ul`
  margin: 0 10px;
  font-family: "Roboto";
  color: #9d9b99;
  font-size: 14px;
`;

export const HelpPanelStep = styled.li<HelpPanelStepProps>`
  cursor: pointer;
  border-top: 1px solid #e8e8e7;
  padding: 13px;

  ${(props) => {
    return (
      props.step === props.activeStep &&
      css`
        background-color: #e9ded1;
        border-top: 1px solid #b79266;
        border-bottom: 1px solid #b79266;
      `
    );
  }}
`;

export const HelpPanelListContent = styled.span`
  /* position: relative;
  left: -30px; */
`;

export const HelpPanelStepButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

