import React from 'react'
import {Avatar, AvatarProps } from '../avatar'
import './avatar-group.scss'

export interface AvatarGroupProps {
    itens: AvatarProps[]
}

export const AvatarGroup: React.FC<AvatarGroupProps> = (props: AvatarGroupProps) => {

    return (
        <div className="avatar-list-element" data-testid="avatar">
            {props.itens && props.itens.map((avatar, index) => (
                <Avatar key={index} {...avatar}/>
            ))}
        </div>
    )
}
