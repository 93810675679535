import React, { ReactNode } from 'react'

import 'react-placeholder/lib/reactPlaceholder.css'
import './card-group.scss'

export interface CardGroupProps {
  modoLista?: boolean
  children: ReactNode
}

export const CardGroup: React.FC<CardGroupProps> = (props: CardGroupProps) => {

  return (

    /**
     * Atualizar skeleton pós removação do mp-grid interno deste wrapper
     */
    <>

        <div className={`${props.modoLista ? 'mp-grid mp-grid-list' : 'mp-grid mp-card-grid'}`}>
          {props.children}
        </div>

    </>

  )
}
