import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
// FIXME - Não deveria existir acoplamento com o fileViewer
import { MpFileListViewerLoader } from '../FileViewer/FileListViewer/MpFileListViewerLoader'

type Props = {
  items: any[]
  height: number
  listRef: any
  isCollapsed: boolean
  next: (currentPage: number) => void
  isLoading?: boolean
  previous?: (currentPage: number) => void
  totalItems?: number
  startPage?: number
  startIndex?: number
  listClassName?: string
  children?: ({ index, style }: { index: number, style: CSSProperties }) => React.ReactElement
}

type StateProps = {
  scrollPosition: 'top' | 'bottom'
  effect: () => void
}

export const MpBiDirecionalInfiniteScroll: React.FC<Props> = (props: Props) => {
  const SINGLE_LIST_ITEM = 38
  // Multiplica a área disponível para os itens por um racional que compensa os itens "extras" paginados de maneira oculta(fora da tela)
  const AMOUNT_LOADED_PER_TIME = Math.round((props.height / SINGLE_LIST_ITEM) * 1.45)
  const { listRef } = props
  const countRender = useRef<number>(0)
  const [scrollPositionAndPage, setPositionAndPage] = useState<StateProps>({
    scrollPosition: 'bottom',
    effect: () => {
      if (props.next) {
        props.next(props.startPage || 1)
      }
    }
  })

  const lastPageUp = useRef<number>(props.startPage || 1)
  const lastPageDown = useRef<number>(props.startPage || 1)

  const [isScrollingUp, setIsScrollingUp] = useState(false)

  const next = (): void => {
    setIsScrollingUp(false)
    if (!props.isLoading) {
      lastPageDown.current = lastPageDown.current + 1
      props?.next(lastPageDown.current)
    }
  }

  const previous = (): void => {
    setIsScrollingUp(true)
    if (!props.isLoading) {
      lastPageUp.current = lastPageUp.current - 1
      props?.previous(lastPageUp.current)
    }
  }

  const onScrollDown = (): void => {
    setPositionAndPage({ scrollPosition: 'bottom', effect: () => next() })
  }

  const onScrollUp = (event: any): void => {
    if (event.scrollDirection === 'backward' && event.scrollOffset === 0 && lastPageUp.current > 1) {
      setPositionAndPage({
        scrollPosition: 'top',
        effect: () => {
          previous()
        }
      })
    }
  }

  const effectItems = (): void => {
    if (scrollPositionAndPage.scrollPosition === 'top') {
      listRef.current.scrollTo(SINGLE_LIST_ITEM * AMOUNT_LOADED_PER_TIME)
    }
    countRender.current++
    // Se na primeira renderização, a lista já estiver carregada, basta scrollar para o item
    if (countRender.current === 1 && props.items.length && props.startIndex) {
      listRef.current.scrollToItem(props.startIndex, 'start')
    }
    // Espera-se que  na 2ª renderização, já existam os dados, para que o scroll aponte para o dado informado.
    if (countRender.current === 2 && props.startIndex && scrollPositionAndPage.scrollPosition === 'bottom') {
      listRef.current.scrollToItem(props.startIndex, 'start')
    }
  }

  useEffect(scrollPositionAndPage.effect, [scrollPositionAndPage])
  useEffect(effectItems, [props.items])

  return (
    <>
      <MpFileListViewerLoader hide={(props.items.length > 0 && !isScrollingUp)} isLoading={props.isLoading} isScrollingUp={isScrollingUp} isCollapsed={props.isCollapsed} />
      <InfiniteLoader
        isItemLoaded={index => index < props.items?.length}
        itemCount={props.totalItems}
        threshold={5}
        loadMoreItems={onScrollDown}>
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            onScroll={onScrollUp}
            className={props.listClassName}
            width={40}
            height={props.height}
            itemCount={props.items?.length}
            itemSize={SINGLE_LIST_ITEM}
            onItemsRendered={onItemsRendered}
            ref={(list) => {
              ref(list)
              listRef.current = list
            }}>
            {props.children}
          </FixedSizeList>
        )}
      </InfiniteLoader>
      <MpFileListViewerLoader hide={isScrollingUp} isLoading={props.isLoading} isCollapsed={props.isCollapsed} />
    </>

  )
}
