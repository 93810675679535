import styled, { css } from "styled-components";

export enum TutorialButtonTypeEnum {
  DEFAULT = "btn-default",
  ICON = "btn-icon",
}

interface ButtonProps {
  hasLeftButton?: boolean;
  buttonType: TutorialButtonTypeEnum;
}

export const ButtonWrapper = styled.div`
  margin: 0;
`;

export const ButtonIconContent = styled.div`
  margin: auto;
`

export const Button = styled.button<ButtonProps>`
  ${({ buttonType }) =>
    buttonType === TutorialButtonTypeEnum.ICON &&
    css`
      display: flex;
      border-radius: 50%;
      padding: 0;
      width: 44px;
      height: 44px;
      justify-content: center;
      align-items: center;
    `}

  ${({ buttonType }) =>
    buttonType === TutorialButtonTypeEnum.DEFAULT &&
    css`
      font-size: 0.88875em;
      font-family: 'Roboto Medium' !important;
      height: 36px;
      padding: 0 16px;
      border: 0;
      color: #b79266;
      max-width: 430px;

      &:hover {
        border-radius: 3px;
      }
    `}

    ${({ hasLeftButton }) =>
    hasLeftButton &&
    css`
      margin-left: 8px;
    `}
`;
