import React from "react";

export  const skeletonCustomSelect = (
    <div className="mp-custom-select-skeleton">
      <small></small>
    </div>
)
export  const skeletonCustomSelectComLabel = (
    <div className="mp-custom-select-skeleton">
      <span></span>
      <small></small>
    </div>
)