export enum ButtonThemeEnum {
  CONTAINED = ' mp-btn-contained',
  FILTER = ' mp-btn-filter',
  DEFAULT = '',
  INFO = ' info',
  SUCCESS = ' success',
  DANGER = ' danger',
  WARNING =  ' warning',
  LIGHT = ' light'

}
