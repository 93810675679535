import React, { useState } from 'react'
import { Column } from '../../Table'
import { ButtonSizeEnum, ButtonTypeEnum, CoresEnum, TamanhoIconeEnum } from '../../../enums'
import { mdiClose, mdiFileDocument, mdiFileKey, mdiFileSign, mdiPencil } from '@mdi/js'
import { formatBytes } from '../../../utils/file-utils'
import { MpFileRename, MpFileRenameProps } from '../../FileRename/MpFileRename'
import { MpFileUploadItemProps } from '../MpFileUpload'
import MpDataGrid from '../../DataGrid'
import { Row } from '../../DataGrid/DataGrid'
import { MpFileUploadTableContextualActionBar } from './MpFileUploadTableContextualActionBar/MpFileUploadTableContextualActionBar'
import { MpFileUploadTableMaxFileSize } from './MpFileUploadTableMaxFileSize'
import Icone from '../../../components/icone'
import MpTypography from '../../../components/Typography'
import { ButtonProps } from '../../Buttons/Button'

export type MpFileUploadRowAction = 'sign' | 'rename' | 'remove' | 'removeSelecteds' | 'signSelecteds'

export type MpFileUploadTableProps = {
  files: MpFileUploadItemProps[]
  maximumSizeOfTheSummedFilesInMB: number
  currentTotalFileSizeInByte: number
  actions: MpFileUploadRowAction[]
  customActions?: ButtonProps[]
  isCheckable: boolean
  signButtonDisabled: boolean
  dragAndDrop?: boolean
  isLoading?: boolean
  afterDrop?: (files: MpFileUploadItemProps[]) => void
  onSign: (file: MpFileUploadItemProps) => void
  onSignSelecteds: (files: MpFileUploadItemProps[]) => void
  onRemove: (updatedFiles: MpFileUploadItemProps[]) => void
  onRemoveSelecteds: (files: MpFileUploadItemProps[]) => void
  onCloseContextualActionBar: (files: MpFileUploadItemProps[]) => void
  handleSelectFiles: (files: MpFileUploadItemProps[]) => void
  handleFileWithEditedName?: (fileWithEditedName: MpFileUploadItemProps) => void
  widthActionsColumn?: number
}

export const MpFileUploadTable: React.FC<MpFileUploadTableProps> = (props: MpFileUploadTableProps) => {
  if (!props.files?.length) {
    return <></>
  }
  const {
    files,
    currentTotalFileSizeInByte,
    onRemove,
    onRemoveSelecteds,
    onSignSelecteds,
    handleFileWithEditedName,
    onSign,
    actions,
    handleSelectFiles,
    onCloseContextualActionBar,
    maximumSizeOfTheSummedFilesInMB,
    signButtonDisabled,
    dragAndDrop,
    afterDrop,
    isLoading
  } = props
  const [fileEdition, setFileEdition] = useState<MpFileRenameProps>()
  const DEFAULT_BUTTON_PROPS = { buttonType: ButtonTypeEnum.ICON, buttonSize: ButtonSizeEnum.SMALL }
  const REMOVE_BUTTON = { ...DEFAULT_BUTTON_PROPS, iconPath: mdiClose }
  const SIGN_BUTTON = { ...DEFAULT_BUTTON_PROPS, iconPath: mdiFileSign }
  const RENAME_BUTTON = { ...DEFAULT_BUTTON_PROPS, iconPath: mdiPencil }

  const COLUMNS_HEADER: Column[] = [
    { content: '', isOrdenable: false, width: '52px' }, // Para exibição do icone.
    { content: 'Nome do arquivo', isOrdenable: false },
    { content: 'Tamanho', width: '125px', isOrdenable: false }
  ]

  const COLUMNS_FOOTER: Column[] = [
    { content: '' },
    { content: '' },
    {
      content: (
        <MpFileUploadTableMaxFileSize
          maximumSizeOfTheSummedFilesInMB={maximumSizeOfTheSummedFilesInMB}
          currentTotalFileSizeInByte={currentTotalFileSizeInByte} />
      )
    }
  ]

  const hideFileEdition = (): void => {
    setFileEdition({ show: false, currentName: '', onClose: () => { }, onSave: () => { } })
  }

  const showFileEdition = (file: MpFileUploadItemProps): void => {
    setFileEdition({
      show: true,
      currentName: file.fileName as string,
      onSave: (editedFileName: string) => {
        handleFileWithEditedName({ ...file, fileName: editedFileName })
      },
      onClose: () => {
        hideFileEdition()
      }
    })
  }

  const handleRowSelected = ({ id, isSelected }): void => {
    const result = files?.map(fileInRow => {
      if (getFileId(fileInRow) === id?.toString()) {
        fileInRow.isSelected = isSelected
      }
      return fileInRow
    }
    )
    handleSelectFiles(result)
  }

  const check = (file): MpFileUploadItemProps => {
    file.isSelected = true
    return file
  }

  const uncheck = (file): MpFileUploadItemProps => {
    file.isSelected = false
    return file
  }

  const handleSelectAll = (isAllSelected): void => {
    if (!isAllSelected) {
      handleSelectFiles(files?.map(check))
    } else {
      handleSelectFiles(files?.map(uncheck))
    }
  }

  const handleDragAndDrop = (rows: Row[]): void => {
    afterDrop(rows?.map(row => row.metadata))
  }

  const getFileId = (file: MpFileUploadItemProps) => {
    return file?.id?.toString() || file?.tempId?.toString()
  }

  const toRows = (): Row[] => {
    return files?.map((file: MpFileUploadItemProps, index) => ({
      id: getFileId(file) || `file-upload-row-id-${index}`,
      isSelected: file.isSelected,
      buttons: [
        ...props.customActions?.map((action) => ({
          ...action,
          handleClick: () => {
            action.handleClick(file)
          }
        })),
        {
          ...SIGN_BUTTON,
          tooltipText: 'Assinar',
          id: `mp-file-upload-table-sign-item-${file.fileName as string}`,
          handleClick: () => {
            onSign(file)
          },
          isDisabled: signButtonDisabled,
          hide: !actions?.includes('sign')
        },
        {
          ...RENAME_BUTTON,
          tooltipText: 'Renomear',
          id: `mp-file-upload-table-rename-item-${file.fileName as string}`,
          handleClick: () => showFileEdition(file),
          hide: !actions?.includes('rename')
        },
        {
          ...REMOVE_BUTTON,
          handleClick: () => {
            onRemove(files.filter(line => getFileId(line) !== getFileId(file)))
          },
          tooltipText: 'Remover',
          id: `mp-file-upload-table-remove-item-${file.fileName as string}`,
          hide: !actions?.includes('remove')
        }
      ],
      content: [
        { data: <Icone iconPath={mdiFileDocument} iconSize={TamanhoIconeEnum.SMALL} /> },
        { data: <MpTypography color={CoresEnum.BLACK} spacing={{ mt: 1 }} isTruncate variant='heading-6' bold id={`mp-file-upload-file-name-${file.fileName as string}`}>{file.fileName as string}</MpTypography> },
        { data: formatBytes(file.fileSizeInBytes, true) }
      ],
      metadata: file
    }))
  }

  return (
    <>
      <MpFileUploadTableContextualActionBar
        files={files}
        signButtonDisabled={signButtonDisabled}
        actions={actions}
        onSignSelecteds={onSignSelecteds}
        onRemoveSelecteds={onRemoveSelecteds}
        onClose={onCloseContextualActionBar} />
      <MpFileRename {...fileEdition} />
      <MpDataGrid
        widthActionsColumn={props?.widthActionsColumn}
        isLoading={isLoading && isLoading}
        dragAndDrop={dragAndDrop}
        afterDrop={handleDragAndDrop}
        rows={toRows()}
        onSelect={props.isCheckable && handleRowSelected}
        onSelectAll={props.isCheckable && handleSelectAll}
        header={{ columns: COLUMNS_HEADER }}
        footer={{ columns: COLUMNS_FOOTER }}>
      </MpDataGrid>
    </>
  )
}
