import {
  MpGrid,
  MpGridItem,
  Button,
  ButtonThemeEnum,
  ButtonGroup,
  NotificationTypeEnum,
  emit,
  Toaster,
} from 'mp-react-components'
import React, { useState } from 'react'
import { DetalhamentoSolicitacao } from './components/DetalhamentoSolicitacao'
import { Formik, Form, FormikHelpers } from 'formik'
import { TipoSolicitacao } from './components/TipoSolicitacao'
import { Extrajudicial } from './components/Extrajudicial'
import Service from '../../service'
import * as Yup from 'yup'
import './style.scss'
import { ElaboracaoQuesitos } from './components/ElababoracaoQuesitos'
import { NomeacaoTecnico } from './components/NomeacaoTecnico'
import { OutrosServicos } from './components/OutrosServicos'
import { initialValues } from './utils'

export const AnaliseTecnica: React.FC = () => {
  const [errorValid, setErrorValid] = useState('')

  const schemaSat = Yup.object().shape({
    tipoFonteProcesso: Yup.string().when('complAltSolicAnt', {
      is: (complAltSolicAnt: boolean) => !complAltSolicAnt,
      then: Yup.string().required('A fonte do processo é obrigatória'),
      otherwise: Yup.string().notRequired(),
    }),
    numeroProcesso: Yup.string().required('O número do processo originário é obrigatório'),
    ementa: Yup.string().required('A ementa é obrigatória'),
    tipoSolicitacao: Yup.object().shape({
      tipoSolicitacao: Yup.string().required('O tipo de solicitação é obrigatório'),
      justAusenciaCompl: Yup.string().when(['analiseTecnicaPrevia', 'tipoSolicitacao'], {
        is: (analiseTecnicaPrevia: boolean, tipoSolicitacao: string) =>
          !analiseTecnicaPrevia && tipoSolicitacao === 'PEAT',
        then: Yup.string().required(
          'A justificativa para ausência de complementaridade é obrigatória',
        ),
        otherwise: Yup.string().notRequired(),
      }),
      descServSolicDuviTec: Yup.string().when('tipoSolicitacao', {
        is: (tipoSolicitacao: string) => tipoSolicitacao === 'PEAT',
        then: Yup.string().required(
          'A descrição do serviço técnico solicitado e dúvida técnica é obrigatória',
        ),
        otherwise: Yup.string().notRequired(),
      }),
      esbocoQuisito: Yup.string().when('tipoSolicitacao', {
        is: (tipoSolicitacao: string) => tipoSolicitacao === 'AJAEQ',
        then: Yup.string().required('O esboço de quesitos é obrigatório'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
  })

  const handleSubmit = (values: any, { resetForm }: FormikHelpers<any>) => {
    if (!errorValid) {
      Service.postService('/sat', values)
        .then(() => {
          resetForm()
          emit('SAT enviada com sucesso', NotificationTypeEnum.SUCCESS)
        })
        .catch(() => {
          emit('Falha ao enviar SAT', NotificationTypeEnum.DANGER)
        })
    }
  }
  return (
    <div className='content'>
      <Toaster />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        validationSchema={schemaSat}
      >
        {() => (
          <Form>
            <MpGrid spacing={{ mt: 10 }}>
              <MpGridItem xl={12} lg={12} md={12}>
                <DetalhamentoSolicitacao setErrorValid={setErrorValid} errorValid={errorValid} />
              </MpGridItem>
              <MpGridItem xl={12} lg={12} md={12}>
                <TipoSolicitacao />
              </MpGridItem>
              <MpGridItem xl={12} lg={12} md={12}>
                <Extrajudicial />
                <ElaboracaoQuesitos />
                <NomeacaoTecnico />
                <OutrosServicos />
              </MpGridItem>
            </MpGrid>
            <MpGrid>
              <MpGridItem xl={1}>
                <ButtonGroup cssClass='button-align'>
                  <Button buttonText='Enviar' buttonTheme={ButtonThemeEnum.CONTAINED} />
                </ButtonGroup>
              </MpGridItem>
            </MpGrid>
          </Form>
        )}
      </Formik>
    </div>
  )
}
