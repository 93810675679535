import React from 'react'
import { FileRejection } from 'react-dropzone'
import './MpFileUpload.scss'
import MpTypography from '../Typography'
import CustomModal from '../Modal'
import { CoresEnum, TamanhoIconeEnum, TamanhoModalEnum } from '../../enums'
import MpList, { MpListItemContent, MpListItemIconLeftArea, MpListItemSimple } from '../List'
import { MpFileUploadErrosMap } from '../../enums/FileUploadErrors'
import Icone from '../icone'
import { mdiCircleSmall, mdiCloseCircleOutline } from '@mdi/js'

export type Props = {
  uploadErrors: FileRejection[]
  onClose: () => void
}

export const MpFileUploadModalError: React.FC<Props> = (props: Props) => {
  const { uploadErrors } = props

  return (
    <CustomModal
      title='Upload de Arquivos: Resultado'
      size={TamanhoModalEnum.MEDIUM}
      onClose={props.onClose}
      footerButtons={[{ buttonText: 'FECHAR', id: 'mp-file-upload-modal-error-close-button', handleClick: props.onClose }]}
      show={uploadErrors?.length > 0}>
      <div className='mp-file-upload-error-list'>
        <div className='mp-file-upload-default-error-message'>
          <Icone iconPath={mdiCloseCircleOutline} iconSize={TamanhoIconeEnum.SMALL} iconColor={CoresEnum.DANGER} />
          <MpTypography
            bold
            element='span'
            color={CoresEnum.DANGER}
            id={'mp-file-upload-error-title'}>Impedimentos ao anexar arquivos</MpTypography>
        </div>
        <MpList isRoot>
          {
            uploadErrors.map((e) => (
              <span key={`mp-file-upload-item-error-${e?.file?.name}`}>
                {
                  e.errors.map(({ code }) => (
                    <MpListItemSimple
                      isMultiline
                      key={`mp-file-upload-error-key-${e?.file?.lastModified}-${code}'`}>
                      <MpListItemIconLeftArea iconPath={mdiCircleSmall} />
                      <MpListItemContent>
                        <MpTypography element='span' key={`mp-file-upload-error-message-${e?.file?.lastModified}-${code}`} id={`mp-file-upload-error-message-${e?.file?.lastModified}-${code}`}>
                          {` ${e?.file?.name ? 'Arquivo ' + e.file.name + ':' : ''} ${MpFileUploadErrosMap[code] as string}`}
                        </MpTypography>
                      </MpListItemContent>
                    </MpListItemSimple>
                  ))
                }
              </span>
            ))
          }
        </MpList>
      </div >
    </CustomModal>

  )
}
