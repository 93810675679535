/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import { CustomDatepicker, MpFileUpload, MpGrid, MpGridItem, Radio } from 'mp-react-components'
import { useFormikContext, Field } from 'formik'
import { FormValues } from '../../types'
import CustomSelect from 'components/CustomSelect'
import './style.scss'
import { useHandleFiles } from 'pages/AnaliseTecnica/utils'

const optionsSelect = [
  {
    label: 'Idoso',
    value: 'Idoso',
  },
  {
    label: 'Criança Adolescente',
    value: 'Criança Adolescente',
  },
  {
    label: 'Pressoa com deficiência',
    value: 'Pressoa com deficiência',
  },
  {
    label: 'Preclusão',
    value: 'Preclusão',
  },
  {
    label: 'Outros',
    value: 'Outros',
  },
]

export const AnalisePrioritaria: React.FC = () => {
  const [date, setDate] = useState('')

  const { changeFiles } = useHandleFiles()

  const formik = useFormikContext<FormValues>()

  return (
    <>
      <div className='container-analise-prioritaria'>
        <div className='content-analise-prioritaria'>
          <p>Análise Prioritária?</p>
          <div className='box-radio-select'>
            <Field name='tipoSolicitacao.analisePrioritaria'>
              {({ form, field }: any) => (
                <>
                  <Radio
                    group='analisePrioritaria'
                    id='1'
                    label='Não'
                    onCheck={(e: any) => {
                      form.setFieldValue(field.name, JSON.parse(e))
                    }}
                    // @ts-ignore
                    value={false}
                    checked={!field.value}
                  />

                  <Radio
                    group='analisePrioritaria'
                    id='2'
                    label='Sim'
                    onCheck={(e: any) => {
                      form.setFieldValue(field.name, e)
                    }}
                    checked={field.value}
                    // @ts-ignore
                    value={true}
                  />
                </>
              )}
            </Field>

            {formik.values.tipoSolicitacao.analisePrioritaria && (
              <div className='box-select'>
                <Field name='tipoSolicitacao.analisePrioritariaDesc'>
                  {({ field, form }: any) => (
                    <CustomSelect
                      value={field.label}
                      onChange={(e: any) => {
                        form.setFieldValue(field.name, e.label)
                      }}
                      options={optionsSelect}
                    />
                  )}
                </Field>
              </div>
            )}
          </div>
        </div>
        {formik.values.tipoSolicitacao.analisePrioritaria && (
          <div className='content-analise-prioritaria'>
            <p>Data limite para conclusão da análise</p>
            <div className='box-radio-select'>
              <Field name='tipoSolicitacao.prazoMaximoAnalise'>
                {({ form, field }: any) => (
                  <>
                    <Radio
                      group='prazoMaximoAnalise'
                      id='1'
                      label='Sem prazo'
                      onCheck={(e: any) => {
                        form.setFieldValue(field.name, e)
                      }}
                      // @ts-ignore
                      value={'Sem prazo'}
                      checked={field.value === 'Sem prazo'}
                    />
                    <Radio
                      group='prazoMaximoAnalise'
                      id='2'
                      label='Com prazo'
                      onCheck={(e: any) => {
                        form.setFieldValue(field.name, e)
                      }}
                      checked={field.value !== 'Sem prazo'}
                      // @ts-ignore
                      value=''
                    />
                    <CustomDatepicker
                      placeholder='DD/MM/YY'
                      value={date}
                      handleChange={(e: any) => {
                        setDate(e)
                        form.setFieldValue(field.name, e)
                      }}
                    />
                  </>
                )}
              </Field>
            </div>
          </div>
        )}
      </div>
      {formik.values.tipoSolicitacao.analisePrioritaria && (
        <>
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <Field name='tipoSolicitacao.descOutraJust'>
                {({ field }: any) => (
                  <>
                    <div className='container-textarea'>
                      <label>Descreva a outra justificativa</label>
                      <textarea {...field} placeholder='Digite seu texto aqui.' />
                    </div>
                  </>
                )}
              </Field>
            </MpGridItem>
          </MpGrid>
          <MpGrid>
            <MpGridItem xl={12} lg={12} md={12}>
              <MpFileUpload
                actions={['remove']}
                label='Documentação de comprovação'
                onUploadSuccess={(e) => changeFiles(e)}
                placeholder='Clique aqui ou arraste para anexar um arquivo'
                signButtonDisabledDelay={2000}
              />
            </MpGridItem>
          </MpGrid>
        </>
      )}
    </>
  )
}
