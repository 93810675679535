import React from 'react'
import { CoresEnum, TamanhoIconeEnum, TooltipPlacementEnum } from '../../enums'
import { MimeType } from '../../domain/MimeType'

import './file-icon.scss'
import Icone from '../icone'
import { mdiDrawing, mdiEqualizer, mdiFileDocumentOutline, mdiMusic, mdiPresentation, mdiVideoOutline, mdiZipBoxOutline } from '@mdi/js'
import CustomTooltip from '../../components/CustomTooltip'
export interface FileIconProps {
  mimeType? : MimeType
  size? : TamanhoIconeEnum
  disabled?: boolean
  placement?: TooltipPlacementEnum
  isMp?: boolean

}

interface IconFileProps {
  icon: string

}

const MIME_TYPE_ICON_MAP: { [key in MimeType]: IconFileProps } = {
  'text/html': { icon: mdiFileDocumentOutline },
  'application/pdf': { icon: mdiFileDocumentOutline },
  'application/rtf': { icon: mdiFileDocumentOutline },
  'application/msword': { icon: mdiFileDocumentOutline },
  'image/png': { icon: mdiDrawing },
  'image/jpeg': { icon: mdiDrawing },
  'audio/video': { icon: mdiVideoOutline },
  'video/mpeg': { icon: mdiVideoOutline },
  'video/mp4': { icon: mdiVideoOutline },
  'video/quicktime': { icon: mdiVideoOutline },
  'audio/ogg': { icon: mdiMusic },
  'audio/mpeg': { icon: mdiMusic },
  'application/octet-stream': { icon: mdiZipBoxOutline },
  'application/zip': { icon: mdiZipBoxOutline },
  'application/x-rar-compressed': { icon: mdiZipBoxOutline },
  'application/vnd.ms-excel': { icon: mdiEqualizer },
  'application/vnd.ms-powerpoint': { icon: mdiPresentation }
}

export const FileIcon: React.FC<FileIconProps> = (props: FileIconProps) => {
  return (
    <CustomTooltip cssClassName={`mp-file-icon ${props.isMp ? 'mp-icon-is-mp' : ''}`} text= {props.mimeType} placement={props.placement ? props.placement : TooltipPlacementEnum.TOP}>
      <Icone
        iconPath={MIME_TYPE_ICON_MAP[props.mimeType].icon}
        iconColor={props.disabled ? CoresEnum.GRAY_200 : CoresEnum.GRAY_700}
        iconSize={props.size ? props.size : TamanhoIconeEnum.SMALL}
      />
    </CustomTooltip>
  )
}
