import React, { useRef } from 'react'
import CustomTooltip from '../components/CustomTooltip'
import { TooltipPlacementEnum } from '../enums'

export interface LimitedTextContentProps {
  children: string
  maxLength?: number
  alwaysShowTooltip?: boolean
  noTruncate?: boolean
  toolTipPlacement?: TooltipPlacementEnum
  tooltipText? : string

}

export const LimitedTextContent: React.FC<LimitedTextContentProps> = (props: LimitedTextContentProps) => {
  const ref = useRef(null)

  const getCroppedText = (): any => props.noTruncate ? props.children.substring(0, props.maxLength) : props.children.substring(0, props.maxLength).concat('...')

  const displayText = props.children && props.children.length > props.maxLength ? getCroppedText() : props.children
  return (
    <>
      {props.children && (props.alwaysShowTooltip || (props.children.length > props.maxLength || (ref.current?.scrollWidth > ref.current?.offsetWidth)))
        ? (
          <>
            <CustomTooltip cssClassName='full-container' text={props.tooltipText ? props.tooltipText : props.children} placement={props.toolTipPlacement || TooltipPlacementEnum.TOP} >
              <div
                ref={ref} data-testid="com-tooltip" className='truncate-text'>
                {displayText}
              </div>
            </CustomTooltip>
          </>
        ) : (
          <span
            style={{ width: '100%' }}><span ref={ref} data-testid="sem-tooltip" className='truncate-text' style={{ width: '100%' }} >
              {displayText}
            </span></span>
        )}
    </>
  )
}
