import React, { useEffect, useContext, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import './style.scss'
import Header from '../Header'
import Sidebar, { SidebarNav } from '../Sidebar'
import {
  Button,
  ButtonTypeEnum,
  CoresEnum,
  CustomModal,
  MpTypography,
  TamanhoModalEnum,
} from 'mp-react-components'
import {
  mdiBellCircle,
  mdiAccessPointMinus,
  mdiTextSearch,
  mdiLogout,
  mdiDotsVertical,
} from '@mdi/js'
import Icon from '@mdi/react'
import { GlobalContext } from '../../context/global'
import CustomSelect from '../CustomSelect'

type Props = {
  children: React.ReactNode
}

interface PropsOrgao {
  codOrgao: string
  orgao: string
}

const LayoutDefault: React.FC<Props> = ({ children }: Props) => {
  const [responseUser, setResponseUser] = useState<any>()
  const [openMenuUser, setOpenMenuUser] = useState(false)
  const [options, setOptions] = useState([{ value: '', label: '' }])
  const [orgao, setOrgao] = useState<any>({ value: '', label: '' })
  const [orgaoUpdate, setOrgaoUpdate] = useState<any>()
  const [showNotification, setShowNotification] = useState(false)

  const { setShow, setUser, setPassword, show } = useContext(GlobalContext)

  const navigate = useNavigate()

  useEffect(() => {
    const localStorageUser = localStorage.getItem('login-MPRJ')
    if (localStorageUser) {
      setResponseUser(JSON.parse(localStorageUser))
    }
  }, [show])

  useEffect(() => {
    const localStorageOrgao = localStorage.getItem('orgao')
    const optionsOrgao = responseUser?.orgaos?.map((item: PropsOrgao) => {
      return {
        label: item.orgao,
        value: item.codOrgao,
      }
    })
    setOptions(optionsOrgao)
    setOrgao(localStorageOrgao ? JSON.parse(localStorageOrgao) : optionsOrgao && optionsOrgao[0])
  }, [responseUser])

  const sidebarItems: SidebarNav[] = [
    {
      id: 1,
      title: 'Análise Técnica',
      path: '/analise-tecnica',
      icon: mdiAccessPointMinus,
    },
    {
      id: 2,
      title: 'Consultar Análise Técnica',
      path: '/consultar-analise-tecnica',
      icon: mdiTextSearch,
    },
  ]

  const initialsLetterName = useMemo(() => {
    if (responseUser) {
      const splittedName = responseUser.nome?.toUpperCase().split(' ') || []
      const primaryLetter = splittedName[0]?.charAt(0) || ''
      const secondaryLetter = splittedName.pop()?.charAt(0) || ''
      return primaryLetter + secondaryLetter
    }
  }, [responseUser])

  const changeOrgao = (orgao: any) => {
    setOrgaoUpdate(orgao)
    setShowNotification(true)
    setOpenMenuUser(false)
  }

  const confirmOrgaoChange = () => {
    setShowNotification(false)
    setOrgao(orgaoUpdate)
    localStorage.setItem('orgao', JSON.stringify(orgaoUpdate))
    navigate('/')
  }

  const args = {
    footerButtons: [
      {
        id: 'A',
        buttonText: 'Confirmar',
        buttonType: ButtonTypeEnum.OUTLINE,
        handleClick: () => confirmOrgaoChange(),
      },
      {
        id: 'A',
        buttonText: 'Cancelar',
        buttonType: ButtonTypeEnum.OUTLINE,
        handleClick: () => setShowNotification(false),
      },
    ],
  }

  return (
    <>
      <CustomModal
        show={showNotification}
        size={TamanhoModalEnum.XSMALL}
        title='Alteração de Orgão'
        {...args}
      >
        <p className='modal-orgao'>
          Você realmente deseja alterar o órgão, todos os dados já salvos no site serão perdidos
        </p>
      </CustomModal>
      <section>
        <Sidebar logoText='MPRJ | PRODATA' items={sidebarItems} />
        <Header
          headerTitleProps={{
            logoText: 'MPRJ | PRODATA',
            href: '/',
          }}
        >
          <div className='orgao'>
            <MpTypography color={CoresEnum.WHITE} id='1' variant='small' element='small'>
              {orgao?.value}
            </MpTypography>
          </div>
          <hr className='mp-divider mp-divider-vertical mt-0a ml-0 mr-1 mb-0a' />
          <Button buttonType={ButtonTypeEnum.ICON} iconPath={mdiBellCircle} tooltipText='text' />

          <MpTypography
            isTruncate
            maxLength={15}
            color={CoresEnum.BLACK}
            id='2'
            spacing={{ mr: 1 }}
            variant='small'
            element='small'
          >
            {responseUser?.nome}
          </MpTypography>
          <div className='avatar'>
            <p>{initialsLetterName}</p>
          </div>
          <Button
            buttonType={ButtonTypeEnum.ICON}
            iconPath={mdiDotsVertical}
            handleClick={() => setOpenMenuUser(!openMenuUser)}
          />
          {openMenuUser && (
            <div className='container-menu'>
              <div className='box-menu'>
                <div className='menu-content'>
                  <div className='avatar-large'>
                    <p>{initialsLetterName}</p>
                  </div>
                  <p className='name'>{responseUser?.nome}</p>
                  <p className='mail'>{responseUser?.mail}</p>
                  <p className='orgao-title'>{orgao.label}</p>
                  <hr className='line mp-divider mp-divider-horizontal' />
                  <CustomSelect
                    options={options}
                    label='Órgão solicitante'
                    onChange={(e) => {
                      changeOrgao(e)
                    }}
                    value={orgao}
                  />
                  <div
                    className='logout'
                    onClick={() => {
                      localStorage.removeItem('login-MPRJ')
                      setPassword('')
                      setUser('')
                      setShow(true)
                      setOpenMenuUser(false)
                      navigate('/')
                    }}
                  >
                    <Icon path={mdiLogout} size={1} color={'#B79266'} />
                    <p>Encerar sessão</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Header>
      </section>
      <div className='container'>{children}</div>
    </>
  )
}

export default LayoutDefault
