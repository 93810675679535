import React, { ReactNode } from 'react'
import Icon from '@mdi/react'
import { mdiChevronDown, mdiEmail, mdiEmailOpen } from '@mdi/js'
import Chip, { ChipProps } from '../chip'
import Icone from '../icone'
import Checkbox from '../Checkbox'
import { Button } from '../Buttons/Button'
import AvatarGroup from '../AvatarGroup'
import Avatar, { AvatarProps } from '../avatar'
import Clipboard from '../Clipboard'

import CustomTooltip from '../CustomTooltip'
import { DataIcone } from '../../domain/DataIcone'

import {
  TamanhoIconeEnum,
  ButtonTypeEnum,
  TooltipPlacementEnum,
  ChipSizeEnum,
  TipoAvatarEnum,
  CoresEnum,
  OrigemEnum,
  SelectorTypeEnum,
  ClipboardTypeEnum
} from '../../enums/'
import {
  ActionsProps,
  ContentProps,
  FooterProps,
  HeaderProps
} from './card.interfaces'
import CardBasico from '../CardBasico'

import './card.scss'
import Radio from '../../components/Radio'
import { Link } from 'react-router-dom'

export interface CardProps {
  id: string
  title?: string
  isLoading?: boolean
  avatarTooltipText?: string
  avatarContent?: string
  status?: CardStatusProps
  tooltipStatus?: string
  dateContentLeft?: DataIcone
  dateContentRight?: DataIcone
  contentText?: string[]
  contentFooter?: ChipProps[]
  avatarGroup?: AvatarProps[]
  isSelectable?: boolean
  selectorType?: SelectorTypeEnum
  isDisabled?: boolean
  isSelected?: boolean
  isDetailsVisible?: boolean
  notifications?: NotificationIcon[]
  notificationMessage?: string
  origin?: OrigemEnum
  originLabel?: string
  tooltipOrigin?: string
  originalNumber?: string
  disableLinkNavigation? : boolean
  link?: string
  handleCheck?: (model: CardProps) => void
  handleRadio?: (model: CardProps) => void
  handleNotificationClick?: (e: any) => void
  handleClick?: (model: CardProps) => void
}

export interface CardStatusProps {
  status: string
  cor: CoresEnum
  tooltip?: string
}

export interface NotificationProps {
  icons: NotificationIcon[]
}

export interface NotificationIcon {
  path: string
  isActive: boolean
  hint: string
  label?: string
}

interface State {
  desabilitado: boolean
  showHeaderActions: boolean
}

interface dateCard {
  dateCard: DataIcone
  dateCardIconPath: string
  IconColor?: CoresEnum
}

const ContentStatus = (props: CardStatusProps) => {
  return (
    <>
      <span style={{ color: props.cor ? props.cor.value : '' }} className={'subtitle'}>
        {props.status}
      </span>
    </>
  )
}
interface LinkProps {
  link?: string
  disableLinkNavigation?: boolean
  children: ReactNode
}
export const LinkCard = (props: LinkProps): any => {
  let returnedLink: ReactNode = props.children

  if (props.link) {
    if (!props.disableLinkNavigation) { returnedLink = <Link to={props.link}>{props.children}</Link> }
  }
  return (
    <>
      { returnedLink }
    </>
  )
}

export const ContentDate = (props: dateCard) => {
  return (
    <>
      <div className="mp-card-data-icone">
        <Icone
          iconPath={props.dateCardIconPath}
          iconSize={TamanhoIconeEnum.SMALL}
          iconColor={props.IconColor}
        />
      </div>
      <div className="mp-card-data-item-text">
        <span className="data-text">
          {props.dateCard.data}
        </span>
      </div>
    </>
  )
}
const Header = (props: HeaderProps) => {
  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }
  const selector = props.selectorType === SelectorTypeEnum.RADIO
    ? <Radio id={props.id} group={'mp-cards'} onCheck={props.onRadioClicked} value={props.id} checked={props.isSelected}/>
    : <Checkbox id={props.id} onChange={props.onCheckboxClicked} isChecked={props.isSelected} isDisabled={props.isDisabled} />

  return (
    <div className="mp-card-procedure-header" >
      {props.avatarContent && (
        <div className="mp-card-header-area-1">
          <Avatar
            tipo={TipoAvatarEnum.Padrao}
            tooltipText={props.avatarTooltipText}
            conteudo={props.avatarContent.substring(0, 3)}
          ></Avatar>
        </div>
      )}

      <div className="mp-card-header-area-2">

        <div className="mp-card-titulo" onClick={stopPropagation} >

          <h6 aria-disabled className="heading-6 mp-no-click">
            {props.title}
          </h6>

          <div
            className="mp-card-header-actions" style={{ visibility: props.showHeaderActions ? 'visible' : 'hidden' }}>
            <Clipboard noLabel type={ClipboardTypeEnum.ICON} text={props.title} />

            {props.isSelectable && selector}
          </div>

        </div>

        <div className="mp-card-area-status-procedencia">

          <div className="mp-card-status">
            <CardStatus {...props.status} tooltip={props.tooltipStatus} />
          </div>
          {
            props.origin && Object.keys(props.origin).length !== 0 && (
              <div className="mp-card-procedencia">
                <Chip color={CoresEnum.WHITE} alwaysShowTooltip tooltipText={props.tooltipOrigin} size={ChipSizeEnum.XSMALL} text={props.origin} />
              </div>)
          }
        </div>

        {props.originalNumber &&
        <CustomTooltip placement={TooltipPlacementEnum.BOTTOM} text="Número originário">
          <div className="mp-card-origin">
            <span>{props.originLabel ? props.originLabel : 'O'}</span>
            <p>{props.originalNumber}</p>
          </div>
        </CustomTooltip>
        }
      </div>
    </div>

  )
}

export const CardStatus = (props: CardStatusProps) => {
  return (
    <CustomTooltip text={props.tooltip} placement={TooltipPlacementEnum.TOP}>
      <ContentStatus {...props} />
    </CustomTooltip>
  )
}

export const Notification: React.FC<NotificationProps> = (props: NotificationProps) => {
  return (
    <>
      <div className="mp-card-notification">
        <ul>
          {props.icons.map((icon, index) => (
            <li key={index} className={` ${icon.isActive ? 'active' : ''}`}>
              <CustomTooltip key={index} placement={TooltipPlacementEnum.BOTTOM} text={icon.hint}>
                <div className="icon-content">
                  <Icon path={icon.path}></Icon>
                </div>
              </CustomTooltip>
              <div className="text-content">{icon.label}</div>
            </li>
          ))}
        </ul>
      </div>

    </>
  )
}

const Content = (props: ContentProps) => {
  const onNotificationClick = (event) => {
    event.stopPropagation()

    if (props.handleNotificationClick) {
      props.handleNotificationClick(props)
    }
  }
  return (
    <div className="mp-card-procedure-content">
      {props.avatarGroup && props.avatarGroup.length > 0 && (
        <div className="mp-card-conteudo-area-1">

          <AvatarGroup itens={props.avatarGroup} />
          {props.notificationMessage && (
            <div className="mp-card-notification-area" data-testid="notification" onClick={onNotificationClick} >
              <Avatar
                tipo={TipoAvatarEnum.Sigla}
                tooltipText={props.notificationMessage}
                conteudo="!"
              />
            </div>
          )}

        </div>
      )}
      {props.dateContentLeft &&
        (Object.keys(props.dateContentLeft).length !== 0 ||
          Object.keys(props.dateContentRight).length !== 0) && (
        <div className="mp-card-conteudo-area-2">
          <div className="mp-card-data">

            {props.dateContentLeft && Object.keys(props.dateContentLeft).length !== 0 && (
              <div className="mp-card-data-item">
                <CustomTooltip text={props.dateContentLeft.tooltip} placement={TooltipPlacementEnum.TOP}>
                  <ContentDate dateCard={props.dateContentLeft} dateCardIconPath={props.dateContentLeft.icon ?? mdiEmail} IconColor={props.dateContentLeft.iconColor} />
                </CustomTooltip>

              </div>
            )}

            {props.dateContentRight && Object.keys(props.dateContentRight).length !== 0 && (
              <div className="mp-card-data-item">
                <CustomTooltip text={props.dateContentRight.tooltip} placement={TooltipPlacementEnum.TOP}>
                  <ContentDate dateCard={props.dateContentRight} dateCardIconPath={props.dateContentRight.icon ?? mdiEmailOpen} IconColor={props.dateContentRight.iconColor} />
                </CustomTooltip>

              </div>
            )}
          </div>
        </div>
      )}
      {props.contentText && props.contentText.length > 0 && (
        <div className="mp-card-conteudo-area-3">
          {props.contentText?.map((conteudo, index) => (

            <p className="mp-card-text" key={index}>
              <CustomTooltip text={conteudo} placement={TooltipPlacementEnum.LEFT}>
                {conteudo}
              </CustomTooltip>

            </p>
          ))}
        </div>
      )}
      {props.notifications && <div className="mp-card-conteudo-area-4">
        <Notification icons={props.notifications} />
      </div>}

    </div>
  )
}

const Footer = (props: FooterProps) => {
  return (
    <>
      {props.contentFooter && props.contentFooter.length > 0 && (
        <div className="mp-card-procedure-footer">
          {props.contentFooter.map((etiqueta, index) => (
            <div key={index} className="mp-card-chip">
              <Chip text={etiqueta.text} />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

const Actions = (props: ActionsProps) => {
  return (
    <>
      {props.isDetailsVisible && (
        <div className="mp-card-procedure-actions">
          <div className="mp-card-actions-button">
            <Button
              isDisabled={props.isDisabled}
              iconPath={mdiChevronDown}
              buttonType={ButtonTypeEnum.ICON}
            />
          </div>
        </div>
      )}
    </>
  )
}

export class Card extends React.Component<CardProps, State> {
  constructor (props: Readonly<CardProps>) {
    super(props)

    this.state = {
      desabilitado: props.isDisabled,
      showHeaderActions: false
    }

    this.onCheckboxClicked = this.onCheckboxClicked.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onCheckboxClicked = (event: any): void => {
    if (this.state.desabilitado) return

    if (event?.stopPropagation) event.stopPropagation()

    if (this.props.handleCheck) {
      this.props.handleCheck(this.props)
    }
  }

  onRadioClicked = (event: any): void => {
    if (this.state.desabilitado) return

    if (event?.stopPropagation) event.stopPropagation()

    if (this.props.handleRadio) {
      this.props.handleRadio(this.props)
    }
  }

  onClick = (): void => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props)
    }
  }

  render () {
    return (
      <LinkCard link={this.props.link} disableLinkNavigation={this.props.disableLinkNavigation} >
        <CardBasico id={'card_' + this.props.id}
          hideFooter hideHeader
          isLoading={this.props.isLoading}
          isDisabled={this.state.desabilitado}
          isSelected={this.props.isSelected}
          handleClick={this.onClick}
          loadingMode='cards'
          cssClass='mp-card-procedure'
          mouseEnter={() => this.setState({ showHeaderActions: true })}
          mouseLeave={() => this.setState({ showHeaderActions: false })}
        >
          <Header
            id={this.props.id}
            avatarContent={this.props.avatarContent}
            avatarTooltipText={this.props.avatarTooltipText}
            status={this.props.status}
            tooltipStatus={this.props.tooltipStatus}
            title={this.props.title}
            isSelectable={this.props.isSelectable}
            selectorType={this.props.selectorType}
            isSelected={this.props.isSelected}
            isDisabled={this.state.desabilitado}
            origin={this.props.origin}
            tooltipOrigin={this.props.tooltipOrigin}
            originalNumber={this.props.originalNumber}
            originLabel={this.props.originLabel}
            onCheckboxClicked={this.onCheckboxClicked}
            onRadioClicked={this.onRadioClicked}
            showHeaderActions = {this.state.showHeaderActions}
          />
          <Content
            contentText={this.props.contentText}
            dateContentLeft={this.props.dateContentLeft}
            dateContentRight={this.props.dateContentRight}
            handleNotificationClick={this.props.handleNotificationClick}
            notifications={this.props.notifications}
            notificationMessage={this.props.notificationMessage}
            avatarGroup={this.props.avatarGroup}
          />
          <Footer contentFooter={this.props.contentFooter} />
          <Actions
            isDetailsVisible={this.props.isDetailsVisible}
            isDisabled={this.state.desabilitado}
          />
        </CardBasico>
      </LinkCard>
    )
  }
}

export abstract class CardStatusDataFactory {
  static readonly RESPONDIDO = {
    cor: CoresEnum.APOIO_11_DARK,
    status: 'Respondido'
  }

  static readonly RESPONDIDA = {
    cor: CoresEnum.APOIO_11_DARK,
    status: 'Respondida'
  }

  static readonly RECEBIDA = {
    cor: CoresEnum.APOIO_13_DARK,
    status: 'Recebida'
  }

  static readonly RECEBIDA_TACITA = {
    cor: CoresEnum.APOIO_1_DARK,
    status: 'Recebida Tácita'
  }

  static readonly PENDENTE = {
    cor: CoresEnum.APOIO_6_DARK,
    status: 'Pendente'
  }

  static readonly PENDENTE_SECRETARIA = {
    cor: CoresEnum.APOIO_6_DARK,
    status: 'Pendente Secretaria'
  }

  static readonly PENDENTE_PROMOTORIA = {
    cor: CoresEnum.APOIO_6_DARK,
    status: 'Pendente Promotoria'
  }

  static readonly CANCELADA = {
    cor: CoresEnum.APOIO_18_LIGHT,
    status: 'Cancelada'
  }

  static readonly INDETERMINADA = {
    cor: CoresEnum.APOIO_18_DARK,
    status: 'Indeterminada'
  }

  static readonly AGUARDANDO_ENVIO = {
    cor: CoresEnum.APOIO_2_DARK,
    status: 'Aguardando Envio'
  }

  static readonly NAO_DISTRIBUIDO = {
    cor: CoresEnum.APOIO_12_DARK,
    status: 'Não Distribuído'
  }

  static readonly VISTA_ABERTA = {
    cor: CoresEnum.APOIO_3_DARK,
    status: 'Vista Aberta'
  }

  static readonly DILIGENCIA_INTERNA = {
    cor: CoresEnum.APOIO_15_DARK,
    status: 'Diligência Interna'
  }

  static readonly BAIXADO = {
    cor: CoresEnum.APOIO_9_DARK,
    status: 'Baixado'
  }

  static readonly DENUNCIADO = {
    cor: CoresEnum.APOIO_2_DARK,
    status: 'Denunciado'
  }

  static readonly ARQUIVADO = {
    cor: CoresEnum.APOIO_17_DARK,
    status: 'Arquivado'
  }

  public static toList (): CardStatusProps[] {
    return [
      this.RESPONDIDO,
      this.RESPONDIDA,
      this.PENDENTE,
      this.PENDENTE_SECRETARIA,
      this.PENDENTE_PROMOTORIA,
      this.AGUARDANDO_ENVIO,
      this.RECEBIDA,
      this.RECEBIDA_TACITA,
      this.CANCELADA,
      this.INDETERMINADA,
      this.NAO_DISTRIBUIDO,
      this.VISTA_ABERTA,
      this.DILIGENCIA_INTERNA,
      this.BAIXADO,
      this.DENUNCIADO,
      this.ARQUIVADO
    ]
  }
}
