import React from 'react'
import { ButtonSizeEnum, ButtonThemeEnum, ButtonTypeEnum } from '../../../enums'
import { Button } from '../../Buttons/Button'

type Props = {
  id?: string
  icon: string
  handleClick: () => void
  isSelected?: boolean
  size?: ButtonSizeEnum
  tooltip?: string
}

export const MpFileTreeButton: React.FC<Props> = ({ icon, handleClick, isSelected, id, size, tooltip }: Props) => {
  return (
    <div className='mp-filetree-button'>
      <Button
        id={id}
        buttonTheme={ButtonThemeEnum.DEFAULT}
        buttonType={ButtonTypeEnum.ICON}
        buttonSize={size}
        tooltipText={tooltip}
        isSelected={isSelected}
        iconPath={icon}
        handleClick={handleClick} />
    </div>
  )
}
