import React, { ReactNode } from 'react'

import './footer.scss'

export interface FooterProps {
  children: ReactNode
  isFloat?: boolean
}

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <footer className={`mp-footer ${props.isFloat ? 'mp-footer-float' : ''}`}>
      {props.children}
    </footer>
  )
}
