import React, { ReactNode } from 'react'
import { JustifyContentEnum, FlexDirectionEnum, FlexEnum, AlignItensEnum, DisplayEnum, FlexWrapEnum } from '../../enums'
import MpSpacingProps, { getSpacing } from '../Spacing'
import './grid.scss'

export interface MpGridTemplateProps {
  children: ReactNode
  spacing?: MpSpacingProps
}

export const MpGridTemplate: React.FC<MpGridTemplateProps> = (props: MpGridTemplateProps) => {
  return (
    <div className={`mp-grid-template ${props.spacing ? getSpacing(props.spacing) : ''}`} >
      {props.children}
    </div>
  )
}

export interface MpGridProps {
  children: ReactNode
  spacing?: MpSpacingProps
}

export const MpGrid: React.FC<MpGridProps> = (props: MpGridProps) => {
  return (
    <div className={`mp-grid ${props.spacing ? getSpacing(props.spacing) : ''}`} >
      {props.children}
    </div>
  )
}

export interface MpGridFlexProps {
  children: ReactNode
  spacing?: MpSpacingProps
}

export const MpGridFlex: React.FC<MpGridFlexProps> = (props: MpGridFlexProps) => {
  return (
    <div className={`mp-grid-flex ${props.spacing ? getSpacing(props.spacing) : ''}`} data-testid="navbar">
      {props.children}
    </div>
  )
}

export interface MpGridRowProps {
  children: ReactNode
  justifyContent?: JustifyContentEnum
  spacing?: MpSpacingProps
  flexDirection?: FlexDirectionEnum
  flexWrap?: FlexWrapEnum
  alignItens?: AlignItensEnum
}

export const MpGridRow: React.FC<MpGridRowProps> = (props: MpGridRowProps) => {
  return (
    <div className={`mp-grid-row ${props.alignItens ? props.alignItens : ''} ${props.justifyContent ? props.justifyContent : ''} ${props.flexDirection ? props.flexDirection : ''} ${props.flexWrap ? props.flexWrap : ''} ${props.spacing ? getSpacing(props.spacing) : ''}`}>
      {props.children}
    </div>
  )
}

export interface MpGridItemProps {
  children: ReactNode
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  display?: DisplayEnum
  flex?: FlexEnum
  alignItens?: AlignItensEnum
  justifyContent?: JustifyContentEnum
  spacing?: MpSpacingProps
}

export const MpGridItem: React.FC<MpGridItemProps> = (props: MpGridItemProps) => {
  const cssXsClass = props.xs ? `mp-col-xs-${props.xs}` : ''
  const cssSmClass = props.sm ? `mp-col-sm-${props.sm}` : 'mp-col-sm'
  const cssMdClass = props.md ? `mp-col-md-${props.md}` : 'mp-col-md'
  const cssLgClass = props.lg ? `mp-col-lg-${props.lg}` : 'mp-col-lg'
  const cssXlClass = props.xl ? `mp-col-xl-${props.xl}` : 'mp-col-xl'

  return (
    <div className={`${cssXsClass} ${cssSmClass} ${cssMdClass} ${cssLgClass} ${cssXlClass} ${props.flex ? props.flex : ''} ${props.alignItens ? props.alignItens : ''} ${props.display ? props.display : ''} ${props.spacing ? getSpacing(props.spacing) : ''} `} >
      {props.children}
    </div>
  )
}

export default MpGrid
