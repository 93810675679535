/**
 *Spreading the provided styles into your returned object lets you extend it however you like 
 *while maintaining existing styles. Alternatively, you can omit the provided styles
 *and completely take control of the component's styles.
 https://react-select.com/styles#style-object
 */

export const customStyles = {
  loadingIndicator: () => ({
  }),
  loadingMessage: () => ({
  }),
  noOptionsMessage: () => ({
  }),
  placeholder: () => ({
  }),
  indicatorSeparator: () => ({
  }),
  input: () => ({
  }),
  option: () => ({
  }),
  control: () => ({
  }),
  singleValue: () => {
  },
  indicatorsContainer: () => {
  },
  dropdownIndicator: () => {
  },
  clearIndicator: () => {
  },
  menu: (provided) => ({
    ...provided
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  menuPortal: (provided) => ({
    ...provided
  }),
  container: (provided) => ({
    ...provided,
    zIndex:9
  }),
  valueContainer: (provided) => ({
    ...provided
  }),
  groupHeading: () => ({
    padding: '0',
    color:'#61778E',
    fontWeight:'bold', 
    fontSize:'12px',
    margin:'0',
  }),
  group: () => ({
    padding: '0',
    margin:'0',
  }),
  multiValue: () => ({
    background:'#A77F4F',
    display:'flex',
    height:'24px',
    borderRadius:'24px',
    padding:'0 8px 0 16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '0px 0 2px 2px',
  }),
  multiValueLabel: () => ({
    lineHeight: '24px',
    color: '#fff',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize:'12px',
    letterSpacing:'0.75px',
  }),
  multiValueRemove: () => ({
    margin: 'auto',
    width: '18px',
    height: '18px',
    background: '#DDCBB6',
    borderRadius: '18px',
    marginLeft:'8px',
    cursor:'pointer',
  }),
}

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor:'#E9E9E9',
  color:"#575553",
  height:'40px',
  paddingLeft:'16px',
  paddingRight:'8px',
}
  export const groupBadgeStyles = {   
  width:'24px',
  height:'24px',
  lineHeight:'24px',
  backgroundColor:'#575553',
  color:'white',
  borderRadius:'24px',
  textAlign: 'center' as 'center'
}
