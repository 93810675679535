import React from 'react'
import { mdiChevronDown, mdiChevronUp, mdiArrowExpandRight, mdiArrowExpandLeft } from '@mdi/js'
import { ButtonSizeEnum, ButtonTypeEnum } from '../../../enums'
import Button from '../../Buttons'
import { LimitedTextContent } from '../../../utils/LimitedTextContent'
import { MpFileProps } from '../../../domain/File'

type Props = {
  files: MpFileProps[]
  totalFiles: number
  currentFile: MpFileProps
  onFileClick: (file: MpFileProps, selectedFileIndex: number) => void
  onMenuClick: () => void
  isCollapsed: boolean
}

export const MpFileListViewerHeaderControl: React.FC<Props> = (props: Props) => {
  const { currentFile, onFileClick } = props

  const getFileIndex = (): number => (
    props.files?.findIndex(file => file?.id === currentFile?.id)
  )

  const setNextFile = (): void => {
    const currentFileIndex = getFileIndex()
    const selectedFileIndex = currentFileIndex + 1
    onFileClick(props.files[selectedFileIndex], selectedFileIndex)
  }

  const setPreviousFile = (): void => {
    const currentFileIndex = getFileIndex()
    if (currentFileIndex > 0) {
      const selectedFileIndex = currentFileIndex - 1
      onFileClick(props.files[selectedFileIndex], selectedFileIndex)
    }
  }

  return (
    <div className="mp-file-viewer-control">

      <Button tooltipText={props.isCollapsed ? 'Mostrar nome da peça' : 'Esconder nome da peça'}
        buttonSize={ButtonSizeEnum.SMALL}
        buttonType={ButtonTypeEnum.ICON}
        handleClick={props.onMenuClick}
        iconPath={props.isCollapsed ? mdiArrowExpandRight : mdiArrowExpandLeft } />

      <div className='controls'>
        <Button tooltipText='Passar para anterior'
          id='go-to-up'
          buttonSize={ButtonSizeEnum.SMALL}
          buttonType={ButtonTypeEnum.ICON}
          isDisabled={getFileIndex() === 0 || !currentFile?.id}
          handleClick={setPreviousFile}
          iconPath={mdiChevronUp} />
        <span className='pagination'>
          <LimitedTextContent maxLength={14}>
            {`${getFileIndex() + 1} de ${props.totalFiles ? props.totalFiles : props.files.length}`}
          </LimitedTextContent>
        </span>
        <Button tooltipText='Passar para próxima'
          id='go-to-down'
          buttonSize={ButtonSizeEnum.SMALL}
          buttonType={ButtonTypeEnum.ICON}
          isDisabled={getFileIndex() === (props.files?.length - 1)}
          handleClick={setNextFile}
          iconPath={mdiChevronDown} />
      </div>

    </div>
  )
}
