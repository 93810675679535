import React, { ReactNode, useEffect, useState } from 'react'
import { mdiChevronDown, mdiChevronUp, mdiDotsVertical, mdiMenu, mdiUnfoldMoreHorizontal } from '@mdi/js'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import MpTable, { Column, MpTableCell, MpTableRow } from '../Table'
import { Button } from '../Buttons'
import { ButtonProps } from '../Buttons/Button'
import Checkbox, { MpCheckbox } from '../Checkbox'
import ButtonGroup from '../ButtonGroup'
import Icone from '../icone'
import { MpTableColumnsProps } from '@/components/Table/Table'
import ContextualMenu from '../ContextualMenu'
import { MpListItemLink, MpListItemSimple, MpListItemIconLeftArea, MpListItemContent } from '../List'
import { ButtonSizeEnum, ButtonTypeEnum, ContextualMenuPositionEnum, CoresEnum, TamanhoIconeEnum } from '../../enums'
import MpSpacingProps from '../Spacing'

import 'react-placeholder/lib/reactPlaceholder.css'
import './dataGrid.scss'
import { DragAndAdropHandler, getDroppableAreaStyle, getDraggableItemStyle } from '../../utils/DragAndDrop/DragAndDropHandler'

export interface MpDataGridProps {
  id?: string
  isLoading?: boolean
  header: MpTableColumnsProps
  footer?: MpTableColumnsProps
  rows: Row[]
  hasHighlightInFirstColumn?: boolean
  spacing?: MpSpacingProps
  noHover?: boolean
  dragAndDrop? : boolean
  widthActionsColumn?: number
  onSelectAll?: (isAllSelected?: boolean) => void
  onSelect?: (row: any) => void
  onCollapseAll?: () => void
  afterDrop? : (rows?: any[]) => void
}

export interface ContextualMenuProps {
  actions?: ActionProps[]
  menuPosition?: ContextualMenuPositionEnum
}

export interface ActionProps {
  buttonText: string
  icon?: string
  onClick?: (model? : any) => void
}
export interface Row {
  id: string
  content: RowContent[]
  buttons?: ButtonProps[]
  buttonContextualMenuSize?: ButtonSizeEnum
  contextualActions?: ContextualMenuProps
  isSelected?: boolean
  isCollapsed?: boolean
  collapsedContent?: ReactNode
  metadata?: any
}

export interface RowContent {
  data: ReactNode

}
export const MpDataGrid: React.FC<MpDataGridProps> = (props: MpDataGridProps) => {
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [isAllCollapsed, setIsAllCollapsed] = useState(false)
  const [rows, setRows] = useState(props.rows)

  const hasButtonArea = (row: Row): boolean => (row.buttons && row.buttons.length > 0 && row.buttons.some(button => !button.hide)) || row.collapsedContent !== undefined

  const hasAnyButtonOrContextualAction = (): boolean => props.rows.some(row => row.contextualActions?.actions?.length) || props.rows.some(row => row.buttons?.length && row.buttons.some(button => !button.hide))

  const onCheckOrCollapseAll = (command: 'CHECK_ALL' | 'COLLAPSE_ALL'): void => {
    props.rows.map(row => {
      if (command === 'CHECK_ALL') row.isSelected = !isAllSelected
      if (command === 'COLLAPSE_ALL') row.isCollapsed = !isAllCollapsed
    })
  }

  const onCheckAll = (): void => {
    setIsAllSelected(!isAllSelected)
    onCheckOrCollapseAll('CHECK_ALL')

    if (props.onSelectAll) props.onSelectAll(isAllSelected)
  }

  const onCollapseAll = (): void => {
    setIsAllCollapsed(!isAllCollapsed)
    onCheckOrCollapseAll('COLLAPSE_ALL')

    if (props.onCollapseAll) props.onCollapseAll()
  }

  const onCheckOrCollapse = (command: 'CHECK' | 'COLLAPSE', rowId: string): void => {
    const allItens: any = props.rows.map((row) => {
      if (rowId === row.id) {
        if (command === 'CHECK') {
          row.isSelected = !row.isSelected
          if (props.onSelect) {
            props.onSelect(row)
          }
        }
        if (command === 'COLLAPSE') row.isCollapsed = !row.isCollapsed
      }
      return row
    })

    setRows(allItens)
  }

  const onCheck = (rowId: string): void => {
    onCheckOrCollapse('CHECK', rowId)
    checkIfIsAllSelected()
  }

  const checkIfIsAllSelected = (): void => {
    let count: number = 0
    props.rows.map((linha) => {
      if (linha.isSelected) count++
    })
    if (count <= props.rows.length && isAllSelected) setIsAllSelected(false)
    if (count === props.rows.length && !isAllSelected) setIsAllSelected(true)
  }

  const getColumns = (position: 'HEADER' | 'FOOTER'): Column[] => {
    let contentOnSelectAll: React.ReactElement = <></>
    let contentOnCollapseAll: React.ReactElement = <></>
    const contentDragAndDrop: React.ReactElement = <> </>
    let celWidth: string = 'auto'
    const previousColumns = position === 'HEADER' ? props.header.columns : props.footer.columns
    if (position === 'HEADER') {
      celWidth = '52px'
      contentOnSelectAll = <MpCheckbox data-testid='mp-checkbox-thead' id='mp-checkbox-thead' isChecked={isAllSelected} onChange={onCheckAll} />
      contentOnCollapseAll = <Button id={'mp-data-grid-collapse-all-button'} key={'mp-data-grid-collapse-all-button'} buttonType={ButtonTypeEnum.ICON} iconPath={mdiUnfoldMoreHorizontal} buttonSize={ButtonSizeEnum.SMALL} handleClick={() => onCollapseAll()} />
    }

    const columns = [
      props?.dragAndDrop ? { width: celWidth, content: contentDragAndDrop } : undefined,

      props?.onSelectAll ? { width: celWidth, content: contentOnSelectAll } : undefined,
      ...previousColumns,
      props?.onCollapseAll ? { width: `${props.widthActionsColumn}px` ?? celWidth, content: contentOnCollapseAll } : hasAnyButtonOrContextualAction() ? { content: ' ', width: `${props.widthActionsColumn}px` ?? null } : undefined
    ]
    return columns.filter(column => column !== undefined)
  }

  const getContentCollapsableColspan = (columnsLength): number => {
    let result = columnsLength
    if (props.onSelectAll) result++
    if (props.onCollapseAll) result++
    return result
  }

  const onDragEnd = (dragAndDropEvent: any): void => {
    if (dragAndDropEvent.destination) {
      const reorderedItems = DragAndAdropHandler.reorder('TABLE_ROW',
        rows,
        dragAndDropEvent
      )
      setRows(reorderedItems)
      if (props.afterDrop) props.afterDrop(reorderedItems)
    }
  }

  const ContextualButtonsArea = (props: Row): JSX.Element => {
    return (
      <ContextualMenu id={'mp-data-grid-contextual-menu'}
        position={props.contextualActions.menuPosition ? props.contextualActions.menuPosition : ContextualMenuPositionEnum.CORNER_TOP_LEFT}
        itensMenu={
          props.contextualActions?.actions.map((action, index) =>
            <MpListItemLink handleClick={action.onClick} key={`mp-data-grid-ctxmenu-${index.toString()}`}>
              <MpListItemSimple>
                {action.icon &&
                  <MpListItemIconLeftArea iconPath={action.icon} />
                }
                <MpListItemContent>{action.buttonText}</MpListItemContent>
              </MpListItemSimple>
            </MpListItemLink>)
        }
      >
        <Button id='mp-data-grid-contextual-menu'
          iconPath={mdiDotsVertical}
          buttonSize={props.buttonContextualMenuSize ? props.buttonContextualMenuSize : ButtonSizeEnum.SMALL}
          buttonType={ButtonTypeEnum.ICON} />
      </ContextualMenu>)
  }

  const ButtonArea = (collapsableRow: Row): JSX.Element => {
    const collapseButton: ButtonProps = collapsableRow.collapsedContent
      ? { id: 'mp-data-grid-collapse-button', buttonType: ButtonTypeEnum.ICON, buttonSize: ButtonSizeEnum.SMALL, iconPath: collapsableRow.isCollapsed ? mdiChevronDown : mdiChevronUp, handleClick: () => onCheckOrCollapse('COLLAPSE', collapsableRow.id) }
      : undefined

    let buttonsToRender: ButtonProps[] = []
    const buttonList: ButtonProps[] = collapsableRow.buttons?.length ? collapsableRow.buttons : []

    if (collapseButton) {
      buttonsToRender.push(collapseButton)
    } else {
      buttonsToRender.push({ hide: true })
    }

    buttonsToRender = [...buttonsToRender, ...buttonList]
    return (
      <ButtonGroup>
        {buttonsToRender.map((btnProps, index) =>
          <React.Fragment key={`mp-data-grid-btn-list${index}`}>
            { btnProps.iconPath &&
              <Button {...btnProps}
                id={btnProps.id ? btnProps.id : `mp-data-grid-row-id${index}` }
                key={`mp-data-grid-btn-list-${btnProps.id ? btnProps.id : index}-row-id-${index}`}
                buttonType={ButtonTypeEnum.ICON}
                handleClick={() => btnProps.handleClick?.(collapsableRow)}
              />
            }
            {!btnProps.iconPath && props.onCollapseAll &&
              <div style={{ width: '32px' }}></div>}
          </React.Fragment>
        )}

        {collapsableRow.contextualActions?.actions?.length ? <ContextualButtonsArea {...collapsableRow} /> : <></>}

      </ButtonGroup>
    )
  }

  useEffect(() => { setRows(props.rows) }, [props.rows])
  useEffect(() => { onCollapseAll() }, [])
  useEffect(() => {
    setIsAllSelected(props.rows.every(r => r.isSelected))
  }, [props.rows])

  return (
    <DragDropContext onDragEnd={props.dragAndDrop ? onDragEnd : null} >
      <Droppable droppableId="mp-table-droppable">
        {(provided, snapshot) => (
          <MpTable key={props.id ? props.id : 'mp-data-grid-table'}
            id={props.id ? props.id : 'mp-data-grid-table'}
            isLoading={props.isLoading}
            header={{ columns: getColumns('HEADER') }}
            footer={props?.footer ? { columns: getColumns('FOOTER') } : null}
            ref={provided.innerRef}
            style={getDroppableAreaStyle(snapshot.isDraggingOver)}
            htmlInternalProps = {provided.droppableProps}
            hasHighlightInFirstColumn={props.hasHighlightInFirstColumn}
            spacing={props.spacing}
            firstColumnIndex={props.onSelectAll ? 1 : 0}

          >
            {rows?.map((row, i) => (
              <Draggable key={row?.id?.toString() ?? String(Math.random() * 100) } draggableId={row?.id?.toString() ?? String(Math.random() * 100) } index={i} isDragDisabled={!props.dragAndDrop}>
                {(provided, snapshot) => (

                  <React.Fragment key={`mp-data-grid-${i}`} >

                    <MpTableRow id={`mp-data-grid-table-row-${i}`}
                      ref={provided.innerRef}
                      htmlInternalProps = {{ ...provided.draggableProps }}
                      noHover={props.noHover}
                      style= {getDraggableItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}>

                      {props.dragAndDrop && <MpTableCell key={`mp-data-grid-table-drag-and-drop${i}`} >
                        <div {...provided.dragHandleProps}>
                          <Icone iconPath={mdiMenu} iconSize={TamanhoIconeEnum.XSMALL} iconColor={CoresEnum.GRAY_200}> </Icone>
                        </div>
                      </MpTableCell>}

                      {props.onSelectAll && <MpTableCell key={`mp-data-grid-table-cell-${i}`} >
                        <Checkbox key={`mp-checkbox-table-${i}`} id={`mp-checkbox-table-${i}`} isChecked={row.isSelected} onChange={() => onCheck(row.id)} />
                      </MpTableCell>}

                      {row.content.map((col, index) => (
                        <MpTableCell key={`mp-data-grid-table-cell-${index}`} >
                          {col.data}
                        </MpTableCell>
                      ))}

                      { hasButtonArea(row) && <MpTableCell key={`mp-data-grid-table-actions-${i}`} >
                        <ButtonArea {...row} />
                      </MpTableCell>
                      }

                    </MpTableRow >

                    {row.collapsedContent && (!row.isCollapsed) &&
                   <MpTableRow cssClassName='mp-table-collapse' id='mp-data-grid-collapse-all-row' key={`mp-data-grid-table-collapse-content-${i}`}>
                     {<MpTableCell colspan={getContentCollapsableColspan(props.header.columns.length)}> {row.collapsedContent} </MpTableCell>}
                   </MpTableRow>
                    }
                  </React.Fragment>
                )}
              </Draggable>

            ))}
            {provided.placeholder}

          </MpTable>
        )}
      </Droppable>
    </DragDropContext>
  )
}
