import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { ButtonTypeEnum, TamanhoIconeEnum } from '../../enums'
import { Badge, BadgeProps, Button } from '../../index'
import { LimitedTextContent } from '../../utils/LimitedTextContent'
import Icone from '../icone'
import './tabs.scss'

interface Tab {
  id: number
  title: string
  content: ReactNode
  hide?: boolean
  disabled?: boolean
  iconPath?: string
  badge?: BadgeProps
}

export interface MpTabsProps {
  tabs: Tab[]
  activeTabId?: number
  hide?: boolean
}

export const MpTabs: React.FC<MpTabsProps> = (props: MpTabsProps) => {
  const ref = useRef(null)
  const SINGLE_SCROLL_UNIT = 180
  const ARROW_ICONS_WIDTH = 88
  // calculando a quantidade de abas que cabem na aŕea visivel a partir da resolucao
  const SCROLLABLE_CONTAINER_MIN = Math.trunc((window.innerWidth - ARROW_ICONS_WIDTH) / SINGLE_SCROLL_UNIT) * SINGLE_SCROLL_UNIT

  const findActiveTab = () => {
    if (props.activeTabId === undefined) return
    return props.tabs.find(
      tab => tab.id === props.activeTabId
    )?.content
  }
  const [activeTabId, setActiveTabId] = useState(props.activeTabId)
  const [content, setContent] = useState(findActiveTab)
  const [disableScroll, setDisableScroll] = useState(false)
  const [showScrollToLeft, setShowScrollToLeft] = useState(false)
  const [showScrollToRight, setShowScrollToRight] = useState(false)
  const [scrollPositition, setScrollPosition] = useState(SCROLLABLE_CONTAINER_MIN)

  const onClickTab = (tab: Tab): void => {
    setActiveTabId(tab.id)
    setContent(tab.content)
  }

  const scrollToLeft = (scrollOffset: number): void => {
    setDisableScroll(true)

    // Scroll possível apenas entre 0 e a largura da área scrollavel
    if (scrollPositition >= 0) {
      ref.current.scrollTo({ left: ref.current.scrollLeft -= scrollOffset, behavior: 'smooth' })
      setScrollPosition(scrollPositition - scrollOffset)
    }
    // Não há mais o que scrollar à esquerda
    if (scrollPositition <= SCROLLABLE_CONTAINER_MIN + SINGLE_SCROLL_UNIT) {
      setShowScrollToLeft(false)
    }
    // Se a área scrollavel é maior que a posição atual do scroll, habilita scroll a direita
    if (ref.current.scrollWidth > scrollPositition) {
      setShowScrollToRight(true)
    }
    setTimeout(function () { setDisableScroll(false) }, 300)
  }

  const scrollToRight = (scrollOffset: number): void => {
    setDisableScroll(true)

    // Se a posição do scroll + scroll adicional ainda for menor que a largura da área scrollavel, habilite um novo scroll
    if ((scrollPositition) < (ref.current.scrollWidth)) {
      ref.current.scrollTo({ left: ref.current.scrollLeft += scrollOffset, behavior: 'smooth' })
      setScrollPosition(scrollPositition + scrollOffset)
    } else {
      // Caso não, desabilite o scroll a direita
      setShowScrollToRight(false)
    }
    // Caso a posição do scroll seja menor que a área inicial scrollavel, habilite o scroll a esquerda
    if (scrollPositition >= SCROLLABLE_CONTAINER_MIN) {
      setShowScrollToLeft(true)
    }
    setTimeout(function () { setDisableScroll(false) }, 300)
  }

  // Habilita o scroll a direita, assim que o componente renderiza, caso necessario
  useEffect(() => {
    if ((scrollPositition) < (ref.current?.scrollWidth)) {
      setShowScrollToRight(true)
    } else {
      setShowScrollToRight(false)
    }
  })

  return (
    <>
      {(!props.hide && props.tabs.length) &&
        <div className="mp-tabs ">
          <ul className='mp-tabs-root'>

            <div data-testid="div-scroll-left-button" className='mp-tab-icon-scroll'>
              {showScrollToLeft && <Button isDisabled={disableScroll} buttonType={ButtonTypeEnum.ICON} iconPath={mdiChevronLeft} handleClick={() => scrollToLeft(SINGLE_SCROLL_UNIT)} />}
            </div>

            <div className='mp-tab-header-content' ref={ref} >

              {props.tabs?.map((tab, i) => (
                <Badge key={i} isOvertaking={false} tooltipText={tab.badge?.tooltipText} count={tab.badge?.count} position={tab.badge?.position} showWithoutNumber={tab.badge?.showWithoutNumber}>
                  <React.Fragment key={tab.id}>
                    {!tab.hide &&
                      <li className={` ${activeTabId === tab.id ? 'active' : ''}`} onClick={() => onClickTab(tab)} >
                        {tab.iconPath &&
                          <div className="mp-tabs-icon">
                            <Icone iconSize={TamanhoIconeEnum.SMALL} iconPath={tab.iconPath} />
                          </div>
                        }
                        <div className="mp-tabs-text">
                          <LimitedTextContent maxLength={tab.iconPath ? 12 : 15} >
                            {tab.title}
                          </LimitedTextContent>
                        </div>
                      </li>
                    }
                  </React.Fragment>
                </Badge>
              ))}

            </div>

            <div data-testid="div-scroll-right-button" className='mp-tab-icon-scroll'>
              {showScrollToRight &&
              <Button data-testid="scroll-right-button" isDisabled={disableScroll} buttonType={ButtonTypeEnum.ICON} iconPath={mdiChevronRight} handleClick={() => scrollToRight(SINGLE_SCROLL_UNIT)} />
              }
            </div>
          </ul>

          <div className="mp-tabs-content">
            {content}
          </div>

        </div>
      }
    </>
  )
}
