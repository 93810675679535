import styled from "styled-components";

export const HelpButtonWrapp = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 10000;
`;

export const HelpButton = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #b79266;
  border: solid white 5px;
  color: white;
  font-size: 40px;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 20%);
`;
