import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { ContextualMenuProps, CONTEXTUAL_MENU_MAX_DIMENSION_PX, SPACING_DEFAULT_OFFSET } from './ContextualMenu'
import { MenuContextualContent } from './ContextualMenuContent'
import { MenuContextualClickArea } from './ContextualMenuClickArea'
import { ContextualMenuPositionEnum } from '../../enums'

import './contextual-menu.scss'

const CONTEXTUAL_MENU_ITEM_HEIGHT_PX: number = 52
const CONTEXTUAL_MENU_BUTTON_DIMENSION: number = 44

export const AbsoluteContextualMenu: React.FC<ContextualMenuProps> = (props: ContextualMenuProps) => {
  const contentRef = useRef(null)
  const btnRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const [contextualMenuXy, setContextualMenuXy] = useState(null)

  const getButtonCoordinates = (position: ContextualMenuPositionEnum): {x: number, y: number} => {
    const rect = btnRef.current?.getBoundingClientRect()
    const offsetPosition = getPositionStyle(position)

    return rect ? { x: parseInt(rect?.x) - offsetPosition.x, y: parseInt(rect?.y) - offsetPosition.y } : { x: 0, y: 0 }
  }

  const getContextualMenuHeight = (): any => {
    const itensMenu = props?.itensMenu as any
    let contextualMenuItemsLength = itensMenu?.length
    if (!contextualMenuItemsLength) contextualMenuItemsLength = itensMenu?.props.children.length
    const contextualMenuVirtualHeightPX = contextualMenuItemsLength * CONTEXTUAL_MENU_ITEM_HEIGHT_PX
    return Math.min(...[contextualMenuVirtualHeightPX, CONTEXTUAL_MENU_MAX_DIMENSION_PX]) + 4
  }
  const getPositionStyle = (position: ContextualMenuPositionEnum): any => {
    switch (position) {
      case ContextualMenuPositionEnum.CORNER_TOP_LEFT : return { x: CONTEXTUAL_MENU_MAX_DIMENSION_PX, y: getContextualMenuHeight() }
      case ContextualMenuPositionEnum.CORNER_TOP_RIGHT : return { x: -CONTEXTUAL_MENU_BUTTON_DIMENSION, y: getContextualMenuHeight() }
      case ContextualMenuPositionEnum.CORNER_BOTTOM_LEFT : return { x: CONTEXTUAL_MENU_MAX_DIMENSION_PX, y: -CONTEXTUAL_MENU_BUTTON_DIMENSION }
      case ContextualMenuPositionEnum.CORNER_BOTTOM_RIGHT : return { x: -CONTEXTUAL_MENU_BUTTON_DIMENSION, y: -CONTEXTUAL_MENU_BUTTON_DIMENSION }

      case ContextualMenuPositionEnum.INNER_TOP_LEFT : return { x: CONTEXTUAL_MENU_MAX_DIMENSION_PX - CONTEXTUAL_MENU_BUTTON_DIMENSION, y: getContextualMenuHeight() }
      case ContextualMenuPositionEnum.INNER_TOP_RIGHT : return { x: 0, y: getContextualMenuHeight() }
      case ContextualMenuPositionEnum.INNER_BOTTOM_LEFT : return { x: CONTEXTUAL_MENU_MAX_DIMENSION_PX - CONTEXTUAL_MENU_BUTTON_DIMENSION, y: -CONTEXTUAL_MENU_BUTTON_DIMENSION }
      case ContextualMenuPositionEnum.INNER_BOTTOM_RIGHT : return { x: 0, y: -CONTEXTUAL_MENU_BUTTON_DIMENSION }

      case ContextualMenuPositionEnum.INNER_LEFT_UP : return { x: CONTEXTUAL_MENU_MAX_DIMENSION_PX, y: getContextualMenuHeight() - CONTEXTUAL_MENU_BUTTON_DIMENSION }
      case ContextualMenuPositionEnum.INNER_LEFT_DOWN : return { x: CONTEXTUAL_MENU_MAX_DIMENSION_PX, y: 0 }
      case ContextualMenuPositionEnum.INNER_RIGHT_UP : return { x: -CONTEXTUAL_MENU_BUTTON_DIMENSION, y: getContextualMenuHeight() - CONTEXTUAL_MENU_BUTTON_DIMENSION }
      case ContextualMenuPositionEnum.INNER_RIGHT_DOWN : return { x: -CONTEXTUAL_MENU_BUTTON_DIMENSION, y: 0 }

      default: return { x: 0, y: 0 }
    }
  }

  const verifyPlacementAndShow = (): void => {
    const rect = btnRef.current?.getBoundingClientRect()

    switch (props.position) {
      case ContextualMenuPositionEnum.CORNER_BOTTOM_LEFT :
      case ContextualMenuPositionEnum.INNER_BOTTOM_LEFT:
      case ContextualMenuPositionEnum.CORNER_TOP_LEFT :
      case ContextualMenuPositionEnum.INNER_TOP_LEFT :
      case ContextualMenuPositionEnum.INNER_LEFT_UP :
      case ContextualMenuPositionEnum.INNER_LEFT_DOWN:

        { const noHorizontalSpace: boolean = ((rect?.x - CONTEXTUAL_MENU_MAX_DIMENSION_PX) - SPACING_DEFAULT_OFFSET <= 0)
          const noVerticalSpace: boolean = ((window.innerHeight - rect?.y - getContextualMenuHeight()) - SPACING_DEFAULT_OFFSET <= 0)

          if (noHorizontalSpace) {
            setContextualMenuXy({ x: getButtonCoordinates(props.position)?.x + CONTEXTUAL_MENU_MAX_DIMENSION_PX, y: getButtonCoordinates(props.position).y })
          } else if (!noHorizontalSpace && noVerticalSpace) {
            setContextualMenuXy({ x: getButtonCoordinates(props.position)?.x, y: getButtonCoordinates(props.position)?.y - getContextualMenuHeight() - CONTEXTUAL_MENU_BUTTON_DIMENSION - SPACING_DEFAULT_OFFSET })
          } }

        break

      case ContextualMenuPositionEnum.CORNER_BOTTOM_RIGHT :
      case ContextualMenuPositionEnum.INNER_BOTTOM_RIGHT:
      case ContextualMenuPositionEnum.CORNER_TOP_RIGHT :
      case ContextualMenuPositionEnum.INNER_TOP_RIGHT :
      case ContextualMenuPositionEnum.INNER_RIGHT_UP :
      case ContextualMenuPositionEnum.INNER_RIGHT_DOWN:
        { const noHorizontalSpace: boolean = ((window.innerWidth - rect?.x - CONTEXTUAL_MENU_MAX_DIMENSION_PX) - SPACING_DEFAULT_OFFSET <= 0)
          const noVerticalSpace: boolean = (window.innerHeight - rect?.y - getContextualMenuHeight() - CONTEXTUAL_MENU_BUTTON_DIMENSION - SPACING_DEFAULT_OFFSET <= 0)

          if (noHorizontalSpace) {
            console.log('Alterando o LADO para ESQUERDA por falta de espaço horizontal')
            setContextualMenuXy({ x: getButtonCoordinates(props.position)?.x - CONTEXTUAL_MENU_MAX_DIMENSION_PX - CONTEXTUAL_MENU_BUTTON_DIMENSION, y: getButtonCoordinates(props.position).y })
          } else if (!noHorizontalSpace && noVerticalSpace) {
            console.log('RIGHT->Subindo o contextual menu por falta de espaço vertical', getContextualMenuHeight())

            setContextualMenuXy({
              x: getButtonCoordinates(props.position)?.x,
              y: getButtonCoordinates(props.position)?.y - getContextualMenuHeight() - CONTEXTUAL_MENU_BUTTON_DIMENSION - SPACING_DEFAULT_OFFSET
            })
          }
        }

        break
    }

    setShowMenu(!showMenu)
  }

  // Refactor: Estudar generalizacao dessas funcoes abaixo no ContextualMenu
  const onClickMenu = (e: any): void => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    setContextualMenuXy(getButtonCoordinates(props.position))
    verifyPlacementAndShow()
  }

  const checkClickInsideContextualMenu = (clientX: number, clientY: number): boolean => {
    const minContextualMenuX = contextualMenuXy?.x
    const maxContextualMenuX = parseInt(contextualMenuXy?.x) + CONTEXTUAL_MENU_MAX_DIMENSION_PX

    const minContextualMenuY = contextualMenuXy?.y
    const maxContextualMenuY = parseInt(contextualMenuXy?.y) + parseInt(getContextualMenuHeight())

    return !(clientX >= parseInt(minContextualMenuX) && clientX <= maxContextualMenuX) || (clientY <= parseInt(minContextualMenuY) && clientY >= maxContextualMenuY)
  }

  useEffect(() => {
    if (props.handleIsOpened) {
      props.handleIsOpened(showMenu)
    }
  }, [showMenu])

  const FloatContainer = (props: {children: ReactNode, positionX: number, positionY: number}): JSX.Element => {
    return (
      <div role="tooltip" x-placement="top" id="contextualMenuPlaceHolder"
        style={{ zIndex: 10, position: 'absolute', width: CONTEXTUAL_MENU_MAX_DIMENSION_PX, top: `${props.positionY}px`, left: `${props.positionX}px` }}>
        <div className="tooltip-inner">
          {props.children}
        </div>
      </div>
    )
  }
  const handleClickOutside = (event: MouseEvent): void => {
    if (checkClickInsideContextualMenu(event.clientX, event.clientY)) {
      setTimeout(() => setShowMenu(false), 400)
    }
  }

  document.addEventListener('click', handleClickOutside)

  return (

    <div datatest-id="mp-contextual-menu-absolute" className="mp-contextual-menu" ref={btnRef}>
      { props.itensMenu && showMenu && createPortal(
        <FloatContainer positionX={contextualMenuXy.x} positionY={contextualMenuXy.y}>
          <MenuContextualContent ref={contentRef} {...props}/>
        </FloatContainer>, document.body
      )}

      <MenuContextualClickArea id={`mp-contextual-menu-absolute-button-${Math.random() * 100}`} onClick={(e) => onClickMenu(e)}>
        {props.children}
      </MenuContextualClickArea>
    </div>

  )
}
