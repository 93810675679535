import React, { useState } from 'react'
import DayjsUtils from '@date-io/dayjs'
import locale from 'dayjs/locale/pt-br'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import dayjs, { Dayjs } from 'dayjs'
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder'
import { skeletonCustomDatepicker, skeletonCustomDatepickerComLabel } from './custom-datepicker-skeleton'

import 'react-placeholder/lib/reactPlaceholder.css'
import './custom-datepicker.scss'

export interface CustomDatepickerProps {
  value: string
  handleChange: (value: string) => void
  isLoading?: boolean,
  id?: string
  dataMinima?: string /** @params dataMinima deve ser fornecida no formato UTC */
  dataMaxima?: string /** @params dataMaxima deve ser fornecida no formato UTC */
  label?: string
  placeholder?: string
  outputFormat?: 'YYYY-MM-DD' | 'DD/MM/YYYY' /** @params formato em que a data será devolvida através do callback handleChange() */
  isRequired?: boolean
}

export const CustomDatepicker: React.FC<CustomDatepickerProps> = (props: CustomDatepickerProps) => {
  const onChange = (value): void => {
    const isoDate = dateIsValid(value) ? buildOutput(value) : value
    props.handleChange(isoDate)
  }

  const buildOutput = (dateValue: Dayjs): string => props.outputFormat ? dateValue.format(props.outputFormat) : dateValue.toISOString()

  function buildInput (dateValue: string): string {
    if (dateValue==undefined || dateValue==null) {
      return null
    } 
    if (props.outputFormat && dateIsValid(dateValue)) {
      return dayjs(dateValue).toISOString()
    }
    return dateValue
  }

  const dateIsValid = (dateValue: string): boolean => dateValue && dayjs(dateValue).isValid()

  const turnOnValidation = (): void => setInvalidMessage('Data inválida')

  const turnOffValidation = (): void => setInvalidMessage('')

  const [invalidMessage, setInvalidMessage] = useState('')

  const configDatePicker: KeyboardDatePickerProps = {
    autoComplete: 'off',
    placeholder: props.placeholder,
    format: 'DD/MM/YYYY',
    id: props.id,
    label: props.label,
    autoOk: true,
    value: buildInput(props.value),
    onChange: onChange,
    onBlur: turnOnValidation,
    onFocus: turnOffValidation,
    invalidDateMessage: invalidMessage,
    maxDateMessage: 'Data não pode ser maior que a data máxima',
    minDateMessage: 'Data não pode ser menor que a data mínima',
    variant: 'inline',
    margin: 'normal',
    openTo: 'date',
    views: ['year', 'month', 'date'],
    InputAdornmentProps: { position: 'end' },
    InputLabelProps: { shrink: true },
    ...props.dataMinima
      ? { minDate: props.dataMinima }
      : {},
    ...props.dataMaxima
      ? { maxDate: props.dataMaxima }
      : {}
  }

  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={props.label ? skeletonCustomDatepickerComLabel : skeletonCustomDatepicker}> 


    <div className="custom-datepicker">
      <MuiPickersUtilsProvider   locale={locale} utils={DayjsUtils}>
        <KeyboardDatePicker  required={props.isRequired ? true : false} { ...configDatePicker } />
      </MuiPickersUtilsProvider>
    </div>
    </ReactPlaceholder>
  )
}
