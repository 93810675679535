import { withStyles } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import classNames from 'classnames'
import React, { ReactNode, useEffect, useState } from 'react'
import { Badge, BadgePositionEnum, ButtonTypeEnum, Icone, TamanhoIconeEnum } from '../../index'
import { MpAutoHeight } from '../AutoHeight/MpAutoHeight'
import { ButtonGroup } from '../ButtonGroup'
import { Button } from '../Buttons'
import { ButtonProps } from '../Buttons/Button'
import './navigation-rail.scss'

interface Tab {
  label: string
  id?: number
  iconPath?: string
  contentTitle?: string
  contentButtons?: ButtonProps[]
  content: ReactNode
  badgeCount?: number
  hide?: boolean
}

export interface NavigationRailsProps {
  tabs: Tab[]
  initialTabIndex?: number
  heightTopInPx?: number
}

export const NavigationRail: React.FC<NavigationRailsProps> = (props: NavigationRailsProps) => {
  const tabsLen = props.tabs.length
  const [tabId, setTabId] = useState(props.initialTabIndex ? (props.initialTabIndex < tabsLen ? props.initialTabIndex : 0) : 0)
  const [content, setContent] = useState(null)
  const [contentTitle, setContentTitle] = useState(null)
  const [contentButton, setContentButton] = useState(null)

  const onClickTab = (tab: Tab, id: number): void => {
    setTabId(id)
    setContent(tab.content)
    setContentTitle(tab.contentTitle)
    setContentButton(tab.contentButtons)
  }

  useEffect(
    () => {
      onClickTab(props.tabs[tabId], tabId)
    }, []
  )

  return (
    <MpAutoHeight>
      <div className='mp-rails-container'>
        <div className={`mp-rails-tab-content ${(contentTitle || contentButton) ? 'has-title-button' : 'has-no-title-button'} `} >
          {
            (contentTitle || contentButton) && (
              <div className='tab-content-header'>
                {
                  contentTitle &&
              <div className='tab-content-title'><h6>{contentTitle}</h6></div>
                }
                {
                  contentButton &&
              <ButtonGroup data-testid='mp-custom-modal-buttom-group'>
                {contentButton?.map((button, key) =>
                  <Button data-testid={`mp-rails-buttom-${key}`} key={key}
                    handleClick={button.handleClick}
                    iconPath={button.iconPath}
                    tooltipText={button.tooltipText}
                    buttonType={ButtonTypeEnum.ICON} />
                )}
              </ButtonGroup>
                }
              </div>
            )
          }

          <div className='tab-content'>{content}</div>
        </div>

        <div className="mp-rails-tabs">

          <VerticalTabs value={tabId} variant='scrollable' orientation='vertical' scrollButtons="auto">
            {props.tabs.map((tab, index) => (
              !tab.hide &&
              <Badge key={`badge-tab-${tab.label}`} count={tab.badgeCount} position={BadgePositionEnum.TOP_RIGHT}>

                <Tab
                  key={index}
                  label={tab.label}
                  className={classNames({ 'tab-selected': tab.id === tabId })}
                  icon={<Icone iconSize={TamanhoIconeEnum.SMALL} iconPath={tab.iconPath || ''} />}
                  onClick={() => onClickTab(tab, index)}
                />
              </Badge>
            ))}
          </VerticalTabs>

        </div>
      </div>
    </MpAutoHeight>
  )
}

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column'
  },
  indicator: {
    display: 'none'
  }
}))(Tabs)
