import Icon from '@mdi/react'
import React, { useEffect, useState } from 'react'
import { mdiArrowUp, mdiMenu } from '@mdi/js'
import { useLocation } from 'react-router-dom'
import './style.scss'

export type SidebarNav = {
  id: number
  title: string
  path?: string
  icon: string
  subNav?: SidebarNav[]
}

type SidebarProps = {
  href?: string
  logoText?: string
  items?: SidebarNav[]
}

const Sidebar: React.FC<SidebarProps> = ({ href = '#', logoText, items }: SidebarProps) => {
  const location = useLocation()
  const [collapse, setCollapse] = useState<number>()
  const [menuOpen, setMenuOpen] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (menuOpen) {
      setTimeout(() => {
        setVisible(true)
      }, 200)
    } else setVisible(false)
  }, [menuOpen])

  useEffect(() => {
    items?.forEach((item) => {
      if (item.subNav) {
        item.subNav.forEach((subItem) => {
          if (location.pathname === subItem.path) {
            setCollapse(item.id)
          }
        })
      } else if (location.pathname === item.path) {
        setCollapse(item.id)
      }
    })
  }, [items, location.pathname])

  return (
    <nav className={menuOpen ? 'nav-sidebar-open' : 'nav-sidebar'}>
      <div className='header-sidebar'>
        <button className='bt-header-menu' onClick={() => setMenuOpen(!menuOpen)}>
          <Icon path={mdiMenu} color='#F6F1EB' size={1} />
        </button>
        {menuOpen && visible && (
          <a href={href} className='sidebar-a'>
            {!!logoText && <h1 className='sidebar-logo'>{logoText}</h1>}
          </a>
        )}
      </div>
      {items?.map(({ title, path, subNav, icon, id }) => {
        return (
          <ul key={title} className='sidebar-ul'>
            <li
              className={
                location.pathname === path && !subNav ? 'sidebar-li sidebar-active' : 'sidebar-li'
              }
            >
              <div className='sidebar-icon'>
                <Icon path={icon} size={1} color={'#F6F1EB'} />
              </div>
              {subNav && menuOpen && visible && subNav.length > 0 ? (
                <span onClick={() => setCollapse(id)}>
                  {title}
                  <Icon path={mdiArrowUp} color='#B79266' size={1} />
                </span>
              ) : (
                <>
                  {menuOpen && visible && (
                    <a
                      className={location.pathname === path ? 'sidebar-active' : ''}
                      href={`${path}`}
                    >
                      {title}
                    </a>
                  )}
                </>
              )}
            </li>
            {subNav &&
              visible &&
              menuOpen &&
              subNav.length > 0 &&
              subNav.map(({ title, path, icon }) => {
                return (
                  <ul
                    key={title}
                    className={collapse === id ? 'sub-sidebar-ul' : 'sub-sidebar-ul-hidden'}
                  >
                    <li
                      className={
                        location.pathname === path
                          ? 'sub-sidebar-li sidebar-active'
                          : 'sub-sidebar-li'
                      }
                    >
                      <a href={`/#${path}`}>
                        <Icon path={icon} color='#B79266' size={1} />
                        {title}
                      </a>
                    </li>
                  </ul>
                )
              })}
          </ul>
        )
      })}
    </nav>
  )
}

export default Sidebar
