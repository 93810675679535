
export const normalize = (palavra: string): string => (
  palavra.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
)

/**
 *
 * @param date string de data no formato  ISO8601 (yyyy-mm-dd)
 * @returns  se a string informada é uma data ISO8601 válida
 */
export const isValidDate = (date: string): boolean | RegExpMatchArray => {
  if (!date) return true
  return date
    .toString()
    .match(/\d{4}[\/\- ][0-1][0-2][\/\- ](?:[0-2][0-9]|3[0-1])/g)
}


 