import React from 'react'
import MpTypography from '../Typography'
import { CoresEnum } from '@/enums'
export interface Props {
  show: boolean
  className? : string
  text?: string
  textColor?: CoresEnum
  'data-testid'?: string | number
}

export const MpLoader: React.FC<Props> = (props: Props) => {
  return (
    <>
      {
        props.show && (
          <div className={`mp-loading ${props.className ? props.className : ''}`} data-testid={props['data-testid']}>
            {
              props.text && <MpTypography id={'text-loading'} variant='heading-4' color={props.textColor}>{props.text}</MpTypography>
            }
            <div className="mp-progress">
              <div className="indeterminate"></div>
            </div>
          </div>
        )
      }
    </>
  )
}
