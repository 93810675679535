import React from 'react'
import './style.scss'
interface HeaderProps {
  headerTitleProps: {
    href?: string
    logoText?: string
    logoImg?: React.ReactNode
  }
  children?: React.ReactNode
  isOpen?: boolean
}

const Header: React.FC<HeaderProps> = ({
  headerTitleProps: { logoText, logoImg, href = '#' },
  children,
}: HeaderProps) => {
  return (
    <div className='header-container'>
      <header className='header-box'>
        <div className='header-logo-container'>
          <a href={href} className='header-logo-a'>
            {logoText ? <h1 className='header-text'>{logoText}</h1> : logoImg}
          </a>
        </div>
        <ul className='mp-breadcrumb'></ul>
        <div className='mp-header-container'>{children}</div>
      </header>
    </div>
  )
}

export default Header
