import React from "react";

export const skeletonTable = ( 
    <div className='mp-table-skeleton'>
        <div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
  
    </div>
   )