import axios from 'axios'

const API = process.env.REACT_APP_API_URL

class Service {
  getService(path) {
    return axios.get(API + path)
  }

  postService(path, body) {
    return axios.post(API + path, body)
  }
}
export default new Service()
