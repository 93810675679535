import React from 'react'
import { ToastContainer, toast, Slide } from 'react-toastify'
import { mdiAlertCircleOutline, mdiCheckCircleOutline, mdiCloseCircleOutline, mdiInformationOutline } from '@mdi/js'
import { TamanhoIconeEnum, NotificationTypeEnum } from '../../enums'
import Icone from '../icone'
import 'react-toastify/dist/ReactToastify.css'
import './toaster.scss'

const { INFO, SUCCESS, WARNING, DANGER } = NotificationTypeEnum

export const emit = (message: string, type: NotificationTypeEnum): any => {
  const toastId = message
  const containerId = `mp-react-components-toast-container ${Math.random() * 100}`

  switch (type) {
    case INFO: return toast.info(<ToasterContent icon={mdiInformationOutline} message={message} />, { toastId, containerId })
    case SUCCESS: return toast.success(<ToasterContent icon={mdiCheckCircleOutline} message={message} />, { toastId, containerId })
    case WARNING: return toast.warning(<ToasterContent icon={mdiAlertCircleOutline} message={message} />, { toastId, containerId })
    case DANGER: return toast.error(<ToasterContent icon={mdiCloseCircleOutline} message={message} />, { toastId, containerId })
    default: return toast.info(<ToasterContent icon={mdiInformationOutline} message={message} />, { toastId, containerId })
  }
}

export interface ToasterProps {
  isLight?: boolean
  isProgressBar? : boolean
  autoClose?: number
}
export const Toaster: React.FC<ToasterProps> = (props: ToasterProps) => (
  <ToastContainer className={`mp-toast ${props.isLight ? 'light' : ''}`} autoClose ={props.autoClose ? props.autoClose : 8000} closeOnClick={false} transition={Slide} position="top-center" />
)

type ToasterContentProps = {
  message: string
  icon: string
}

const ToasterContent = ({ message, icon }: ToasterContentProps) => (
  <>
    <div className="icon-toaster"><Icone iconPath={icon} iconSize={TamanhoIconeEnum.LARGE} /></div>
    <div className="text-toaster">{message}</div>
  </>
)
