import { CoresEnum, TooltipPlacementEnum } from '@/enums'
import React, { CSSProperties } from 'react'
import { CustomTooltip } from '../CustomTooltip/CustomTooltip'
import MpSpacingProps, { getSpacing } from '../Spacing'

import ReactPlaceholder from 'react-placeholder/lib'
import { skeletonTypography } from './typography-skeleton'
import 'react-placeholder/lib/reactPlaceholder.css'

type AlignProps = 'center' | 'left' | 'right'
type VariantProps = 'heading-1' | 'heading-2' | 'heading-3' | 'heading-4' | 'heading-5' | 'heading-6' | 'paragraph' | 'small'
type ElementProps = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small' | 'span' | 'div'

export type MpTypographyProps = {
  id: string
  align?: AlignProps
  variant?: VariantProps
  element?: ElementProps
  color?: CoresEnum
  italic?: boolean
  bold?: boolean
  children?: string
  hideTooltip?: boolean
  spacing?: MpSpacingProps
  hasInnerHTML?: boolean
  isTruncate?: boolean
  toolTipPlacement?: TooltipPlacementEnum
  maxLength?: number
  isLoading?: boolean
}

type ElementMapProps = {
  [key in VariantProps]: {
    className: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small' // Classes css existentes para componentes textuais.
    element: ElementProps
  }
}

type OverWriteProps = {
  'data-testid': string
  id: string
  style?: CSSProperties
  className?: string
  children?: React.ReactNode
}

const VARIANT_ELEMENT_MAP: ElementMapProps = {
  paragraph: {
    className: 'p',
    element: 'p'
  },
  'heading-1': {
    className: 'h1',
    element: 'h1'
  },
  'heading-2': {
    className: 'h2',
    element: 'h2'
  },
  'heading-3': {
    className: 'h3',
    element: 'h3'
  },
  'heading-4': {
    className: 'h4',
    element: 'h4'
  },
  'heading-5': {
    className: 'h5',
    element: 'h5'
  },
  'heading-6': {
    className: 'h6',
    element: 'h6'
  },
  small: {
    className: 'small',
    element: 'small'
  }
}

const render = ({ align, children, color, italic, bold, maxLength, hideTooltip, toolTipPlacement, variant, id, element, spacing, hasInnerHTML, isTruncate }: MpTypographyProps): React.ReactElement => {
  if (hasInnerHTML && isTruncate) {
    throw new Error('[Typography] A propriedade isTruncate não funciona com conteúdo em HTML (propriedade hasInnerHTML). \n[Typography] Favor excluir a propriedade isTruncate ou hasInnerHTML')
  }
  const widthToTruncate = maxLength ? maxLength.toString() + 'ch' : '100%'
  const overWriteProps: OverWriteProps = {
    id,
    'data-testid': id,
    children: isTruncate
      ? <CustomTooltip placement={toolTipPlacement} text={hideTooltip ? '' : children}>
        <span className='truncate-text' style={{ width: widthToTruncate }}>{children}</span>
      </CustomTooltip>
      : children,
    className: ''
  }

  if (color) {
    Object.assign(overWriteProps, {
      style: { color: color.value }
    })
  }

  if (align) {
    Object.assign(overWriteProps, {
      style: { ...overWriteProps.style, textAlign: align }
    })
  }

  if (italic) {
    Object.assign(overWriteProps, {
      style: { ...overWriteProps.style, fontStyle: 'italic' }
    })
  }

  if (bold) {
    Object.assign(overWriteProps, {
      style: { ...overWriteProps.style, fontWeight: 'bold' }
    })
  }

  if (variant) {
    Object.assign(overWriteProps, {
      className: VARIANT_ELEMENT_MAP[variant].className
    })
  }

  if (spacing) {
    Object.assign(overWriteProps, {
      className: overWriteProps.className.concat(' ').concat(getSpacing(spacing))
    })
  }

  if (hasInnerHTML) {
    Object.assign(overWriteProps, {
      children: null,
      dangerouslySetInnerHTML: { __html: children }
    })
  }

  if (element) {
    return React.createElement(element, overWriteProps)
  } else {
    return React.createElement(VARIANT_ELEMENT_MAP[variant]?.element || 'p', overWriteProps)
  }
}

export const MpTypography: React.FC<MpTypographyProps> = (props: MpTypographyProps) => {
  return (
    <ReactPlaceholder showLoadingAnimation ready={!props.isLoading} customPlaceholder={skeletonTypography}>
      { render(props) }
    </ReactPlaceholder>
  )
}
