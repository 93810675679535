import '../../assets/css/spacing.scss'

// mp-design-system-scale
export type spaceRange = 0|0.5|1|1.5|2|2.5|3|3.5|4|4.5|5|5.5|6|6.5|7|7.5|8|8.5|9|9.5|10|10.5|11|11.5|12|12.5|13|13.5|14|14.5|15|15.5

export interface MpSpacingProps {
  ml?: spaceRange
  mr?: spaceRange
  mb?: spaceRange
  mt?: spaceRange
  mx?: spaceRange
  my?: spaceRange
  m? : spaceRange

  pl?: spaceRange
  pr?: spaceRange
  pb?: spaceRange
  pt?: spaceRange
  px?: spaceRange
  py?: spaceRange
  p? : spaceRange

  gap?: number
}

const getClassName = (prefixScss, unit) => {
  const somaPosicao = unit > 9.5 ? 3 : 2
  const posicao = prefixScss.indexOf('-') + somaPosicao
  const aux = `${unit}`.indexOf('.')
  return aux === -1 ? `${prefixScss + unit} ` : `${prefixScss + unit}`.substring(0, posicao) + 'a '
}

export function getSpacing (props: MpSpacingProps): string {
  let spacing = ''

  if (!props) {
    return spacing
  }

  if (props.m != null) spacing += getClassName('m-', props.m)
  if (props.mt != null) spacing += getClassName('mt-', props.mt)
  if (props.ml != null) spacing += getClassName('ml-', props.ml)
  if (props.mr != null) spacing += getClassName('mr-', props.mr)
  if (props.mb != null) spacing += getClassName('mb-', props.mb)
  if (props.mx != null) spacing += getClassName('mx-', props.mx)
  if (props.my != null) spacing += getClassName('my-', props.my)

  if (props.p != null) spacing += getClassName('p-', props.p)
  if (props.pt != null) spacing += getClassName('pt-', props.pt)
  if (props.pl != null) spacing += getClassName('pl-', props.pl)
  if (props.pr != null) spacing += getClassName('pr-', props.pr)
  if (props.pb != null) spacing += getClassName('pb-', props.pb)
  if (props.px != null) spacing += getClassName('px-', props.px)
  if (props.py != null) spacing += getClassName('py-', props.py)

  if (props.gap != null) spacing += `gap-${props.gap} `

  return spacing
}
