import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PaintBrush from './icons/paint-brush.svg';
import ClickObserver from '@ckeditor/ckeditor5-engine/src/view/observer/clickobserver';


import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import './css/style.css';


function toggleIcons(copyOrPaste) {

    if (copyOrPaste == "COPY") {
        document.getElementById('mp-text-editor').classList.toggle('paste-formmating')
        document.getElementById('mp-text-editor').classList.remove('copy-formmating')
    } else if (copyOrPaste == "PASTE") {
        document.getElementById('mp-text-editor').classList.toggle('copy-formmating')
        document.getElementById('mp-text-editor').classList.remove('paste-formmating')

    }

}

export default class CopyFormatting extends Plugin {


    init() {
        const editor = this.editor;
        const editorView = editor.editing.view
        const viewDocument = editorView.document;
        let isSwitchingStep = false



        editorView.addObserver(ClickObserver);
        editor.ui.componentFactory.add('copyFormatting', locale => {

            const view = new ButtonView(locale);


            let i = 0; //si 0 position normale
            let stylesToApply = []
            let selectionsToApply = []
            let stylesArray = null


            function fireAClick()
            {
                if(!isSwitchingStep)
                {
                    isSwitchingStep = true
                    view.fire('execute')
                }
            }

            function applyStyles(atributes, range) {

                if (!atributes.length) {
                     editor.execute('removeFormat');
                     setTimeout(fireAClick, 1200)

                    return
        
                }
                editor.model && editor.model.change(writer => {
                    for (const singleAttribute of atributes) {
                       
                        writer.setAttribute(singleAttribute[0], singleAttribute[1], range)

                    }

                  
                    setTimeout(fireAClick, 1000)
                
                })
              
        
            }
        

            view.set({
                label: 'Copiar Formatação',
                icon: PaintBrush,
                tooltip: true
            });

            // Callback executed once the image is clicked.
            view.on('execute', () => {


                if (i === 0) {
                    //zera o que estava selecionado em memoria
                    //console.log('Deve copiar os estilos selecionados...',selectionsToApply )

                    isSwitchingStep = false


                    toggleIcons("COPY")

                    view.set({
                        label: 'Copiar Formatação',
                        icon: PaintBrush,
                        tooltip: true,
                        isOn: true

                    });

                    let selection = editor.model.document.selection;
                    let range = selection.getFirstRange();
                    selection.destroy()


                    for (const item of range.getItems()) {
                        stylesToApply = item.getAttributes()
                        stylesArray = stylesToApply ? Array.from(stylesToApply) : []

                        //acrescenta ao que foi slecionado via mouse, o que está atualmente ativo no cursor

                        break
                    }

                    if (!stylesArray || !stylesArray.length)  stylesArray = Array.from(editor.model.document.selection.getAttributes())



                    view.set({
                        label: 'Colar Formatação',
                        icon: PaintBrush,
                        tooltip: true,
                        isOn: false

                        



                    });

                    i = 1;

                    //console.log('Estilos copiados na fase de copia', stylesToApply)

                } else {

                    toggleIcons("PASTE")

                    view.set({
                        label: 'Copiar Formatação',
                        icon: PaintBrush,
                        tooltip: true,
 
                    });


                    stylesToApply = []
                    stylesArray = []
                    selectionsToApply = []
                    i = 0;

                }


            });


            editor.listenTo(viewDocument, 'mousedown', (evt, data) => {
                //console.log('MOUSE DOWN...' )

                if (i === 0) return

                const modelElement = editor.editing.mapper.toModelElement(data.target);
                if (modelElement) selectionsToApply.push(modelElement)

            });

            editor.listenTo(viewDocument, 'mouseup', (evt, data) => {
                 //console.log('MOUSE UP...' )

                if (i === 0) return


                let selectionX = editor.model.document.selection;
                const range = selectionX.getFirstRange()


                if (selectionsToApply) {
                    applyStyles( stylesArray, range)

                }

            })


            return view;
        });
    }
}