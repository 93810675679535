import React, { useEffect, useRef, useState } from 'react'
import { ContextualMenuProps, CONTEXTUAL_MENU_MAX_DIMENSION_PX, SPACING_DEFAULT_OFFSET } from './ContextualMenu'
import { MenuContextualContent } from './ContextualMenuContent'
import { MenuContextualClickArea } from './ContextualMenuClickArea'
import { ContextualMenuPositionEnum } from '../../enums'

import './contextual-menu.scss'

export const RelativeContextualMenu: React.FC<ContextualMenuProps> = (props: ContextualMenuProps) => {
  const contentRef = useRef(null)
  const btnRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const { width: clientWidth, height: clientHeight } = btnRef.current?.getBoundingClientRect() ?? 0

  const POS_LEFT = clientWidth
  const POS_RIGHT = clientWidth
  const POS_TOP = clientHeight
  const POS_BOTTOM = clientHeight

  const TOP_RIGHT = { bottom: POS_BOTTOM, left: POS_LEFT }
  const TOP_LEFT = { bottom: POS_BOTTOM, right: POS_RIGHT }
  const BOTTOM_LEFT = { top: POS_TOP, right: POS_RIGHT }
  const BOTTOM_RIGHT = { top: POS_TOP, left: POS_LEFT }
  const INNER_TOP_RIGHT = { bottom: POS_BOTTOM, left: 0 }
  const INNER_TOP_LEFT = { bottom: POS_BOTTOM, right: 0 }
  const INNER_BOTTOM_LEFT = { top: POS_TOP, right: 0 }
  const INNER_BOTTOM_RIGHT = { top: POS_TOP, left: 0 }
  const INNER_LEFT_UP = { bottom: 0, right: POS_RIGHT }
  const INNER_LEFT_DOWN = { top: 0, right: POS_RIGHT }
  const INNER_RIGHT_UP = { bottom: 0, left: POS_LEFT }
  const INNER_RIGHT_DOWN = { top: 0, left: POS_LEFT }

  const [position, setPosition] = useState(props.position)

  const getPositionStyle = (position: ContextualMenuPositionEnum): any => {
    switch (position) {
      case ContextualMenuPositionEnum.CORNER_TOP_RIGHT : return TOP_RIGHT
      case ContextualMenuPositionEnum.CORNER_TOP_LEFT : return TOP_LEFT
      case ContextualMenuPositionEnum.CORNER_BOTTOM_LEFT : return BOTTOM_LEFT
      case ContextualMenuPositionEnum.CORNER_BOTTOM_RIGHT : return BOTTOM_RIGHT
      case ContextualMenuPositionEnum.INNER_TOP_LEFT : return INNER_TOP_LEFT
      case ContextualMenuPositionEnum.INNER_TOP_RIGHT : return INNER_TOP_RIGHT
      case ContextualMenuPositionEnum.INNER_BOTTOM_LEFT : return INNER_BOTTOM_LEFT
      case ContextualMenuPositionEnum.INNER_BOTTOM_RIGHT : return INNER_BOTTOM_RIGHT
      case ContextualMenuPositionEnum.INNER_LEFT_UP : return INNER_LEFT_UP
      case ContextualMenuPositionEnum.INNER_LEFT_DOWN : return INNER_LEFT_DOWN
      case ContextualMenuPositionEnum.INNER_RIGHT_UP : return INNER_RIGHT_UP
      case ContextualMenuPositionEnum.INNER_RIGHT_DOWN : return INNER_RIGHT_DOWN

      default: return INNER_BOTTOM_RIGHT
    }
  }

  const verifyPlacementAndShow = (): void => {
    const rect = btnRef.current?.getBoundingClientRect()
    switch (props.position) {
      case ContextualMenuPositionEnum.CORNER_BOTTOM_LEFT :
      case ContextualMenuPositionEnum.INNER_BOTTOM_LEFT:
      case ContextualMenuPositionEnum.CORNER_TOP_LEFT :
      case ContextualMenuPositionEnum.INNER_TOP_LEFT :
      case ContextualMenuPositionEnum.INNER_LEFT_UP :
      case ContextualMenuPositionEnum.INNER_LEFT_DOWN:

        if (((rect?.x - CONTEXTUAL_MENU_MAX_DIMENSION_PX) - SPACING_DEFAULT_OFFSET <= 0)) {
          setPosition(getPositionStyle(ContextualMenuPositionEnum.INNER_RIGHT_UP))
        }
        break

      case ContextualMenuPositionEnum.CORNER_BOTTOM_RIGHT :
      case ContextualMenuPositionEnum.INNER_BOTTOM_RIGHT:
      case ContextualMenuPositionEnum.CORNER_TOP_RIGHT :
      case ContextualMenuPositionEnum.INNER_TOP_RIGHT :
      case ContextualMenuPositionEnum.INNER_RIGHT_UP :
      case ContextualMenuPositionEnum.INNER_RIGHT_DOWN:
        if ((window.innerWidth - rect?.x - CONTEXTUAL_MENU_MAX_DIMENSION_PX) - SPACING_DEFAULT_OFFSET <= 0) {
          console.log('Alterando posicionando para INNER_LEFT_UP por falta de espaço horizontal')
          setPosition(getPositionStyle(ContextualMenuPositionEnum.INNER_LEFT_UP))
        }

        break
    }

    setShowMenu(!showMenu)
  }
  const onClickMenu = (): void => {
    setShowMenu(!showMenu)
    verifyPlacementAndShow()
  }

  // Refactor: Estudar generalizacao dessas funcoes abaixo no ContextualMenu
  const clickOutsideMenu = (): any => {
    function handleClickOutside (event: MouseEvent): void {
      if (contentRef.current) {
        if (contentRef.current.contains(event.target)) {
          setTimeout(() => setShowMenu(false), 400)
        }
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }

  const mouseOutsideMenu = (): any => {
    function handleMouseOutside (event: MouseEvent): void {
      if (btnRef.current) {
        if (!btnRef.current.contains(event.target)) {
          setShowMenu(false)
        }
      }
    }
    document.addEventListener('mousedown', handleMouseOutside)
    return () => document.removeEventListener('mousedown', handleMouseOutside)
  }

  useEffect(() => {
    if (props.handleIsOpened) {
      props.handleIsOpened(showMenu)
    }
  }, [showMenu])

  useEffect(clickOutsideMenu, [])
  useEffect(mouseOutsideMenu, [])
  useEffect(() => { setPosition(props.position) }, [props.position])

  return (
    <div datatest-id="mp-contextual-menu-relative" className="mp-contextual-menu" ref={btnRef}>

      { props.itensMenu && showMenu &&
      <>
        <MenuContextualContent ref={contentRef} style={getPositionStyle(position)} {...props}/>
      </>
      }

      <MenuContextualClickArea id={`mp-contextual-menu-relative-button-${Math.random() * 100}`} onClick={onClickMenu}>
        {props.children}
      </MenuContextualClickArea>

    </div>
  )
}
