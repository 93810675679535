import styled from 'styled-components'

export const BeaconButton = styled.span`
    position: relative;
    top: -22%;
    left: 50%;
    height: 44px;
    width: 44px;
    border-radius:50%;
    background: #FFFFFF;
    border: 1px solid #B79266;
    color: #B79266;
    text-align: center;
    cursor: pointer;
`;

