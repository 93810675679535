import React, { useEffect, useState } from 'react'
import { CoresEnum } from '../../enums'
import MpSpacingProps, { getSpacing } from '../Spacing'

type Props = {
  id?: string
  children: React.ReactNode
  spacing?: MpSpacingProps
  withoutBackground?: boolean
  heightDecrement?: number
}

export const MpAutoHeight = ({ id, children, spacing, withoutBackground, heightDecrement }: Props): React.ReactElement => {
  const [height, setHeight] = useState('')
  const CONTAINER_ID = id || OVER_FLOW_CONTAINER_ID

  useEffect(() => {
    const el = document.getElementById(CONTAINER_ID)
    const viewportOffset = el?.getBoundingClientRect()
    const decrement = heightDecrement || 0
    const calcHeight = `calc(100vh - ${decrement + viewportOffset?.top + 16}px)` // acrescimo de 16px de padding + 2px de border, qdo aplicado o Card basico
    setHeight(calcHeight)
  }, [heightDecrement])

  return (
    <div
      id={CONTAINER_ID}
      className={getSpacing(spacing)}
      style={{
        height,
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
        backgroundColor: !withoutBackground && CoresEnum.WHITE.value
      }}>
      {children}
    </div>
  )
}

export const OVER_FLOW_CONTAINER_ID = 'mp-overflow-container'
