import React from 'react'
import {
  Button,
  ButtonGroup,
  ButtonThemeEnum,
  InputText,
  MpGrid,
  MpGridItem,
  MpTableCell,
  MpTable,
  MpTableRow,
  ButtonTypeEnum,
} from 'mp-react-components'
import './style.scss'
import { Column } from 'mp-react-components/dist/src/components/Table'
import { mdiPencil } from '@mdi/js'
import { Accordion, AccordionDetails, createStyles, makeStyles, Theme } from '@material-ui/core'
import CustomSelect from '../../components/CustomSelect'

export const ConsultarAnaliseTecnica: React.FC = () => {
  const columnsHeader: Column[] = [
    { content: 'Número SAT' },
    { content: 'Número MPRJ' },
    { content: 'Fase' },
    { content: 'Situação' },
    { content: 'Ementa' },
    { content: 'Órgão solicitante' },
    { content: 'Membro responsável' },
    { content: 'Servidor Responsável' },
    { content: '' },
  ]

  const linhas = [
    {
      id: 1,
      sat: '1234',
      mprj: '20.22.00001.0074178.2021-03',
      fase: 'Em elaboração da SAT',
      situacao: 'Em elaboração',
      duvida:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sollicitudin sapien vitae diam consequat accumsan. Cras in sagittis orci. Nulla cursus turpis at odio consectetur lobortis. Praesent sed tortor erat. Aliquam dictum ultrices ultricies. Nunc congue tincidunt cursus. Vestibulum et aliquet ante, et efficitur quam. In sagittis auctor mauris quis tristique. Vestibulum vel lacus consectetur augue vestibulum tincidunt ut et enim. Curabitur et dictum mi, vel interdum felis. Praesent libero turpis, pharetra non nisi ut, varius pretium nibh. Vestibulum sed eleifend justo. Phasellus at leo vel dolor tempus rhoncus nec sit amet dui. In luctus vel nunc congue auctor. Donec fringilla semper mauris at mollis.',
      orgao: '3ª PROMOTORIA DE JUSTIÇA DE TUTELA COLETIVA DO NÚCLEO ANGRA DOS REIS',
      membro: 'RAQUEL MADRUGA DO NASCIMENTO BRITO',
      servidor: 'Fabiana Ribeiro de Castro',
    },
    {
      id: 2,
      sat: '1235',
      mprj: '20.22.00001.0075148.2021-05',
      fase: 'Análise',
      situacao: 'Análise pela secretaria',
      duvida:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sollicitudin sapien vitae diam consequat accumsan. Cras in sagittis orci. Nulla cursus turpis at odio consectetur lobortis. Praesent sed tortor erat. Aliquam dictum ultrices ultricies. Nunc congue tincidunt cursus. Vestibulum et aliquet ante, et efficitur quam. In sagittis auctor mauris quis tristique. Vestibulum vel lacus consectetur augue vestibulum tincidunt ut et enim. Curabitur et dictum mi, vel interdum felis. Praesent libero turpis, pharetra non nisi ut, varius pretium nibh. Vestibulum sed eleifend justo. Phasellus at leo vel dolor tempus rhoncus nec sit amet dui. In luctus vel nunc congue auctor. Donec fringilla semper mauris at mollis.',
      orgao: '3ª PROMOTORIA DE JUSTIÇA DE TUTELA COLETIVA DO NÚCLEO ANGRA DOS REIS',
      membro: 'RAQUEL MADRUGA DO NASCIMENTO BRITO',
      servidor: 'Fabiana Ribeiro de Castro',
    },
    {
      id: 3,
      sat: '1236',
      mprj: '20.23.00001.0075148.2021-08',
      fase: 'Execução',
      situacao: 'Análise documental',
      duvida:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sollicitudin sapien vitae diam consequat accumsan. Cras in sagittis orci. Nulla cursus turpis at odio consectetur lobortis. Praesent sed tortor erat. Aliquam dictum ultrices ultricies. Nunc congue tincidunt cursus. Vestibulum et aliquet ante, et efficitur quam. In sagittis auctor mauris quis tristique. Vestibulum vel lacus consectetur augue vestibulum tincidunt ut et enim. Curabitur et dictum mi, vel interdum felis. Praesent libero turpis, pharetra non nisi ut, varius pretium nibh. Vestibulum sed eleifend justo. Phasellus at leo vel dolor tempus rhoncus nec sit amet dui. In luctus vel nunc congue auctor. Donec fringilla semper mauris at mollis.',
      orgao: '3ª PROMOTORIA DE JUSTIÇA DE TUTELA COLETIVA DO NÚCLEO ANGRA DOS REIS',
      membro: 'RAQUEL MADRUGA DO NASCIMENTO BRITO',
      servidor: 'Fabiana Ribeiro de Castro',
    },
  ]

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      heading: {
        fontSize: theme.typography.pxToRem(15),
      },
      accordionDetails: {
        flexDirection: 'column',
      },
    }),
  )

  const classes = useStyles()
  return (
    <div className='content'>
      <MpGrid spacing={{ mt: 10 }}>
        <MpGridItem xl={12} lg={12} md={12}>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.accordionDetails}>
              <MpGrid>
                <MpGridItem xl={4} lg={4} md={4}>
                  <CustomSelect
                    options={[
                      {
                        label:
                          '3ª PROMOTORIA DE JUSTIÇA DE TUTELA COLETIVA DO NÚCLEO ANGRA DOS REIS',
                        value: 1,
                      },
                    ]}
                    label='Órgão solicitante'
                    onChange={() => console.log('onChange')}
                  />
                </MpGridItem>
                <MpGridItem xl={4} lg={4} md={4}>
                  <InputText
                    handleChange={() => console.log('handleChange')}
                    hint=' '
                    label='Número (SAT/MPRJ)'
                    onBlur={() => console.log('function')}
                  />
                </MpGridItem>
                <MpGridItem xl={4} lg={4} md={4}>
                  <MpGridItem xl={4} lg={4} md={4}>
                    <CustomSelect
                      options={[
                        {
                          label: 'RAQUEL MADRUGA DO NASCIMENTO BRITO',
                          value: 1,
                        },
                      ]}
                      label='Membro Responsável'
                      onChange={() => console.log('onChange')}
                    />
                  </MpGridItem>
                </MpGridItem>
              </MpGrid>
              <MpGrid>
                <MpGridItem xl={4} lg={4} md={4}>
                  <CustomSelect
                    options={[
                      {
                        label: 'Fabiana Ribeiro de Castro',
                        value: 1,
                      },
                    ]}
                    label='Servidor Responsável'
                    onChange={() => console.log('onChange')}
                  />
                </MpGridItem>
                <MpGridItem xl={4} lg={4} md={4}>
                  <CustomSelect
                    options={[
                      {
                        label: 'Em elaboração da SAT',
                        value: 1,
                      },
                      {
                        label: 'Análise',
                        value: 2,
                      },
                      {
                        label: 'Execução',
                        value: 3,
                      },
                      {
                        label: 'Produção da IT',
                        value: 4,
                      },
                      {
                        label: 'Concluída',
                        value: 5,
                      },
                    ]}
                    label='Fase'
                    onChange={() => console.log('onChange')}
                  />
                </MpGridItem>
                <MpGridItem xl={4} lg={4} md={4}>
                  <CustomSelect
                    options={[
                      {
                        label: 'Em elaboração',
                        value: 1,
                      },
                      {
                        label: 'Análise pela secretaria',
                        value: 2,
                      },
                      {
                        label: 'Análise documental',
                        value: 3,
                      },
                      {
                        label: 'Em elaboração da IT',
                        value: 4,
                      },
                      {
                        label: 'Concluída com IT',
                        value: 5,
                      },
                      {
                        label: 'Concluída sem IT',
                        value: 6,
                      },
                    ]}
                    label='Situação'
                    onChange={() => console.log('onChange')}
                  />
                </MpGridItem>
              </MpGrid>
              <MpGrid>
                <MpGridItem xl={4} lg={4}>
                  <InputText
                    handleChange={() => console.log('handleChange')}
                    hint=' '
                    label='Ementa'
                    onBlur={() => console.log('function')}
                  />
                </MpGridItem>
                <MpGridItem xl={4} lg={4} md={4}>
                  <ButtonGroup cssClass='button-search'>
                    <Button
                      buttonText='Buscar'
                      buttonTheme={ButtonThemeEnum.CONTAINED}
                      handleClick={() => console.log('handleClick')}
                    />
                  </ButtonGroup>
                </MpGridItem>
              </MpGrid>
            </AccordionDetails>
          </Accordion>
        </MpGridItem>
      </MpGrid>
      <div className='table-1'>
        <MpTable id='table-1' header={{ columns: columnsHeader }}>
          {linhas.map((linha, i) => {
            return (
              <MpTableRow key={i} id={`row-${i}`} noHover={true}>
                <MpTableCell>
                  <a href='#'>{linha.sat}</a>
                </MpTableCell>
                <MpTableCell>{linha.mprj}</MpTableCell>
                <MpTableCell>{linha.fase}</MpTableCell>
                <MpTableCell>{linha.situacao}</MpTableCell>
                <MpTableCell>
                  <div className='quest'>{linha.duvida}</div>
                </MpTableCell>
                <MpTableCell>{linha.orgao}</MpTableCell>
                <MpTableCell>{linha.membro}</MpTableCell>
                <MpTableCell>{linha.servidor}</MpTableCell>
                <MpTableCell>
                  <Button
                    buttonType={ButtonTypeEnum.ICON}
                    iconPath={mdiPencil}
                    buttonText={'right'}
                  />
                </MpTableCell>
              </MpTableRow>
            )
          })}
        </MpTable>
      </div>
    </div>
  )
}
