import { ForwardRefRenderFunction, forwardRef } from 'react'
import InputMask from 'react-input-mask'
import { InputProps } from './types'
import Icon from '@mdi/react'

const InputWithRef: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { icon, variant = 'default', mask = '', label, hint, id, alwaysShowMask, ...rest },
  ref,
) => {
  const asVariant = variant === 'default' ? '' : `mp-form-${variant}`
  const asDisabled = rest.disabled ? `mp-form-control-disabled` : ''
  const asRequired = rest.required ? `mp-form-label-required` : ''

  return (
    <div className={`mp-form-control ${asVariant} ${asDisabled}`}>
      <div className='mp-form-label mp-label select-title'>
        <div data-testid='mp-icon-label-Label' className='mp-form-label-icone'>
          {icon && <Icon path={icon} color='#333' size={0.6} />}
        </div>
        <label className={asRequired}>{label}</label>
      </div>
      <div className='mp-form-input' style={{ height: '38px' }}>
        <InputMask id={id} mask={mask} inputRef={ref} {...rest} alwaysShowMask={alwaysShowMask} />
      </div>
      {!!hint && (
        <div className='mp-form-hint'>
          <p>{hint}</p>
        </div>
      )}
    </div>
  )
}

export const Input = forwardRef(InputWithRef)
