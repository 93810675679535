import React, { ReactNode } from 'react'
import './flex-container.scss'

export interface MpFlexContainerProps {
  id? : string
  height? : string
  children: ReactNode
}

export const MpFlexContainer: React.FC<MpFlexContainerProps> = (props: MpFlexContainerProps) => {
  return (
    <div id={props.id} key={props.id} className="mp-flex-wrapper" style={{ height: props.height ? props.height : '100%' }}>
      {props.children}
    </div>
  )
}
