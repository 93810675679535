import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Copy from './icons/copy.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import './css/style.css';

export default class MpCopy extends Plugin {
    init() {
        const editor = this.editor;


        editor.ui.componentFactory.add('copy', locale => {
            const view = new ButtonView(locale);
            view.set({
                label: 'Copiar',
                icon: Copy,
                tooltip: true
            });

            // Callback executed once the image is clicked.
            view.on('execute', () => {

                let selection = editor.model.document.selection;

                const range = selection.getFirstRange();
                let copiedText = ""

                for (const item of range.getItems()) {
                    copiedText+= item.data
                }

                if(copiedText.length)
                {
                    navigator.clipboard.writeText(copiedText)
                }


            });

            return view;
        });
    }
}
