import React from 'react'
import { MpFileProps, MpGetFileService } from '../../../src/domain/File'
import { MpFileListViewerProps } from './FileListViewer/MpFileListViewer'
import { MpFileViewerControl } from './FileViewerControl/MpFileViewerControl'
import './mpfileviewer.scss'

export type MpFileViewerProps = {
  currentFile: MpFileProps
  authenticatedService?: MpGetFileService
  children?: React.ReactElement<MpFileListViewerProps>
  onRetry?: (model?: any) => void
}

export const MpFileViewer: React.FC<MpFileViewerProps> = (props: MpFileViewerProps) => {
  return (
    <MpFileViewerControl
      listViewer={props.children}
      authenticatedService={props.authenticatedService}
      onRetry={props.onRetry}
      currentFile={props.currentFile} />
  )
}

export default MpFileViewer
