import React from 'react'
import { mdiApplication, mdiArrowExpandAll, mdiClose, mdiMinus, mdiOpenInNew } from '@mdi/js'
import { MpWindowManagerProps } from './MpWindowManager'
import CustomModal from '../Modal'
import { ButtonSizeEnum, ButtonTypeEnum, TamanhoModalEnum } from '../../enums'
import CardBasico from '../CardBasico'
import { MpAutoHeight } from '../AutoHeight/MpAutoHeight'
import { Button, ButtonProps } from '../Buttons/Button'
import ButtonGroup from '../ButtonGroup'
import MpFlexContainer from '../../components/FlexContainer'

import './mpwindowmanager.scss'

type ViewerProps = MpWindowManagerProps & {
  show: boolean
}

type ButtonAreaProps ={ buttons: ButtonProps[] }

const ButtonArea = (buttons: ButtonAreaProps): JSX.Element => {
  return (
    <div className="mp-window-manager-side-menu">
      <ButtonGroup cssClass='button-group-vertical'>

        {buttons.buttons?.map((btnProps, index: number) =>
          <Button
            key={`mp-window-manager-button-id-${index}`}
            {...btnProps}
          />
)}
      </ButtonGroup>
    </div>)
}

const RenderViewer = (props: Omit<ViewerProps, 'show'>): JSX.Element => {
  return (
    props.isFlex
      ? <MpFlexContainer>
        {props.children}
        {props.buttons?.length && <ButtonArea buttons={props.buttons}/>}
      </MpFlexContainer>
      : <>
        {props.children}
        {props.buttons?.length && <ButtonArea buttons={props.buttons}/>}
      </>

  )
}

export const FullScreenViewer = (props: Omit<ViewerProps, 'onFullScreen' | 'onCloseAll'>): React.ReactElement => {
  return (
    <>
      {
        props.show && (
          <CustomModal
            hideFooter
            id='fullscreen-window-manager'
            data-testid='fullscreen-window-manager'
            size={TamanhoModalEnum.FULLSCREEN}
            title={props.currentWindow?.title}
            footerButtons={props?.footerButtons?.map((fb) => ({ buttonText: fb.text, handleClick: fb.onClick }))}
            headerButtons={[
              minimizeButton(props.onMinimized),
              toDefaultViewerButton(props.onFloatScreen),
              openNewWindowButton(() => props.onNewWindow(props.currentWindow)),
              closeButton(props.onClose)
            ]}
            show={props.show}>
            <RenderViewer {...props}/>
          </CustomModal>
        )
      }
    </>
  )
}

export const MpWindowManagerNewWindow = (props: Omit<ViewerProps, 'show' | 'tabs' | 'withBottomTabs' | 'accumulateTabs' | 'onCloseAll' | 'onFullScreen' | 'onFloatScreen' | 'onNewWindow' | 'newWindowUrl' | 'onMinimized' >): React.ReactElement => {
  return (
    <MpAutoHeight heightDecrement={props.heightDecrement}>
      <CardBasico
        id='new-window-window-manager'
        data-testid='new-window-window-manager'
        cssClass='new-window-window-manager'
        title={props.currentWindow?.title}
        buttons={props.footerButtons}
        withHeaderHR = {props.withHeaderHR}
        hideFooter={!props.footerButtons?.length}
        headerButtons={[
          openNewWindowButton(() => {
            if (props?.onClose) {
              props.onClose(props?.currentWindow?.id)
            }
            close()
          }, 'Fechar janela')
        ]}>
        <RenderViewer {...props}/>
      </CardBasico>
    </MpAutoHeight>
  )
}

const ID = 'default-window-manager'
export const DefaultViewer = (props: ViewerProps): React.ReactElement => {
  return (
    <>
      {
        props.show && (
          <MpAutoHeight heightDecrement={props.heightDecrement}>
            <CardBasico
              id={ID}
              data-testid={ID}
              buttons={props.footerButtons}
              withHeaderHR = {props.withHeaderHR}
              headerButtons={[
                minimizeButton(props.onMinimized),
                openModalButton(props.onFullScreen),
                openNewWindowButton(() => {
                  props.onNewWindow(props?.currentWindow)
                }),
                closeButton(props.onClose)
              ]}
              title={props?.currentWindow?.title}
              hideFooter={true}
              hideHeader={props.hideHeader}>
              <RenderViewer {...props}/>
            </CardBasico>
          </MpAutoHeight>
        )
      }
    </>
  )
}

const openNewWindowButton = (handleClick: (model?: any) => void, tooltipText: string = 'Abrir em nova janela.'): ButtonProps => ({
  hide: !handleClick,
  id: 'open-new-window-button',
  buttonType: ButtonTypeEnum.ICON,
  buttonSize: ButtonSizeEnum.SMALL,
  iconPath: mdiOpenInNew,
  tooltipText,
  handleClick
})

const openModalButton = (handleClick: (model?: any) => void): ButtonProps => ({
  hide: !handleClick,
  id: 'mp-window-open-modal-button',
  buttonType: ButtonTypeEnum.ICON,
  buttonSize: ButtonSizeEnum.SMALL,
  iconPath: mdiArrowExpandAll,
  tooltipText: 'Abrir em tela cheia',
  handleClick
})

const closeButton = (handleClick: (model?: any) => void): ButtonProps => ({
  hide: !handleClick,
  id: 'mp-window-close-button',
  buttonType: ButtonTypeEnum.ICON,
  buttonSize: ButtonSizeEnum.SMALL,
  iconPath: mdiClose,
  tooltipText: 'Fechar',
  handleClick
})

const minimizeButton = (handleClick: (model?: any) => void): ButtonProps => ({
  hide: !handleClick,
  id: 'mp-window-minimize-button',
  buttonType: ButtonTypeEnum.ICON,
  buttonSize: ButtonSizeEnum.SMALL,
  iconPath: mdiMinus,
  tooltipText: 'Minimizar',
  handleClick
})

const toDefaultViewerButton = (handleClick: (model?: any) => void): ButtonProps => ({
  hide: !handleClick,
  id: 'mp-window-to-default-viewer-button',
  buttonType: ButtonTypeEnum.ICON,
  buttonSize: ButtonSizeEnum.SMALL,
  iconPath: mdiApplication,
  tooltipText: 'Abrir flutuante',
  handleClick
})
