import React from 'react'
import { mdiFileDocumentMultiple, mdiFolder } from '@mdi/js'
import { FileIcon } from '../../../FileIcon'
import Icone from '../../../icone'
import { CoresEnum, TamanhoIconeEnum } from '../../../../enums'
import { MimeType } from '../../../../domain/File'

type Props = {
  isFolder?: boolean
  isParentWithParts?: boolean
  mimeType?: MimeType
  inactive?: boolean
  isMp?: boolean
}

export const MpFileTreeNodeIcon: React.FC<Props> = ({ isFolder, mimeType, inactive, isParentWithParts, isMp }: Props) => {
  if (isFolder) {
    return <Icone iconPath={mdiFolder} iconColor={CoresEnum.GRAY_700} iconSize={TamanhoIconeEnum.SMALL} />
  }
  if (isParentWithParts) {
    return <Icone iconPath={mdiFileDocumentMultiple} iconColor={CoresEnum.GRAY_700} iconSize={TamanhoIconeEnum.XSMALL} />
  }
  if (mimeType) {
    return <FileIcon isMp={isMp} disabled={inactive} mimeType={mimeType} />
  } else {
    return <FileIcon isMp={isMp} disabled={inactive} mimeType={'application/pdf'} />
  }
}
