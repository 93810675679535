import { TooltipPlacementEnum } from '../../enums/TooltipPlacementEnum'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
export interface TooltipProps {
  text: string
  placement: TooltipPlacementEnum
  isSingleLine?: boolean
  isLight?: boolean
  cssClassName? : string
  isHide?: boolean
  footer? : ReactNode
  children: ReactNode
}

const TOOLTIP_MAX_WIDTH_HEIGHT_PX: number = 280
const TOOLTIP_APPROX_MAX_CH: number = 32
const SPACING_DEFAULT_OFFSET: number = 16
const TOOLTIP_WIDTH_RATIO_PX_X_CH: number = TOOLTIP_MAX_WIDTH_HEIGHT_PX / TOOLTIP_APPROX_MAX_CH // 8.75

export const CustomTooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const [show, setShow] = useState(false)
  const [placement, setPlacement] = useState(props.placement)
  const target = useRef(null)

  const { text: descricao } = props

  const predictTooltipDimensions = (): {width: number, height: number} => {
    const calculatedWidth = props.text?.length * TOOLTIP_WIDTH_RATIO_PX_X_CH
    const calculatedlHeight = props.text?.length * TOOLTIP_WIDTH_RATIO_PX_X_CH / TOOLTIP_APPROX_MAX_CH

    const width = Math.min(calculatedWidth, TOOLTIP_MAX_WIDTH_HEIGHT_PX)
    const height = Math.min(calculatedlHeight, TOOLTIP_MAX_WIDTH_HEIGHT_PX)

    const dimensions = { width, height }
    return dimensions
  }

  const verifyTooltipPlacement = (event: any): void => {
    switch (placement) {
      case TooltipPlacementEnum.LEFT :

        if ((event.clientX - predictTooltipDimensions().width) - SPACING_DEFAULT_OFFSET <= 0) {
          setPlacement(TooltipPlacementEnum.BOTTOM)
        }
        break

      case TooltipPlacementEnum.RIGHT :
        if (((window.innerWidth - event.clientX) - predictTooltipDimensions().width) - SPACING_DEFAULT_OFFSET <= 0) {
          setPlacement(TooltipPlacementEnum.BOTTOM)
        }
        break

      case TooltipPlacementEnum.TOP :
        if (event.clientY - predictTooltipDimensions().height - SPACING_DEFAULT_OFFSET <= 0) {
          setPlacement(TooltipPlacementEnum.BOTTOM)
        }
        break

      case TooltipPlacementEnum.BOTTOM :
        if ((window.innerHeight - event.clientY) - predictTooltipDimensions().height - SPACING_DEFAULT_OFFSET <= 0) {
          setPlacement(TooltipPlacementEnum.TOP)
        }
        break
    }
    if (!props.isHide) setShow(true)
  }

  const onMouseEnter = (event): void => {
    event.persist()
    verifyTooltipPlacement(event)
  }

  const verifyIfCanHide = (event: any): void => {
    if (!props.footer) {
      setShow(false)
    } else {
      if (target.current) {
        if (!target.current.contains(event.target)) {
          setShow(false)
        } else {
        }
      }
    }
  }

  useEffect(() => { setPlacement(props.placement) }, [props.placement])

  return (
    <>
      <span className={`tooltip-target  ${props.cssClassName ? props.cssClassName : ''}` } data-testid="tooltip"
        ref={target}
        onMouseEnter={onMouseEnter}
        onMouseLeave={(event: any) => {
          event.persist()
          setTimeout(() => verifyIfCanHide(event), 200)
        }}>

        {props.children}
      </span>
      {descricao &&
        <>
          <Overlay offset={[0, 0]} target={target.current} show={show} placement={placement}>
            {(tooltipProps) => (
              <Tooltip className={`  ${props.isSingleLine ? 'mp-tooltip-singleline' : ''} ${props.isLight ? 'light' : ''}` } onMouseLeave={(event: any) => {
                event.persist()
                setTimeout(() => setShow(false), 200)
              }}
              id="tooltip" {...tooltipProps}>
                {descricao}
                {props.footer}
              </Tooltip>
            )}
          </Overlay>
        </>
      }
    </>
  )
}
