import React from "react";
import { mdiChevronLeft, mdiChevronRight, mdiWindowMinimize } from "@mdi/js";
import {
  HelpPanelBody,
  HelpPanelContent,
  HelpPanelFooter,
  HelpPanelHeader,
  HelpPanelList,
  HelpPanelListContent,
  HelpPanelStep,
  HelpPanelStepButtonsArea,
  HelpPanelTitle,
  HelpPanelTopicsTitle,
} from "./HelpPanelTutorial.styles";

import { ButtonTutorial } from "../ButtonTutorial/ButtonTutorial";
import { TutorialButtonTypeEnum } from "../ButtonTutorial/ButtonTutorial.styles";
import { TooltipPlacementEnum } from "../../../enums";

export interface MpHelpPanelTutorialProps {
  title: string;
  steps: Step[];
  show: boolean;
  activeStep: number;
  position: number;
  handleSelectStep: (stepIndex: number) => void;
  handleMoveStep: (next?: string, back?: string) => void;
  handleSkip: (changeType: boolean) => void;
  handleMinimize: () => void;
}

export interface Step {
  index: number;
  target: string;
  content: string;
  title?: string;
}

enum MoveStepEnum {
  FOWARD = "next",
  BACKWARD = "back",
}

export const MpHelpPanelTutorial: React.FC<MpHelpPanelTutorialProps> = (
  props: MpHelpPanelTutorialProps
) => {
  return (
    <>
      {props.show && (
        <HelpPanelBody
          position={props.position}
          data-testid={"help-panel-tutorial"}
          id={"help-panel-tutorial"}
        >
          <HelpPanelHeader>
            <HelpPanelTitle>{props.title}</HelpPanelTitle>
            <ButtonTutorial
              iconPath={mdiWindowMinimize}
              buttonType={TutorialButtonTypeEnum.ICON}
              tooltipPosition={TooltipPlacementEnum.TOP}
              tooltipText={"Minimizar Painel"}
              handleClick={props.handleMinimize}
              id={"help-panel-minimize"}
            />
          </HelpPanelHeader>
          <HelpPanelContent>
            <HelpPanelTopicsTitle>Tópicos:</HelpPanelTopicsTitle>
            <HelpPanelList>
              {props.steps.map((step) => {
                return (
                  <HelpPanelStep
                    activeStep={props.activeStep}
                    step={step.index}
                    key={step.target}
                    data-testid={`help-panel-step-${step.index}`}
                    onClick={() => props.handleSelectStep(step.index)}
                  >
                    <HelpPanelListContent>
                      {step.index + 1}. {step.title}
                    </HelpPanelListContent>
                  </HelpPanelStep>
                );
              })}
            </HelpPanelList>
          </HelpPanelContent>
          <HelpPanelFooter>
            <ButtonTutorial
              buttonType={TutorialButtonTypeEnum.DEFAULT}
              tooltipPosition={TooltipPlacementEnum.TOP}
              tooltipText={"Encerrar Ajuda"}
              handleClick={() => props.handleSkip(false)}
              id={"help-panel-skip"}
              buttonText={"ENCERRAR"}
            />
            <HelpPanelStepButtonsArea>
              <ButtonTutorial
                buttonType={TutorialButtonTypeEnum.ICON}
                iconPath={mdiChevronLeft}
                tooltipPosition={TooltipPlacementEnum.TOP}
                tooltipText={"Anterior"}
                disabled={props.activeStep === 0}
                handleClick={() => props.handleMoveStep(MoveStepEnum.BACKWARD)}
                id={"help-panel-back"}
              />
              <ButtonTutorial
                hasLeftButton
                buttonType={TutorialButtonTypeEnum.ICON}
                iconPath={mdiChevronRight}
                tooltipPosition={TooltipPlacementEnum.TOP}
                tooltipText={"Próximo"}
                disabled={props.activeStep === props.steps.length - 1}
                handleClick={() => props.handleMoveStep(MoveStepEnum.FOWARD)}
                id={"help-panel-next"}
              />
            </HelpPanelStepButtonsArea>
          </HelpPanelFooter>
        </HelpPanelBody>
      )}
    </>
  );
};
