import { Button } from '../Buttons'
import { ButtonThemeEnum } from '../../enums/ButtonThemeEnum'
import { ButtonTypeEnum } from '../../enums/ButtonTypeEnum'
import { mdiArrowUp } from '@mdi/js'
import React, { ReactNode } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MpLoader } from '../Loader/loader'

import './infinite-scroll.scss'

export interface InfiniteScrollProps {
  children: ReactNode
  dataLength: number
  next: () => void
  hasMore: boolean
  scrollableTargetId?: string
}

export interface InfiniteScrollState {
  showAnchorIcon: boolean

}

export class CustomInfiniteScroll extends React.Component<InfiniteScrollProps, InfiniteScrollState> {
  scrollTargetObject: any
  constructor (props: InfiniteScrollProps) {
    super(props)

    this.state = {
      showAnchorIcon: false
    }

    this.scrollTargetObject = window
  }

  componentDidMount (): void {
    if (this.props.scrollableTargetId) {
      this.scrollTargetObject = document.getElementById(this.props.scrollableTargetId)
    }
    this.scrollTargetObject?.addEventListener('scroll', this.handleAnchorIcon)
  }

  componentWillUnmount (): void {
    this.scrollTargetObject?.removeEventListener('scroll', this.handleAnchorIcon)
  }

  handleAnchorIcon = (): void => {
    // Se o scroll for para cima e chegar ao final, esconda o botão novamente
    const showAnchorIcon = this.scrollTargetObject.scrollY !== 0

    this.setState({ showAnchorIcon })
  }

  scrollToTop = (): void => {
    this.scrollTargetObject.removeEventListener('scroll', this.handleAnchorIcon)

    this.scrollTargetObject.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.setState({ showAnchorIcon: false })
    setTimeout(() => this.scrollTargetObject.addEventListener('scroll', this.handleAnchorIcon), 1500)
  }

  render (): JSX.Element {
    return (
      <>
        <InfiniteScroll
          scrollableTarget = {this.props.scrollableTargetId}
          dataLength={this.props.dataLength}
          next={this.props.next}
          hasMore={this.props.hasMore}
          loader={<MpLoader show={true} text='Carregando...' />}>
          {this.props.children}
        </InfiniteScroll>
        <div className="mp-infinte-scroll-anchor">
          {this.state.showAnchorIcon &&
            <Button iconPath={mdiArrowUp} buttonType={ButtonTypeEnum.ICON} buttonTheme={ButtonThemeEnum.CONTAINED} handleClick={this.scrollToTop} />}

        </div>
      </>
    )
  }
}
