import React, { useEffect, useRef, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import './MpFileUpload.scss'
import MpTypography from '../Typography'
import { MimeType } from '../../domain/MimeType'
import { MpFileUploadRowAction, MpFileUploadTable } from './FileUploadTable/MpFileUploadTable'
import { MpFileUploadModalError } from './MpFileUploadModalError'
import { CoresEnum } from '../../enums'
import classNames from 'classnames'
import { MpFileUploadContainer } from './FileUploadContainer/MpFileUploadContainer'
import { mbToByte } from '../../utils/file-utils'
import { MpFileProps } from '../../domain/File'
import { ButtonProps } from '../Buttons/Button'
import { Optional } from 'utility-types'

export type MpFileUploadItemProps = Optional<MpFileProps> & {
  fileSizeInBytes: number
  blob: Blob
  isSelected: boolean
  tempId?: number
}

export type MpFileUploadProps = {
  label: string
  id?: string
  placeholder: string
  highlight?: boolean
  defaultFiles?: MpFileUploadItemProps[]
  maximumSizeOfTheSummedFilesInMB?: number
  actions?: MpFileUploadRowAction[]
  maxFiles?: number
  isCheckable?: boolean
  maximumSizeOfaFileInMB?: number
  signButtonDisabledDelay?: number
  dragAndDrop?: boolean
  customActions?: ButtonProps[]
  isLoading?: boolean
  onDragAndDrop?: (files: MpFileUploadItemProps[]) => void
  // supportedTypes?: MimeType[] // Inicialmente será aceito somente pdfs.
  onFileSign?: (file: MpFileUploadItemProps) => void
  onSignSelecteds?: (file: MpFileUploadItemProps[]) => void
  onRemove?: (updatedFiles: MpFileUploadItemProps[]) => void
  onRename?: (updatedFile: MpFileUploadItemProps[]) => void
  onRemoveSelecteds?: (updatedFiles: MpFileUploadItemProps[]) => void
  onUploadSuccess: (files: MpFileUploadItemProps[]) => void
  widthActionsColumn?: number
}

export const MpFileUpload: React.FC<MpFileUploadProps> = ({ ...props }: MpFileUploadProps) => {
  const timeout = useRef<any>()
  const fileIndex = useRef<number>(0)
  const [files, setFiles] = useState<MpFileUploadItemProps[]>(props.defaultFiles ?? [])
  const [fileUploadErrors, setFileUploadErrors] = useState<FileRejection[]>([])
  const [sizeOfAllFilesAdded, setSizeOfAllFilesAdded] = useState(0)
  const [signButtonDisabled, setSignButtonDisabled] = useState(false)

  const toMpFileUploadItemProps = (file: File, index: number): MpFileUploadItemProps => {
    setSizeOfAllFilesAdded(size => size + file.size)
    return {
      fileName: file.name,
      fileSizeInBytes: file.size,
      mimeType: file.type as MimeType,
      src: URL.createObjectURL(new Blob([file], { type: file.type })),
      blob: file,
      isSelected: false,
      tempId: fileIndex.current++
    }
  }

  // TODO: Primeiro tentar usar o ID(que pode ter vindo do banco), caso nao tenha, usar tempId gerado

  function handleOnDrop (uploadedFiles: File[], rejectedFiles: FileRejection[]): void {
    const filesFormatted: MpFileUploadItemProps[] = uploadedFiles?.map(toMpFileUploadItemProps)
    const allFiles = [...files, ...filesFormatted]
    props.onUploadSuccess(allFiles)
    setFiles(allFiles)
    setFileUploadErrors(rejectedFiles)
  }

  function handleDeleteFile (filesUpdated): void {
    setFiles(filesUpdated)
    if (props.onRemove) {
      props.onRemove(filesUpdated)
    }
  }

  const handleRename = (file: MpFileUploadItemProps): void => {
    const fileId = file?.id || file?.tempId
    const filesWithRenamedFile = files?.map(f => rename(f, fileId, file.fileName))
    setFiles(filesWithRenamedFile)
    if (props.onRename) {
      props.onRename(filesWithRenamedFile)
    }
  }

  const rename = (file: MpFileUploadItemProps, id, fileName): MpFileUploadItemProps => {
    const fileId = file?.id || file?.tempId
    if (fileId === id) file.fileName = fileName
    return file
  }

  const handleRemoveSelecteds = (result): void => {
    setFiles(result)
    if (props.onRemoveSelecteds) {
      props.onRemoveSelecteds(result)
    }
  }

  const disableSignButtons = (): void => {
    setSignButtonDisabled(true)
    timeout.current = setTimeout(() => setSignButtonDisabled(false), props.signButtonDisabledDelay)
  }

  function handleToSignFile (file: MpFileUploadItemProps): void {
    disableSignButtons()
    if (props.onFileSign) {
      props.onFileSign(file)
    }
  }

  const handleSignSelecteds = (): void => {
    disableSignButtons()
    if (props.onSignSelecteds) {
      const result = files.filter(f => f.isSelected)
      props.onSignSelecteds(result)
    }
  }

  const updateSumOfAllUploadedFilesSize = (): void => {
    setSizeOfAllFilesAdded(
      files?.reduce((acc: number, next: MpFileUploadItemProps): number => {
        acc += next.fileSizeInBytes
        return acc
      }, 0)
    )
  }

  const removeDisableButtonTimeout = (): void => {
    if (timeout?.current) {
      clearTimeout(timeout.current)
    }
  }

  const handleDragAndDrop = (files: MpFileUploadItemProps[]): void => {
    setFiles(files)
    if (props.onDragAndDrop) {
      props.onDragAndDrop(files)
    }
  }

  // TODO: Deus há de nos perdoar por atualizar um parametro default no state
  useEffect(() => {
    if (!props?.defaultFiles) return
    const updatedFiles = props?.defaultFiles.map(defaultFile => {
      ++fileIndex.current
      const file = { ...defaultFile, tempId: fileIndex.current }
      return file
    })
    setFiles(updatedFiles)
  }, [props.defaultFiles])

  useEffect(() => removeDisableButtonTimeout, [])

  useEffect(updateSumOfAllUploadedFilesSize, [files])

  return (
    <div className='mp-file-upload-scroll'>
      <div data-testid={'test-upload'} className={classNames('mp-file-upload-wrapper', { 'mp-dropzone-highlight-container': props.highlight })}>
        <MpTypography bold color={CoresEnum.BLACK} id={'mp-file-upload-label'}>{props.label}</MpTypography>
        <MpFileUploadModalError
          uploadErrors={fileUploadErrors}
          onClose={() => {
            setFileUploadErrors([])
          }} />
        {!props?.isLoading ? (
          <MpFileUploadContainer
            id={props.id || 'mp-file-upload-input'}
            files={files}
            count={files?.length}
            maximumSizeOfTheSummedFilesInMB={props.maximumSizeOfTheSummedFilesInMB}
            sizeOfAllFilesAdded={sizeOfAllFilesAdded}
            maxFileSize={mbToByte(props.maximumSizeOfaFileInMB) as number}
            maxFiles={props.maxFiles}
            placeholder={props.placeholder}
            handleOnDrop={handleOnDrop}
            accept={['application/pdf']} />
        ) : <></>}
        <MpFileUploadTable
          isLoading={props?.isLoading}
          dragAndDrop={props.dragAndDrop}
          afterDrop={handleDragAndDrop}
          customActions={props.customActions || []}
          files={files}
          signButtonDisabled={signButtonDisabled}
          actions={props.actions}
          isCheckable={props.isCheckable}
          onRemoveSelecteds={handleRemoveSelecteds}
          onSignSelecteds={handleSignSelecteds}
          maximumSizeOfTheSummedFilesInMB={props.maximumSizeOfTheSummedFilesInMB}
          currentTotalFileSizeInByte={sizeOfAllFilesAdded}
          onCloseContextualActionBar={setFiles}
          handleSelectFiles={setFiles}
          onRemove={handleDeleteFile}
          onSign={handleToSignFile}
          handleFileWithEditedName={handleRename}
          widthActionsColumn={props?.widthActionsColumn}
        />
      </div>
    </div>
  )
}
