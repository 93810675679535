import React from 'react'
import { HelpButton, HelpButtonWrapp } from './HelpButtonTutorial.styles'

export interface MpHelpButtonTutorialProps {
  handleMinimizeHelpPanel: () => void
  show: boolean
}

export const MpHelpButtonTutorial: React.FC<MpHelpButtonTutorialProps> = (
  props: MpHelpButtonTutorialProps
) => {
  return (
    props.show && (
      <HelpButtonWrapp data-testid={'help-button-tutorial'}>
        <HelpButton onClick={props.handleMinimizeHelpPanel}>?</HelpButton>
      </HelpButtonWrapp>
    )
  )
}
