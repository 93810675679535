import React from 'react'
import { CustomTooltip } from '../CustomTooltip'
import { BadgePositionEnum, TooltipPlacementEnum } from '../../enums'
import './badge.scss'

export interface BadgeProps {
  position? : BadgePositionEnum
  isOvertaking? : boolean
  tooltipText?: string
  count?: number
  showWithoutNumber?: boolean
  children?: any
}

export const Badge: React.FC<BadgeProps> = (props: BadgeProps) => {
  return (
    <div className='mp-badge'>
      {props.children}
      {
        (!props.showWithoutNumber && !props.count) ? (<></>) : (
          (
            <small className={`mp-badge-content 
            ${props.position ? props.position : ''}  
            ${props.isOvertaking ? 'mp-badge-outside' : ''} 
            ${props.count ? 'mp-badge-number' : ''}
          `}>
              <CustomTooltip text={props.tooltipText} placement={TooltipPlacementEnum.TOP}>
                {props.count &&
                  <small className="text-content">
                    {props.count < 1000 ? props.count : '+999'}
                  </small>
                }
              </CustomTooltip>
            </small>
          )
        )

      }
    </div>
  )
}
