import { ButtonTypeEnum } from '../../enums'
import React, { ReactElement } from 'react' 


export const skeletonButtonPadrao = (
    <div className='mp-btn-skeleton'/>
  )
  export const skeletonButtonCircle = (
    <div className='mp-btn-circle-skeleton'/>
  )
  export const skeletonButtonSquare = (
    <div className='mp-btn-square-skeleton'/>
  )

  export function getSkeletonType(buttonType: ButtonTypeEnum): ReactElement {
    switch (buttonType) {
      case ButtonTypeEnum.DEFAULT: return skeletonButtonPadrao
  
      case ButtonTypeEnum.ICON: return skeletonButtonCircle
  
      case ButtonTypeEnum.OUTLINE: return skeletonButtonPadrao
  
      case ButtonTypeEnum.SQUARE: return skeletonButtonSquare
      
      default: return skeletonButtonPadrao
  
    }
  
  }
  