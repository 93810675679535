import React, { useState } from 'react'
import { mdiDotsVertical } from '@mdi/js'
import { ContextualMenu } from '../../ContextualMenu'
import { MpListItemLink } from '../../List'
import { MpListItemContent, MpListItemIconLeftArea, MpListItemIconRightArea, MpListItemSimple } from '../../List/List'
import { MpFileTreeButton } from '../FileTreeButton/MpFileTreeButton'
import { ButtonSizeEnum, ContextualMenuPositionEnum } from '../../../enums'
import { MpFileNodeProps } from '../FileTreeNode/MpFileTreeNode'

export type MpFileContextualMenuItemProps = {
  id?: string
  text: string
  iconLeft?: string
  iconRight?: string
  handleClick?: (node?: MpFileNodeProps) => void
  node?: MpFileNodeProps
  hide?: boolean
}

export type MpFileTreeContextualMenu = {
  children: React.ReactNode
  buttonSize?: ButtonSizeEnum
  isAbsolute?: boolean
  isReduced?: boolean
}

export const MpFileTreeContextualMenu: React.FC<MpFileTreeContextualMenu> = ({ children, isAbsolute, buttonSize, isReduced }: MpFileTreeContextualMenu) => {
  const [isSelected, setSelected] = useState(false)

  const getDefaultButtonSize = (): ButtonSizeEnum.SMALL | ButtonSizeEnum.DEFAULT => {
    return isReduced ? ButtonSizeEnum.SMALL : ButtonSizeEnum.DEFAULT
  }

  return (
    <div className='mp-file-tree-box-cm'>
      <ContextualMenu itensMenu={children} isAbsolute position={ContextualMenuPositionEnum.INNER_RIGHT_DOWN} id='mp-file-tree-contextual-menu'>
        <MpFileTreeButton
          id='mp-file-tree-contextual-menu-button'
          size={getDefaultButtonSize()}
          icon={mdiDotsVertical}
          tooltip='Menu de opções'
          handleClick={() => setSelected(!isSelected)}
          isSelected={isSelected} />
      </ContextualMenu>
    </div>
  )
}

export const MpFileTreeContextualMenuItem: React.FC<MpFileContextualMenuItemProps> = ({ handleClick, iconLeft, iconRight, text, node }: MpFileContextualMenuItemProps) => (
  <MpListItemLink handleClick={() => { handleClick(node) }}>
    <MpListItemSimple>
      {iconLeft && <MpListItemIconLeftArea iconPath={iconLeft} />}
      <MpListItemContent>
        {text}
      </MpListItemContent>
      {iconRight && <MpListItemIconRightArea iconPath={iconRight} />}
    </MpListItemSimple>
  </MpListItemLink>
)
